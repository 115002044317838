import React, { useState } from 'react';
import classNames from 'classnames';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { Fab } from '@ublend-npm/aulaui-next';
import PlusIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import CreateSpaceIcon from '@material-ui/icons/PlaylistAdd';
import AddUserIcon from '@material-ui/icons/GroupAdd';
import DeleteUserIcon from '@material-ui/icons/DeleteForever';
import RemoveUserIcon from '@material-ui/icons/RemoveFromQueue';
import PeopleIcon from '@material-ui/icons/People';
import { RouteComponentProps } from 'react-router-dom';

const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'end',
    },
    menuList: {
      listStyleType: 'none',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'end',
      gap: '16px',
      marginRight: 8,
      transition: 'all 200ms cubic-bezier(0, 0, 0.2, 1) 0s',
      transform: 'translateY(32px)',
      opacity: 0,
      zIndex: -1,
    },
    menuListOpen: {
      transform: 'translateY(0px)',
      opacity: 1,
      zIndex: 10,
    },
    menuListItem: {
      display: 'flex',
      gap: '16px',
      alignItems: 'center',
    },
    menuListItemButton: {
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
      borderRadius: 4,
      backgroundColor: 'white',
      boxShadow: 'rgb(0 0 0 / 30%) 0px 0px 8px 0px',
      padding: '6px 12px',
      fontFamily: 'unset',
      fontWeight: 500,
    },
  });

const MENU_ITEMS = [
  {
    title: 'Create spaces',
    Icon: CreateSpaceIcon,
    data: '?createClass=true',
  },
  {
    title: 'Create users',
    Icon: PeopleIcon,
    data: '?createUser=true',
  },
  {
    title: 'Add users to spaces',
    Icon: AddUserIcon,
    data: '?addUser=true',
  },
  {
    title: 'Add roles',
    Icon: AddUserIcon,
    data: '?addRoles=true',
  },
  {
    title: 'Remove users from spaces',
    Icon: RemoveUserIcon,
    data: '?removeUsersFromSpaces=true',
  },
  {
    title: 'Delete users from institution',
    Icon: DeleteUserIcon,
    data: '?deleteUsersFromInstitution=true',
  },
];

type ActionButtonProps = WithStyles<typeof styles> &
  Pick<RouteComponentProps, 'history' | 'location'>;

const ActionButton = ({ classes, history, location }: ActionButtonProps) => {
  const [open, setOpen] = useState(false);

  const handleMenuItemClick = (data) => () => {
    history.push({
      pathname: location.pathname,
      search: data,
    });
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <ul
        className={classNames(classes.menuList, {
          [classes.menuListOpen]: open,
          // use CSS animation to reduce width to 0 after 200ms
          // this avoids the closed menu from preventing pointer
          // events on underlying elements while preserving the
          // transitions when the menu is opened
          'delayed-hide': !open,
        })}
      >
        {MENU_ITEMS.map((item) => (
          <li className={classes.menuListItem} key={item.title}>
            <button
              disabled={!open}
              type="button"
              className={classes.menuListItemButton}
              onClick={handleMenuItemClick(item.data)}
            >
              {item.title}
            </button>
            <Fab
              type="secondary"
              onClick={handleMenuItemClick(item.data)}
              disabled={!open}
            >
              <item.Icon />
            </Fab>
          </li>
        ))}
      </ul>
      <Fab type="primary" onClick={() => setOpen(!open)}>
        {open ? <CloseIcon fontSize="large" /> : <PlusIcon fontSize="large" />}
      </Fab>
    </div>
  );
};

const StyledActionButton = withStyles(styles, { withTheme: true })(
  ActionButton,
);

export default StyledActionButton;
