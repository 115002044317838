import { ParameterRenderTypes } from './types';

type CustomParameter = {
  key: string;
  value: string;
};

export const customParameterWhitelist: CustomParameter[] = [
  { key: '$user.id', value: 'user.id' },
  { key: '$user.email', value: 'user.email' },
  { key: '$user.externalId', value: 'user.externalId' },
  { key: '$user.username', value: 'user.username' },
  { key: '$user.custom.studentId', value: 'user.custom.studentId' },
  { key: '$space.id', value: 'space.id' },
  { key: '$space.externalId', value: 'space.externalId' },
  { key: '$space.code', value: 'space.code' },
  { key: '$space.startDate', value: 'space.startDate' },
  { key: '$space.endDate', value: 'space.endDate' },
];

export const assignmentParameterWhitelist: CustomParameter[] = [
  {
    key: '$assignment.openDate',
    value: 'assignment.openDate',
  },
  { key: '$assignment.dueDate', value: 'assignment.dueDate' },
  {
    key: '$assignment.gradesReleasedAt',
    value: 'assignment.gradesReleasedAt',
  },
  {
    key: ParameterRenderTypes.DROPDOWN,
    value: 'Dropdown (Single choice)',
  },
];

export const isStaticCustomParameter = (value: unknown): value is string =>
  typeof value === 'string' && !value.startsWith('$');

export const isWhitelistedCustomParameter = (value: unknown): value is string =>
  customParameterWhitelist.some(({ key: validKey }) => validKey === value);

export const isWhitelistedAssignmentParameter = (
  value: unknown,
): value is string =>
  assignmentParameterWhitelist.some(({ key: validKey }) => validKey === value);
