import styled from '@emotion/styled';
import { createTheme } from '@material-ui/core/styles';
import { theme as aulaTheme } from '@ublend-npm/aulaui-next';

export const DialogContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const HeaderContainer = styled.h4`
  margin: 0;
`;

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: aulaTheme.color.purple,
    },
  },
  typography: {
    fontFamily: aulaTheme.font.family.workSans,
    fontSize: 14,
  },
});
