import React from 'react';
import pluralize from 'pluralize';
import { withStyles } from '@material-ui/core/styles';
import Table from '../Table/Table';

export default withStyles(
  {},
  { withTheme: true },
)(
  ({
    numRows,
    title,
    columns,
    parentClasses,
    table,
    data,
    onRemove,
    theme,
    rightPanelConfig: { getTitle, getPlaceholderTitle },
  }: any) => (
    <div className={parentClasses.right}>
      {data.length > 0 ? (
        <Table
          title={getTitle && getTitle(pluralize(title, numRows, true))}
          titleStyles={{
            color: theme.palette.common.black,
          }}
          data={data}
          columns={columns}
          disableFooter
          disableEmptyRows
          singlePage
          onRemove={onRemove}
          {...table}
        />
      ) : (
        <div className={parentClasses.placeholderContainer}>
          <div className={parentClasses.placeholderTop}>
            {getPlaceholderTitle && getPlaceholderTitle()}
          </div>
          <div className={parentClasses.placeholderImageContainer}>
            <img
              className={parentClasses.placeholderImage}
              src={table.image}
              alt={`add ${title}s`}
            />
          </div>
        </div>
      )}
    </div>
  ),
);
