import React from 'react';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from '@material-ui/core/styles';
import Search from '../../DataManagement/Search/Search.container';
import AvatarDropdown from '../AvatarDropdown/AvatarDropdown';
import InstitutionDetails from '../InstitutionDetails/InstitutionDetails';
import {
  Container,
  LeftContent,
  CenterContent,
  RightContent,
} from './Header.style';

const styleClasses = (theme) => ({
  title: {
    color: 'white',
    fontWeight: theme.typography.fontWeightRegular,
  },
  leftContent: {
    display: 'flex',
    alignItems: 'center',
  },
  institution: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 12px',
  },
});

export default withStyles(styleClasses, { withTheme: true })((props: any) => {
  const { onIconClick, ...others } = props;

  return (
    <Container {...others}>
      <LeftContent>
        <div className={props.classes.leftContent}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={onIconClick}
          >
            <MenuIcon fontSize="large" />
          </IconButton>
          <InstitutionDetails institution={props.institution}>
            <Link to={props.url} className={props.classes.title}>
              {props.title}
            </Link>
          </InstitutionDetails>
        </div>
      </LeftContent>
      <CenterContent>
        <Search />
      </CenterContent>
      <RightContent>
        <AvatarDropdown
          user={props.user}
          institution={props.institution.general.institution}
        />
      </RightContent>
    </Container>
  );
});
