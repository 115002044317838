import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowIcon from '@material-ui/icons/PlayArrow';

const styles = (theme) => ({
  menuItem: {
    padding: '4px 24px',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
    width: 172,
    '&:hover': {
      backgroundColor: theme.palette.common.grey,
    },
  },
  changeRoleTab: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    width: 14,
    height: 14,
  },
});

export default withStyles(styles, { withTheme: true })(
  (props: any) =>
    [
      <MenuItem
        key="removeFromSpace"
        className={props.classes.menuItem}
        onClick={() => {
          props.removeUserModal(true);
          props.closeMenu();
        }}
      >
        Remove from space
      </MenuItem>,
      <MenuItem
        key="addToAnotherSpace"
        className={props.classes.menuItem}
        onClick={props.handleAddToAnotherSpace}
      >
        Add to another space
      </MenuItem>,
      <MenuItem
        key="changeRole"
        className={props.classes.menuItem}
        onClick={props.changeRoleMenuClick}
      >
        <div className={props.classes.changeRoleTab}>
          <div>Change Role</div>
          <ArrowIcon className={props.classes.icon} />
        </div>
      </MenuItem>,
      <Menu
        key="longMenu"
        id="long-menu"
        anchorEl={props.changeRoleMenu}
        open={!!props.changeRoleMenu}
        onClose={props.changeRoleMenuClose}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        style={{
          zIndex: 10000000,
        }}
      >
        <MenuItem
          className={props.classes.menuItem}
          onClick={() => props.handleChangeRole('student')}
        >
          Student
        </MenuItem>
        <MenuItem
          className={props.classes.menuItem}
          onClick={() => props.handleChangeRole('educator')}
        >
          Educator
        </MenuItem>
      </Menu>,
    ] as any,
);
