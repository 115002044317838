import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tile from './Tile';

const BORDER_RADIUS = 4;
const styles = (theme) => ({
  loadingScreen: {
    backgroundColor: theme.palette.common.loadingBackground,
    position: 'absolute',
    zIndex: 500,
    borderRadius: BORDER_RADIUS,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    height: 'calc(100% - 80px)',
    width: 'calc(100% - 200px)',
    maxHeight: 600,
    maxWidth: 1080,
  },
  progress: {
    color: theme.palette.common.white,
  },
  content: {
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
});

const LoaderScreen = ({ classes, open, tileProps }) => {
  if (!open) return null;

  return (
    <div className={classes.loadingScreen} data-testid="modal-loader-screen">
      {!tileProps && (
        <div className={classes.content}>
          <div>
            <CircularProgress className={classes.progress} />
          </div>
          <div className={classes.bold}>
            The eggs are cooking.{' '}
            <span role="img" aria-label="fryingpan">
              🍳
            </span>
          </div>
          <div>
            This can take up to a few minutes. Please keep this page open.
          </div>
        </div>
      )}
      {tileProps && <Tile {...tileProps} />}
    </div>
  );
};

export default withStyles(styles as any, { withTheme: true })(LoaderScreen);
