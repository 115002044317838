import React, { Component } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Modal } from '@ublend-npm/aulaui-next';
import Paper from '@material-ui/core/Paper';
import { withRouter } from 'react-router-dom';
import pluralize from 'pluralize';
import queryString from 'query-string';

import FlatButton from '../Buttons/FlatButton';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';
import LoaderScreen from '../Loaders/ModalLoaderScreen';
import { getUniqDataLength } from '../../../utils/duplicate';
import {
  checkDupFromExistingData,
  filterDataWithObjectId,
  sortErrorToTop,
} from '../../../utils/addNew';
import { CSV_TYPES } from '../../../utils/csv';

const BORDER_RADIUS = 4;

const styles = (theme) =>
  createStyles({
    paper: {
      height: 'calc(100vh - 80px)',
      borderRadius: BORDER_RADIUS,
      width: 'calc(100vw - 200px)',
      maxHeight: 600,
      maxWidth: 1080,
    },
    footer: {
      height: 80,
      width: '100%',
      backgroundColor: theme.palette.common.lightBlue,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottomRightRadius: BORDER_RADIUS,
      borderBottomLeftRadius: BORDER_RADIUS,
    },
    footerInner: {
      padding: 20,
    },
    main: {
      display: 'flex',
      height: 'calc(100% - 80px)',
    },
    left: {
      width: 389,
      height: '100%',
      borderRight: `1px solid ${theme.palette.common.mediumGrey}`,
      overflow: 'auto',
    },
    sliderContainer: {
      height: 0,
    },
    right: {
      width: 'calc(100% - 390px)',
      height: '100%',
      overflow: 'auto',
    },
    placeholderTop: {
      height: 64,
      fontWeight: theme.typography.fontWeightRegular,
      borderBottom: `1px solid ${theme.palette.common.grey}`,
      color: theme.palette.common.darkGrey,
      paddingLeft: 24,
      display: 'flex',
      alignItems: 'center',
    },
    placeholderImageContainer: {
      height: 'calc(100% - 68px)',
      display: 'block',
      width: '100%',
      textAlign: 'center',
    },
    placeholderImage: {
      height: '100%',
    },
    placeholderContainer: { height: '100%' },
    navButtons: { marginLeft: 20 },
    bold: { fontWeight: theme.typography.fontWeightBold },
    institutionDetailsWrapper: {
      margin: 0,
      padding: 12,
      borderBottom: `1px solid ${theme.palette.common.grey}`,
    },
    danger: {
      backgroundColor: '#f06161',
      color: theme.palette.common.white,
    },
  });

class ModalBox extends Component<any, any> {
  state = {
    id: null,
    data: {
      [CSV_TYPES.user]: [],
      [CSV_TYPES.space]: [],
      [CSV_TYPES.spaceWithOpenSpace]: [],
      [CSV_TYPES.role]: [],
    },
    loading: false,
    progressScreen: false,
    clicked: undefined,
    selected: undefined,
    loaderTileProps: undefined,
    totalProcessedActions: undefined,
  };

  componentDidMount() {
    if (this.props.addingUsers && this.props.addingUsers.length) {
      void this.handleAddNew(this.props.addingUsers);
    }

    const parsed = queryString.parse(this.props.location.search);

    const { userName, userId, spaceName, spaceId } = parsed;

    if (userName && userId) {
      this.addDefaultUser(userName, userId);
    } else if (spaceName && spaceId) {
      this.addDefaultSpace(spaceName, spaceId);
    }
  }

  addDefaultUser(name, externalId) {
    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          user: [
            {
              name,
              externalId,
            },
          ],
        },
      }),
      () => this.props.onNext(),
    );
  }

  addDefaultSpace(name, externalId) {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        space: [
          {
            name,
            externalId,
          },
        ],
      },
    }));
  }

  onNext = () => {
    if (this.state.clicked) {
      this.setState({
        clicked: null,
      });
    } else {
      this.props.onNext();
    }
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.leftToProcess !== this.props.leftToProcess) {
      this.setState({
        totalProcessedActions:
          nextProps.totalToProcess - nextProps.leftToProcess,
      });
    }
  };

  handleNavClick = (id) => {
    this.setState({ clicked: id });
  };

  // update new data to state
  updateData = (data, title, cb) => {
    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          [title]: data,
        },
      }),
      cb,
    );
  };

  handleAddNew = async (data) => {
    const { title, uniqueness } = this.props;
    const oldData = [...this.state.data[title]];

    if (uniqueness) {
      const { columns } = uniqueness;
      const { dataWithDup, dataWithoutDup } = checkDupFromExistingData(
        oldData,
        data,
        columns,
      );
      const { dataWithObjId, dataWithoutObjId } =
        filterDataWithObjectId(dataWithoutDup);

      // merge results with old data
      const newData = [
        ...dataWithDup,
        ...oldData,
        ...dataWithoutObjId,
        ...dataWithObjId,
      ];

      // sort by error
      const sortedData = sortErrorToTop(newData);

      // update data, send cb to stop loading
      this.updateData(sortedData, title, this.stopLoading);
      return;
    }

    this.updateData([...oldData, ...data], title, this.stopLoading);
  };

  startLoading = () => {
    this.setState({ loading: true });
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };

  showConfirmation = () =>
    new Promise<void>((resolve, reject) => {
      const { data } = this.state;
      const { classes, title, selectedRole, confirmationSectionConfig } =
        this.props;
      const uniqDataLength = getUniqDataLength(data[title]);
      const uniqUsersLength = getUniqDataLength(data.user);
      const uniqSpacesLength = getUniqDataLength(data.space);
      let text;

      if (
        confirmationSectionConfig &&
        confirmationSectionConfig.getConfirmationMessage
      ) {
        text = confirmationSectionConfig.getConfirmationMessage({
          title,
          parentClasses: classes,
          selectedRole,
          uniqDataLength,
          uniqUsersLength,
          uniqSpacesLength,
        });
      }

      this.setState({
        loading: true,
        loaderTileProps: {
          heading: 'Please Confirm.',
          text: text || 'Are you sure?',
          confirmButton: {
            action: () => {
              this.closeLoader();
              resolve();
            },
          },
          cancelButton: {
            action: () => reject(),
          },
        },
      });
    });

  showSaveLoader = () => {
    this.setState({
      loading: true,
      progressScreen: true,
    });
  };

  close = () => {
    this.props.history.push({
      pathname: this.props.location.pathname,
    });
  };

  closeLoader = (error?) => {
    const loaderProps = error
      ? {
          loading: true,
          progressScreen: false,
          loaderTileProps: {
            heading: 'Something went wrong... 😢',
            text: error && `The error was: ${error.message}`,
            confirmButton: {
              action: () => this.close(),
              label: 'Close',
            },
          },
        }
      : {
          loading: false,
          loaderTileProps: null,
        };

    this.setState({ ...loaderProps });
  };

  doneSaving = () => {
    const { data } = this.state;
    const {
      classes,
      title,
      errorsList,
      selectedRole,
      confirmationSectionConfig,
    } = this.props;

    const uniqDataLength = getUniqDataLength(data[title]);
    const uniqSpacesLength = getUniqDataLength(data.space);
    const uniqUsersLength = getUniqDataLength(data.user);

    let text;
    if (errorsList && errorsList.length > 0) {
      text = (
        <div>
          There were some import errors: <br />{' '}
          {errorsList.map(({ message }) => (
            <div key={message}>{message}</div>
          ))}
        </div>
      );
    } else if (
      confirmationSectionConfig &&
      confirmationSectionConfig.getSuccessMessage
    ) {
      text = confirmationSectionConfig.getSuccessMessage({
        title,
        parentClasses: classes,
        selectedRole,
        uniqDataLength,
        uniqUsersLength,
        uniqSpacesLength,
      });
    } else {
      text = 'Successfully executed!';
    }

    this.setState({
      loading: true,
      progressScreen: false,
      loaderTileProps: {
        heading: errorsList ? 'Something went wrong... 😢' : 'High 5 ✋',
        text,
        confirmButton: {
          action: () => this.close(),
          label: errorsList ? "I'll try again" : 'Great stuff!',
        },
      },
    });
  };

  save = async () => {
    try {
      await this.showConfirmation();
      this.showSaveLoader();
      // await start saving .....
      await this.props.onSave(this.state.data);
      this.doneSaving();
    } catch (error) {
      this.closeLoader(error);
    }
  };

  handleRemove = (selected, bTitle) => {
    const { data } = this.state;

    // only accept block title that is `space` or `user`
    const acceptedTitles = ['user', 'space', 'role'];

    const title = acceptedTitles.includes(bTitle) ? bTitle : this.props.title;

    const filtered = data[title].filter(
      (item) => !selected.includes(item.externalId),
    );

    this.setState(
      {
        data: {
          ...data,
          [title]: filtered,
        },
      },
      () => {
        const shouldGoBack =
          this.props.title === 'space' && title === 'user' && !filtered.length;

        if (shouldGoBack && this.props.onPrev) {
          this.props.onPrev();
        }
      },
    );
  };

  handleSelect = (selected) => {
    this.setState({ selected });
  };

  render() {
    const {
      classes,
      history,
      location,
      manualAdd,
      step,
      table,
      search,
      title,
      columns,
      onPrev,
      renderLeftPanel,
      renderRightPanel,
      uniqueness,
      totalToProcess,
      theme,
      danger,
      modalSubtitle,
      csvLeftPanelAction,
      showInstitutionDetails,
      rightPanelConfig,
    } = this.props;
    const {
      clicked,
      data,
      loading,
      loaderTileProps,
      totalProcessedActions,
      progressScreen,
    } = this.state;

    const uniqDataLength = getUniqDataLength(data[title]);

    return (
      <Modal
        open
        onClose={() =>
          history.push({
            pathname: location.pathname,
          })
        }
      >
        <Paper className={classes.paper} elevation={6}>
          <LoaderScreen open={loading} tileProps={loaderTileProps} />
          <LoaderScreen
            open={progressScreen}
            tileProps={{
              heading: `Processed ${totalProcessedActions} of ${pluralize(
                'row',
                totalToProcess,
                true,
              )}...`,
              text: 'This may take a moment.',
              loader: true,
            }}
          />
          <div className={classes.main}>
            {renderLeftPanel ? (
              renderLeftPanel({
                classes,
                clicked,
                title,
                handleAddNew: this.handleAddNew,
                handleNavClick: this.handleNavClick,
                onBack: () => this.setState({ clicked: null }),
              })
            ) : (
              <LeftPanel
                classes={classes}
                theme={theme}
                clicked={clicked}
                search={search}
                manualAdd={manualAdd}
                csvAction={csvLeftPanelAction}
                title={title}
                columns={columns}
                uniqueness={uniqueness}
                danger={danger}
                modalSubtitle={modalSubtitle}
                handleAddNew={this.handleAddNew}
                handleNavClick={this.handleNavClick}
                startLoading={this.startLoading}
                stopLoading={this.stopLoading}
                onBack={() => this.setState({ clicked: null })}
                showInstitutionDetails={showInstitutionDetails}
              />
            )}
            {renderRightPanel ? (
              renderRightPanel({
                parentClasses: classes,
                data,
                table,
                onRemove: this.handleRemove,
              })
            ) : (
              <RightPanel
                title={title}
                parentClasses={classes}
                columns={columns}
                data={data[title]}
                table={table}
                onRemove={this.handleRemove}
                numRows={uniqDataLength}
                rightPanelConfig={rightPanelConfig}
              />
            )}
          </div>
          <div className={classes.footer}>
            <div className={classes.navButtons}>
              {step === 'spaces' && (
                <FlatButton color="white" onClick={onPrev}>
                  ← Previous
                </FlatButton>
              )}
            </div>
            <div className={classes.footerInner}>
              <FlatButton
                color="white"
                onClick={() =>
                  history.push({
                    pathname: location.pathname,
                  })
                }
              >
                Cancel
              </FlatButton>
              {data[title].length > 0 && (
                <FlatButton
                  disabled={!uniqDataLength}
                  className={classes.navButtons}
                  color="darkBlue"
                  onClick={step === 'users' ? this.onNext : this.save}
                >
                  {step === 'users' ? 'Next step →' : 'Confirm'}
                </FlatButton>
              )}
            </div>
          </div>
        </Paper>
      </Modal>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withRouter(ModalBox));
