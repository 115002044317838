import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux';
import _orderBy from 'lodash/orderBy';
import { withRouter } from 'react-router-dom';
import TableHeader from './Header';
import TableToolbar from './Toolbar';

const styles = (theme) => ({
  root: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  table: {
    // minWidth: 800,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  checkbox: {
    color: theme.palette.common.green,
  },
  row: {
    '&:hover': {
      backgroundColor: theme.palette.common.grey,
    },
  },
  cell: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    cursor: 'pointer',
  },
  checkboxCell: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    cursor: 'pointer',
    padding: 0,
    textAlign: 'center',
    width: '90px',
  },
  dropDown: {
    fontSize: '0.8125rem',
    width: 87,
    '&::before': {
      height: '0 !important',
    },
  },
  selectMenu: {
    paddingRight: 0,
    minHeight: '1em',
  },
  menuItem: {
    padding: '4px 24px',
    fontSize: '0.8125rem',
    fontWeight: theme.typography.fontWeightRegular,
    width: 172,
    '&:hover': {
      backgroundColor: theme.palette.common.grey,
    },
  },
});

interface UserTableProps {
  reachedLastPage?: (..._args: unknown[]) => void;
  closeMenu?: (..._args: unknown[]) => void;
  onSelect?: (..._args: unknown[]) => void;
  onMenuClose?: (..._args: unknown[]) => void;
  onRemove?: (..._args: unknown[]) => void;
  bTitle: string;
  location: {
    search: { indexOf: (_str: string) => number };
    [key: string]: unknown;
  };
  data: { externalId: string; [key: string]: unknown }[];
  history: { pathname: string; [key: string]: unknown }[];
  classes: any;
  columns: any;
  title: any;
  onRoleChange: any;
  disableFooter: any;
  disableEmptyRows: any;
  disableToolbarTitle: any;
  menuItems: any;
  singlePage: any;
  titleStyles: any;
  dataCount: any;
  readOnly: any;
}

class UserTable extends Component<UserTableProps> {
  static defaultProps: Partial<UserTableProps>;

  state = {
    selected: [],
    page: 0,
    rowsPerPage: 50,
    orderBy: null,
    order: 'asc',
    data: [],
    menuAnchor: null,
  };

  componentDidMount = () => {
    if (this.props.closeMenu) this.props.closeMenu(this.handleMenuClose);
    if (this.props.data.length) this.setState({ data: this.props.data });
  };

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      data: nextProps.data,
    });
  };

  handleSelectAllClick = (event, checked) => {
    const selectedIds = checked ? this.props.data.map((d) => d.externalId) : [];

    this.setState({ selected: selectedIds });
    if (this.props.onSelect) {
      this.props.onSelect(selectedIds);
    }
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    this.setState({ selected: newSelected });
    if (this.props.onSelect) {
      this.props.onSelect(newSelected);
    }
  };

  handlePageChange = (event, page) => {
    const pages = Math.ceil(this.state.data.length / this.state.rowsPerPage);
    // Reached last page
    if (pages - page === 1) {
      this.props.reachedLastPage(page);
    }
    this.setState({ page });
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  handleMenuOpen = (e) => this.setState({ menuAnchor: e.currentTarget });

  handleMenuClose = () => {
    this.setState({ menuAnchor: null });

    if (this.props.onMenuClose) {
      this.props.onMenuClose();
    }
  };

  remove = () => {
    // send block title `['user', 'space']` to remove function
    const { bTitle } = this.props;
    this.props.onRemove(this.state.selected, bTitle);
    this.setState({ selected: [] });
  };

  handleRequestSort = (event, column) => {
    let order: 'desc' | 'asc' = 'desc';

    if (this.state.orderBy === column && this.state.order === 'desc') {
      order = 'asc';
    }

    const data = _orderBy(
      this.state.data,
      [
        (o) =>
          typeof o[column] === 'string'
            ? o[column].toLocaleLowerCase()
            : o[column],
      ],
      [order],
    );
    this.setState({ data, order, orderBy: column });
  };

  handleCellClick = (path, id) => {
    const { search } = this.props.location;
    const isCreating =
      search.indexOf('createClass=true') > 0 ||
      search.indexOf('addUser=true') > 0;

    if (!isCreating && id) {
      this.props.history.push({ pathname: `${path}${id}` });
    }
  };

  renderColumnData = (column, data) => {
    const datum = data[column];
    const datumType = typeof data[column];
    switch (column) {
      case 'sso':
        return data.sso ? 'Enabled' : 'Disabled';
      case 'open':
        return data.open ? 'Yes' : 'No';
      default:
        if (datumType === 'boolean') {
          return datum ? 'True' : 'False';
        }
        return data[column];
    }
  };

  render() {
    const MINIMUM_ROWS_PER_PAGE = 5;
    const {
      classes,
      columns,
      title,
      onRoleChange,
      disableFooter,
      disableEmptyRows,
      disableToolbarTitle,
      menuItems,
      singlePage,
      titleStyles,
      dataCount,
    } = this.props;
    const { selected, rowsPerPage, page, order, orderBy, data } = this.state;
    const dataPerPage = page * rowsPerPage;
    const emptyRows =
      MINIMUM_ROWS_PER_PAGE - Math.min(rowsPerPage, data.length - dataPerPage);
    const visibleData = singlePage
      ? [...data]
      : data.slice(dataPerPage, dataPerPage + rowsPerPage);
    const defaultMenuItems = (
      <MenuItem className={classes.menuItem} onClick={this.remove}>
        Remove
      </MenuItem>
    );
    return (
      <div className={classes.root}>
        <TableToolbar
          disableToolbarTitle={disableToolbarTitle}
          title={title}
          titleStyles={titleStyles}
          numSelected={selected.length}
          anchorEl={this.state.menuAnchor}
          onMenuClick={this.handleMenuOpen}
          onMenuClose={this.handleMenuClose}
          menuItems={menuItems || defaultMenuItems}
        />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} role="table" size="small">
            <TableHeader
              numSelected={selected.length}
              onSelectAllClick={this.handleSelectAllClick}
              rowCount={data.length}
              columns={columns}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              readOnly={this.props.readOnly}
            />
            <TableBody>
              {visibleData.map((pageData, i) => {
                const key = pageData.externalId || `noid-${i + dataPerPage}`;
                const isSelected = this.isSelected(key);
                const path = pageData.firstName
                  ? '/manage/users/'
                  : '/manage/spaces/';
                return (
                  <TableRow
                    className={classes.row}
                    hover
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={`row-key-${key}`}
                    selected={isSelected}
                  >
                    {!this.props.readOnly && (
                      <TableCell className={classes.checkboxCell}>
                        <Checkbox
                          className={classes.checkbox}
                          checked={isSelected}
                          color="primary"
                          onClick={(e) => this.handleClick(e, key)}
                        />
                      </TableCell>
                    )}
                    {columns.map((column) => {
                      const isNotRoleColumn = column !== 'role';

                      if (isNotRoleColumn) {
                        return (
                          <TableCell
                            className={classes.cell}
                            key={column || Math.floor(Math.random() * 1000)}
                            onClick={() =>
                              this.handleCellClick(path, pageData.id)
                            }
                          >
                            {this.renderColumnData(column, pageData)}
                            <span style={{ color: 'red' }}>
                              {pageData.duplicate &&
                              pageData.duplicate.includes(column) ? (
                                <span>
                                  <br />
                                  Exists already. Will not be added.
                                </span>
                              ) : null}
                              {pageData.error &&
                              pageData.error.field === column ? (
                                <span>
                                  <br />
                                  {pageData.error.msg}
                                </span>
                              ) : null}
                            </span>
                          </TableCell>
                        );
                      }

                      const role = (pageData.role || 'invalid').toLowerCase();
                      return (
                        <TableCell key={column} className={classes.cell}>
                          <Select
                            className={classes.dropDown}
                            classes={{
                              selectMenu: classes.selectMenu,
                            }}
                            value={role}
                            onChange={(e) =>
                              onRoleChange(e.target.value, pageData.externalId)
                            }
                            disabled={this.props.readOnly}
                          >
                            <MenuItem value="student">Student</MenuItem>
                            <MenuItem value="educator">Educator</MenuItem>
                          </Select>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && !disableEmptyRows && (
                <TableRow key="empty-row" style={{ height: 49 * emptyRows }}>
                  <TableCell
                    className={classes.cell}
                    colSpan={columns.length + 1}
                  />
                </TableRow>
              )}
            </TableBody>
            {!disableFooter && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    colSpan={6}
                    count={dataCount || data.length}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[]}
                    page={page}
                    backIconButtonProps={{
                      'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                      'aria-label': 'Next Page',
                    }}
                    onPageChange={this.handlePageChange}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </div>
      </div>
    );
  }
}

UserTable.defaultProps = {
  reachedLastPage: () => undefined,
};

const UserTableComponent = withStyles(styles as any, { withTheme: true })(
  UserTable,
);

const mapStateToProps = (state) => ({
  isSidebarOpen: state.sidebar.isSidebarOpen,
});

export default withRouter(
  connect(mapStateToProps, () => ({}))(UserTableComponent),
);
