const theme = {
  typography: {
    fontFamily: '"Work Sans", sans-serif',
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightBold: 700,
    fontSize: 12,
  },
  palette: {
    primary: {
      light: '#5ae1dc',
      main: '#00afaa',
      dark: '#00afaa',
      contrastText: '#000000',
    },
    secondary: {
      light: '#ffffff',
      main: '#fafafa',
      dark: '#c7c7c7',
      contrastText: '#000000',
    },
    common: {
      green: '#00afaa',
      verror: '#f0001d',
      grey: '#efefef',
      grey1: '#BABABA',
      lightGrey: '#f6f6f6',
      mediumGrey: '#e8e8e8',
      darkGrey: '#9b9b9b',
      darkGreen: '#4daca9',
      hover: 'rgba(255, 255, 255, 0.4)',
      lightBlue: '#a6b4cc',
      darkBlue: '#31405c',
      white: '#fff',
      black: '#000',
      searchBar: 'rgba(0, 0, 0, 0.32)',
      loadingBackground: 'rgba(49, 64, 92, 0.88)',
    },
    divider: '#efefef',
  },
};
export default theme;
