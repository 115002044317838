import { connect } from 'react-redux';

import { Snackbar } from '@ublend-npm/aulaui-next';
import { dismissToast } from '../../../redux/actions/toasts';
import {
  isToastActive,
  activeToast as getActiveToast,
} from '../../../redux/selectors/toasts';

const mapStateToProps = (state) => {
  const activeToast = getActiveToast(state) || {};
  return {
    open: isToastActive(state),
    message: activeToast.message || '',
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(dismissToast()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar);
