import styled from '@emotion/styled';

export const Container = styled.div<any>`
  display: flex;
  height: 80px;
  zindex: 1300;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  position: absolute;
  box-sizing: border-box;
  padding: 0 12px 0 12px;
  color: #fff;
  background-color: ${(props) => props.theme.palette.common.darkBlue};
  width: ${(props) =>
    props.sidebarOpen ? `calc(100% - ${props.drawerWidth}px)` : '100%'};
  margin-left: ${(props) => (props.sidebarOpen ? `${props.drawerWidth}px` : 0)};
  transition: ${(props) =>
    props.theme.transitions.create(['margin', 'width'], {
      easing: props.theme.transitions.easing.easeOut,
      duration: props.theme.transitions.duration.enteringScreen,
    })};
`;

export const LeftContent = styled.div`
  display: flex;
  justify-content: flex-start;
  color: #fff;
  min-width: 200,
  width: auto,
`;

export const CenterContent = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  color: #fff;
`;

export const RightContent = styled.div`
  display: flex;
  justify-content: flex-end;
  color: #fff;
  align-items: center;
  min-width: 80px;
  width: auto;
`;
