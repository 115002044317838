import React from 'react';
import pluralize from 'pluralize';

interface RemoveUserConfirmationProps {
  uniqUsersLength: number;
  uniqSpacesLength: number;
  parentClasses?: {
    bold?: string;
  };
}

const RemoveUserConfirmation = ({
  parentClasses,
  uniqUsersLength,
  uniqSpacesLength,
}: RemoveUserConfirmationProps) => (
  <span>
    You are removing{' '}
    <span className={parentClasses.bold}>
      {pluralize('user', uniqUsersLength, true)}
    </span>{' '}
    from{' '}
    <span className={parentClasses.bold}>
      {pluralize('space', uniqSpacesLength, true)}
    </span>
  </span>
);

export default RemoveUserConfirmation;
