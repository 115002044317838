/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Button, icons, Loader } from '@ublend-npm/aulaui-next';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import {
  CreateLtiProviderRequestBody,
  Lti1P3Provider,
  LtiProvider,
  LtiVersion,
  UpdateLtiProviderRequestBody,
} from '@ublend-npm/aula-schema';
import Filter, { FilterType } from './Filter';
import IntegrationListItem from './IntegrationListItem';
import ModifyDialog from './ModifyDialog';
import ErrorDialog from '../shared/ErrorDialog/ErrorDialog';
import IntegrationIdentificationDialog from './IntegrationIdentificationDialog';
import { styles } from './IntegrationManagement.styles';

const { AddIcon } = icons;

const sortByDescription = (pA: LtiProvider, pB: LtiProvider) => {
  const descriptionA = pA.description.toLowerCase();
  const descriptionB = pB.description.toLowerCase();
  return descriptionA.localeCompare(descriptionB, 'en', { numeric: true });
};

type IntegrationManagementProps = WithStyles<typeof styles> &
  Readonly<{
    fetchIntegrations: () => LtiProvider[];
    deleteIntegration: (providerId: string, description: string) => void;
    addIntegration: (args: CreateLtiProviderRequestBody) => LtiProvider;
    editIntegration: (
      providerId: string,
      args: UpdateLtiProviderRequestBody,
    ) => LtiProvider;
    fetchIntegrationById: (providerId: string) => LtiProvider;
  }>;

const IntegrationManagement = ({
  classes,
  fetchIntegrations,
  deleteIntegration,
  addIntegration,
  editIntegration,
  fetchIntegrationById,
}: IntegrationManagementProps) => {
  const [integrations, setIntegrations] = useState<LtiProvider[]>([]);
  const [isFetchingIntegrations, setIsFetchingIntegrations] = useState(false);
  const [filter, setFilter] = useState(FilterType.ALL);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [identificationDialogOpen, setIdentificationDialogOpen] =
    useState(false);
  const [addedIntegration, setAddedIntegration] = useState<Lti1P3Provider>();
  const [error, setError] = useState('');

  const openAddDialog = () => {
    setIsAddDialogOpen(true);
  };

  const closeAddDialog = () => {
    setIsAddDialogOpen(false);
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedFilter = e.target.value as FilterType;
    setFilter(selectedFilter);
  };

  const fetchIntegrationsHandler = useCallback(async () => {
    try {
      setIsFetchingIntegrations(true);
      const fetchedIntegrations = await fetchIntegrations();
      setIntegrations(fetchedIntegrations);
    } catch (err) {
      setError('Failed to fetch integrations');
      setIntegrations([]);
    } finally {
      setIsFetchingIntegrations(false);
    }
  }, [fetchIntegrations]);

  useEffect(() => {
    void fetchIntegrationsHandler();
  }, [fetchIntegrationsHandler]);

  const onAddProviderHandler = async (
    provider: CreateLtiProviderRequestBody,
  ) => {
    try {
      const added = await addIntegration(provider);
      if (added.ltiVersion === LtiVersion.ONE_POINT_THREE) {
        setAddedIntegration(added);
        setIdentificationDialogOpen(true);
      }
    } catch {
      setError(`Adding ${provider.description} was unsuccessful`);
    } finally {
      closeAddDialog();
      await fetchIntegrationsHandler();
    }
  };

  const onDeleteProviderHandler = async (id: string, description: string) => {
    try {
      await deleteIntegration(id, description);
    } catch {
      setError(`Deleting ${description} was unsuccessful`);
    } finally {
      await fetchIntegrationsHandler();
    }
  };

  const handleCloseError = () => {
    setError('');
  };

  const filteredSortedIntegrations = useMemo(() => {
    if (filter === FilterType.ALL) {
      return integrations.concat().sort(sortByDescription);
    }

    return integrations
      .filter(
        (integration) =>
          (filter === FilterType.CUSTOM && !!integration.createdBy) ||
          (filter === FilterType.AULA && !integration.createdBy),
      )
      .sort(sortByDescription);
  }, [integrations, filter]);

  if (isFetchingIntegrations) {
    return (
      <Paper elevation={5} className={classes.root}>
        <div className={classes.loader} data-testid="integration-list-loader">
          <Loader />
        </div>
      </Paper>
    );
  }

  return (
    <>
      <Paper elevation={5} className={classes.root}>
        <div className={classes.header}>
          <div className={classes.actions}>
            <Filter value={filter} onChange={handleFilterChange} />
            <Button
              type="primary"
              iconLeft={AddIcon}
              onClick={openAddDialog}
              data-testid="add-integration-button"
            >
              Add integration
            </Button>
          </div>
          <h3 className={classes.heading}>
            {`${filteredSortedIntegrations.length} integration${
              filteredSortedIntegrations.length === 1 ? '' : 's'
            }`}
          </h3>
        </div>
        <List>
          {filteredSortedIntegrations.map((integration) => (
            <IntegrationListItem
              {...integration}
              isCustom={!!integration.createdBy}
              key={integration.id}
              onDeleteProvider={onDeleteProviderHandler}
              onFetchProviderById={fetchIntegrationById}
              setError={setError}
              fetchIntegrationsHandler={fetchIntegrationsHandler}
              editIntegration={editIntegration}
            />
          ))}
        </List>
        <ModifyDialog
          isOpen={isAddDialogOpen}
          onCloseDialog={closeAddDialog}
          onSaveProvider={onAddProviderHandler}
        />
        {identificationDialogOpen && addedIntegration ? (
          <IntegrationIdentificationDialog
            open
            description={addedIntegration.description}
            iss={addedIntegration.iss}
            clientId={addedIntegration.clientId}
            deploymentId={addedIntegration.deploymentId}
            onClose={() => setIdentificationDialogOpen(false)}
          />
        ) : null}
      </Paper>
      <ErrorDialog
        open={!!error.length}
        title={error}
        onClose={handleCloseError}
      />
    </>
  );
};

export default withStyles(styles, { withTheme: true })(IntegrationManagement);
