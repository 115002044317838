import React, { useState, useCallback, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  Provider as AulaThemeProvider,
} from '@ublend-npm/aulaui-next';
import {
  CreateLti1P1Provider,
  CreateLti1P3Provider,
  CreateLtiProviderRequestBody,
  Lti1P1Provider,
  Lti1P3Provider,
  LtiCustomParameters,
  LtiLaunchContainer,
  LtiPlacement,
  LtiProvider,
  LtiVersion,
  LtiAssignmentCreationParameters,
} from '@ublend-npm/aula-schema';
import { Configuration1p1Args, SECRET_PLACEHOLDER } from './Configuration1p1';
import { Configuration1p3Args } from './Configuration1p3';
import {
  isStaticCustomParameter,
  isWhitelistedAssignmentParameter,
  isWhitelistedCustomParameter,
} from './customParametersWhitelist';
import {
  UrlWithError,
  STATIC_PARAM_VALUE,
  Parameter,
  FormParameters,
  CustomOption,
  ParameterRenderTypes,
} from './types';
import {
  DialogContainer,
  HeaderContainer,
  muiTheme,
} from './ModifyDialog.styles';
import {
  emptyUrlDefaultText,
  invalidUrlHTTPSText,
} from '../../utils/getURLError';
import isHttpsUrl from '../../utils/isHttpsUrl';
import ConfigurationStep from './DialogSteps/ConfigurationStep';
import Lti1p3Configuration from './DialogSteps/ConfigurationStepContent/Lti1p3Configuration';
import Lti1p1Configuration from './DialogSteps/ConfigurationStepContent/Lti1p1Configuration';
import { LaunchContainerOptions } from '../../constants/ltiConstants';
import AppearanceStep from './DialogSteps/AppearanceStep';
import PlacementStep from './DialogSteps/PlacementStep';
import ActionButtons from './DialogSteps/ActionButtons';

const initialCustomParameters: Parameter[] = [];
const getInitialCustomParameters = (
  customParameters: LtiCustomParameters = {},
): Parameter[] =>
  Object.entries(customParameters).reduce(
    (accum, [key, value]): Parameter[] => {
      if (isWhitelistedCustomParameter(value)) {
        // Is valid dynamic param
        return accum.concat({ key, value });
      }

      if (isStaticCustomParameter(value)) {
        return accum.concat({
          key,
          value: STATIC_PARAM_VALUE,
          staticValue: value,
        });
      }

      // If custom parameter value is not whitelisted show it as empty so admin can provide
      // another value.
      return accum.concat({ key, value: '' });
    },
    initialCustomParameters,
  );

const getInitialAssignmentParameters = (
  customParameters: LtiCustomParameters = {},
): Parameter[] =>
  Object.entries(customParameters).reduce(
    (accum, [key, value]): Parameter[] => {
      if (isWhitelistedAssignmentParameter(value)) {
        // Is valid dynamic param
        return accum.concat({ key, value });
      }

      if (isStaticCustomParameter(value)) {
        return accum.concat({
          key,
          value: STATIC_PARAM_VALUE,
          staticValue: value,
        });
      }

      // If custom parameter value is not whitelisted show it as empty so admin can provide
      // another value.
      return accum.concat({ key, value: '' });
    },
    initialCustomParameters,
  );

const getInitialAssignmentCreateParameters = (
  customParameters: LtiAssignmentCreationParameters = [],
): Parameter[] =>
  customParameters.reduce((accum, item): Parameter[] => {
    if (item.type == ParameterRenderTypes.DROPDOWN) {
      return accum.concat({
        key: item.key,
        dropdownLabel: item.label,
        value: item.type,
        options: item.options,
      });
    }
    return accum;
  }, initialCustomParameters);

type ModifyDialogProps = {
  onCloseDialog: () => void;
  onSaveProvider: (args: CreateLtiProviderRequestBody) => Promise<void>;
  providerToBeEdited?: LtiProvider;
};

const ModifyDialog = ({
  onCloseDialog,
  onSaveProvider,
  providerToBeEdited,
}: ModifyDialogProps) => {
  const [ltiVersion, setLtiVersion] = useState<LtiVersion>(
    LtiVersion.ONE_POINT_THREE,
  );
  const [step, setStep] = useState(0);
  const [description, setDescription] = useState<string | null>('');
  const [iconUrl, setIconUrl] = useState<string>('');
  const [allParameters, setAllParameters] = useState<FormParameters>({
    customParameters: [],
    assignmentParameters: [],
  });
  const { customParameters, assignmentParameters } = allParameters;

  const [launchContainer, setLaunchContainer] = useState<LtiLaunchContainer>(
    LaunchContainerOptions[0].key,
  );
  const [iconUrlError, setIconUrlError] = useState<string | null>(null);
  const [showErrors, setShowErrors] = useState(false);
  const [configuration1p1, setConfiguration1p1] =
    useState<Configuration1p1Args>({
      publicUrl: '',
      key: '',
      sharedSecret: '',
      publicUrlError: '',
    });
  const [configuration1p3, setConfiguration1p3] =
    useState<Configuration1p3Args>({
      targetUrl: { value: '', error: emptyUrlDefaultText },
      loginUrl: { value: '', error: emptyUrlDefaultText },
      keysetUrl: { value: '', error: emptyUrlDefaultText },
    });
  const [redirectUris, setRedirectUris] = useState<UrlWithError[]>([
    { value: '', error: emptyUrlDefaultText },
  ]);
  const [canReadContextMembership, setCanReadContextMembership] =
    useState(false);
  const [disabled, setDisabled] = useState(true);
  const [placement, setPlacement] = useState<LtiPlacement>(null);
  const [isDeepLinking, setIsDeepLinking] = useState(false);

  const isEditing = !!providerToBeEdited;
  const title = isEditing ? 'Edit integration' : 'Add integration';

  const setLTI1p1State = useCallback((provider: Lti1P1Provider) => {
    setConfiguration1p1({
      publicUrl: provider.publicUrl,
      key: provider.key,
      sharedSecret: SECRET_PLACEHOLDER,
      publicUrlError: null,
    });
    setLaunchContainer(provider.launchContainer);
  }, []);

  const setLTI1p3State = useCallback((provider: Lti1P3Provider) => {
    setConfiguration1p3({
      targetUrl: { value: provider.targetUrl, error: null },
      loginUrl: { value: provider.loginUrl, error: null },
      keysetUrl: { value: provider.keysetUrl, error: null },
    });
    setRedirectUris(
      provider.redirectUris.map((uri) => ({
        value: uri,
        error: null,
      })),
    );
    const assignmentParameters = getInitialAssignmentParameters(
      provider.assignmentParameters,
    ).concat(
      getInitialAssignmentCreateParameters(
        provider.assignmentCreationParameters,
      ),
    );

    setIsDeepLinking(provider.isDeepLinking);
    setCanReadContextMembership(provider.canReadContextMembership);
    setLaunchContainer(LtiLaunchContainer.WINDOW);
    setPlacement(provider.placement);
    setAllParameters((prev) => ({
      ...prev,
      assignmentParameters,
    }));
  }, []);

  const setLTICommonState = useCallback((provider: LtiProvider) => {
    setLtiVersion(provider.ltiVersion);
    setDescription(provider.description);
    setIconUrl(provider.iconUrl || '');
    setAllParameters((prev) => ({
      ...prev,
      customParameters: getInitialCustomParameters(provider.customParameters),
    }));
    setDisabled(provider.disabled);
  }, []);

  useEffect(() => {
    if (!isEditing) return;
    if (providerToBeEdited.ltiVersion === LtiVersion.ONE_POINT_ONE) {
      setLTI1p1State(providerToBeEdited);
    }

    if (providerToBeEdited.ltiVersion === LtiVersion.ONE_POINT_THREE) {
      setLTI1p3State(providerToBeEdited);
    }
    setLTICommonState(providerToBeEdited);
  }, [
    isEditing,
    providerToBeEdited,
    setLTI1p1State,
    setLTI1p3State,
    setLTICommonState,
  ]);

  const onIconURLChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value;
    setIconUrl(url || '');

    const isIconUrlValid = !url || isHttpsUrl(url);
    setIconUrlError(isIconUrlValid ? null : invalidUrlHTTPSText);
  };

  const getCustomParametersForSave = (params: Parameter[]) =>
    params.reduce((accum, { key: paramKey, value, staticValue }) => {
      if (value === ParameterRenderTypes.DROPDOWN) {
        return accum;
      }
      return {
        ...accum,
        [paramKey]: staticValue || value,
      };
    }, {});

  const getCreationParametersForSave = (params: Parameter[]) =>
    params
      .filter(({ value }) => value === ParameterRenderTypes.DROPDOWN)
      .map(({ key, dropdownLabel, options }) => ({
        key,
        type: ParameterRenderTypes.DROPDOWN,
        label: dropdownLabel,
        options: options.map(({ label, value }) => ({ label, value })),
      }));

  const hasOptionsErrors = (options: CustomOption[]) => {
    return options.some(
      ({ labelError, valueError }) => !!labelError || !!valueError,
    );
  };

  const hasParameterErrors = (parameters: Parameter[]) => {
    return parameters.some(({ keyError, valueError, options }) => {
      if (!!keyError || !!valueError) {
        return true;
      }
      if (options) {
        return hasOptionsErrors(options);
      }
      return false;
    });
  };

  const hasCustomAssignmentParameterErrors = (parameters: Parameter[]) => {
    return parameters.some(
      ({ keyError, valueError, dropdownLabelError, options }) => {
        if (!!keyError || !!valueError) {
          return true;
        }

        if (options) {
          return hasOptionsErrors(options) || !!dropdownLabelError;
        }

        return false;
      },
    );
  };

  const onSave1p1ProviderHandler = async () => {
    const hasCustomParameterErrors = hasParameterErrors(customParameters);

    const { key, sharedSecret, publicUrlError, publicUrl } = configuration1p1;

    const hasStep0ValidationErrors =
      !publicUrl ||
      !key ||
      !sharedSecret ||
      publicUrlError ||
      hasCustomParameterErrors;

    if (hasStep0ValidationErrors) {
      setStep(0);
      setShowErrors(true);
      return;
    }

    const hasStep1ValidationErrors = !description || iconUrlError;
    if (hasStep1ValidationErrors) {
      setShowErrors(true);
      return;
    }

    const secret =
      sharedSecret === SECRET_PLACEHOLDER ? undefined : sharedSecret;

    const args: CreateLti1P1Provider = {
      ltiVersion: LtiVersion.ONE_POINT_ONE,
      description,
      publicUrl,
      key,
      secret,
      iconUrl,
      customParameters: getCustomParametersForSave(customParameters),
      launchContainer,
      disabled,
    };
    await onSaveProvider(args);
  };

  const onSave1p3ProviderHandler = async () => {
    const hasCustomParameterErrors = hasParameterErrors(customParameters);
    const hasAssignmentParameterErrors =
      hasCustomAssignmentParameterErrors(assignmentParameters);

    const { targetUrl, loginUrl, keysetUrl } = configuration1p3;
    const hasRedirectUriErrors = redirectUris.some(({ error }) => !!error);

    const hasStep0ValidationErrors =
      targetUrl.error ||
      loginUrl.error ||
      keysetUrl.error ||
      hasCustomParameterErrors ||
      hasRedirectUriErrors;

    if (hasStep0ValidationErrors) {
      setStep(0);
      setShowErrors(true);
      return;
    }

    const hasStep1ValidationErrors = !description || iconUrlError;
    if (hasStep1ValidationErrors) {
      setShowErrors(true);
      return;
    }

    const hasStep2ValidationErrors = !placement || hasAssignmentParameterErrors;
    if (hasStep2ValidationErrors) {
      setShowErrors(true);
      return;
    }

    const args: CreateLti1P3Provider = {
      ltiVersion: LtiVersion.ONE_POINT_THREE,
      description,
      targetUrl: targetUrl.value,
      loginUrl: loginUrl.value,
      keysetUrl: keysetUrl.value,
      redirectUris: redirectUris.map(({ value }) => value),
      canReadContextMembership,
      isDeepLinking,
      iconUrl,
      customParameters: getCustomParametersForSave(customParameters),
      assignmentParameters: getCustomParametersForSave(assignmentParameters),
      assignmentCreationParameters:
        getCreationParametersForSave(assignmentParameters),
      disabled,
    };

    // placement is not allowed in PATCH /lti/providers/{providerId}
    if (!isEditing) {
      args.placement = placement || LtiPlacement.MATERIALS;
    }

    await onSaveProvider(args);
  };

  const onSaveClickHandler = async () => {
    if (ltiVersion === LtiVersion.ONE_POINT_ONE) {
      await onSave1p1ProviderHandler();
    } else if (ltiVersion === LtiVersion.ONE_POINT_THREE) {
      await onSave1p3ProviderHandler();
    }
  };

  const renderAppearanceStep = () => (
    <AppearanceStep
      description={description}
      iconUrl={iconUrl}
      iconUrlError={iconUrlError}
      onDescriptionChange={setDescription}
      onIconURLChange={onIconURLChangeHandler}
      ltiVersion={ltiVersion}
      launchContainer={launchContainer}
      onLaunchContainerChange={setLaunchContainer}
      isDeepLinking={isDeepLinking}
      onIsDeepLinkingChange={setIsDeepLinking}
      canReadContextMembership={canReadContextMembership}
      onCanReadContextMembershipChange={setCanReadContextMembership}
      disabled={disabled}
      onDisabledChange={setDisabled}
      showErrors={showErrors}
    />
  );

  const renderPlacementStep = () => (
    <PlacementStep
      placement={placement}
      onPlacementChange={setPlacement}
      assignmentParameters={assignmentParameters}
      showErrors={showErrors}
      isEditing={isEditing}
      allParameters={allParameters}
      onAllParametersChange={setAllParameters}
    />
  );

  const renderConfigurationStep = () => (
    <ConfigurationStep
      customParameters={customParameters}
      showErrors={showErrors}
      parameters={allParameters}
      onParametersChange={setAllParameters}
    >
      {ltiVersion === LtiVersion.ONE_POINT_ONE ? (
        <Lti1p1Configuration
          configuration={configuration1p1}
          onConfigurationChange={setConfiguration1p1}
          showErrors={showErrors}
          ltiVersion={ltiVersion}
          onLtiVersionChange={setLtiVersion}
          isEditing={isEditing}
        />
      ) : (
        <Lti1p3Configuration
          configuration={configuration1p3}
          onConfigurationChange={setConfiguration1p3}
          showErrors={showErrors}
          ltiVersion={ltiVersion}
          onLtiVersionChange={setLtiVersion}
          redirectUris={redirectUris}
          onRedirectUrisChange={setRedirectUris}
          isEditing={isEditing}
          providerToBeEdited={providerToBeEdited as Lti1P3Provider}
        />
      )}
    </ConfigurationStep>
  );

  const renderStepContent = (index: number) => {
    switch (index) {
      case 0:
        return renderConfigurationStep();
      case 1:
        return renderAppearanceStep();
      case 2:
        return renderPlacementStep();
      default:
        return null;
    }
  };

  const totalSteps = ltiVersion === LtiVersion.ONE_POINT_THREE ? 3 : 2;

  return (
    <AulaThemeProvider theme={muiTheme}>
      <Dialog id="modify-dialog" width="492" height="705" open>
        <DialogContainer>
          <DialogTitle onClose={onCloseDialog}>
            <HeaderContainer>{title}</HeaderContainer>
          </DialogTitle>
          {renderStepContent(step)}
          <ActionButtons
            totalSteps={totalSteps}
            setStep={setStep}
            step={step}
            onSaveClickHandler={onSaveClickHandler}
            isEditing={isEditing}
          />
        </DialogContainer>
      </Dialog>
    </AulaThemeProvider>
  );
};

const ModifyDialogWrapper = (
  props: ModifyDialogProps & { isOpen: boolean },
): React.ReactElement | null => {
  if (!props.isOpen) {
    return null;
  }
  return <ModifyDialog {...props} />;
};

export default ModifyDialogWrapper;
