import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';

import Table from '../../shared/Table/Table';
import { getUniqDataLength } from '../../../utils/duplicate';

const styles = (theme) => ({
  toolbarTextAbs: {
    color: theme.palette.common.black,
    borderBottom: 'solid 1px #efefef',
    zIndex: 1,
    paddingLeft: '24px',
    width: 'calc(100% - 24px)',
    overflow: 'hidden',
  },
  toolbarText: {
    color: theme.palette.common.black,
  },
  container: {
    width: 'calc(100% - 390px)',
    overflow: 'auto',
    position: 'relative',
  },
  dropDown: {
    height: 64,
    width: 95,
    marginLeft: 5,
  },
  select: {
    height: 64,
    width: 90,
    padding: 0,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.common.green,
    paddingLeft: 5,
  },
  icon: {},
  userTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
  },
});

const RightPanel = ({
  classes,
  data,
  table,
  step,
  parentClasses,
  onRoleChange,
  onRemove,
  selectedRole,
}) => {
  const numberOfUsers = getUniqDataLength(data.user);
  const numberOfSpaces = getUniqDataLength(data.space);
  const userText = numberOfUsers > 1 ? 'users' : 'user';
  const spaceText = numberOfSpaces > 1 ? 'spaces' : 'space';

  const Title = () => (
    <div className={classes.toolbarTextAbs}>
      {numberOfUsers > 0 ? (
        <>
          <span className={classes.userTitle}>
            {`Adding ${numberOfUsers} ${userText} as`}
          </span>
          <span>
            <Select
              className={classes.dropDown}
              classes={{
                selectMenu: classes.selectMenu,
                select: classes.select,
                icon: classes.icon,
              }}
              value={selectedRole}
              onChange={(e) => {
                onRoleChange(e.target.value);
                e.preventDefault();
                e.stopPropagation();
              }}
              native
              disableUnderline
            >
              <option value="student">Student</option>
              <option value="educator">Educator</option>
            </Select>
          </span>
          {numberOfSpaces > 0 ? (
            <span className={classes.userTitle}>
              {`in ${numberOfSpaces} ${spaceText}`}
            </span>
          ) : null}
        </>
      ) : null}
    </div>
  );

  const DataTable = ({ title, columns }) => (
    <Table
      bTitle={title} // block title, to differentiate with table title
      data={data[title]}
      columns={columns}
      onRemove={onRemove}
      disableFooter
      disableEmptyRows
      disableToolbarTitle
      singlePage
      {...table}
    />
  );

  const Placeholder = ({ title }) => (
    <div className={parentClasses.placeholderContainer}>
      <div className={parentClasses.placeholderTop}>{title}</div>
      <div className={parentClasses.placeholderImageContainer}>
        <img
          className={parentClasses.placeholderImage}
          src={table.image}
          alt="add users"
        />
      </div>
    </div>
  );

  const UserData = () => {
    if (data.user.length === 0) {
      return <Placeholder title="Start by selecting users." />;
    }
    return (
      <div>
        <DataTable title="user" columns={['name', 'externalId']} />
      </div>
    );
  };

  const SpaceData = () => {
    if (data.space.length === 0) {
      return <Placeholder title="Select spaces." />;
    }
    return <DataTable title="space" columns={['name', 'externalId']} />;
  };

  return (
    <div className={classes.container}>
      <Title />
      {step === 'users' && <UserData />}
      {step === 'spaces' && <SpaceData />}
    </div>
  );
};

export default withStyles(styles as any, { withTheme: true })(RightPanel);
