import styled from '@emotion/styled';
import { theme as aulaTheme } from '@ublend-npm/aulaui-next';

export const DialogContent = styled.div`
  padding: 20px;
  flex-grow: 2;
  font-weight: 600;
  font-size: 14px;
  overflow: auto;
  & > div {
    padding-bottom: 8px;
  }
  & > h2 + div {
    margin-top: 16px;
  }
  max-height: 534px;
`;

export const SubHeading = styled.h2`
  margin: 8px 0;
  font-size: ${aulaTheme.font.size.copy};
`;

export const DialogHorizontalRuler = styled.hr`
  margin: 12px 0 24px 0;
  border-top: 1px solid ${aulaTheme.color.gray};
  border-bottom: 0;
`;

export const ParametersTitle = styled.h2`
  padding: ${aulaTheme.spacing.small} 0;
  font-size: ${aulaTheme.font.size.copy};
`;

export const ParametersSubtitle = styled.p`
  padding-bottom: ${aulaTheme.spacing.small};
  font-size: ${aulaTheme.font.size.small};
  font-weight: ${aulaTheme.font.weight.regular};
`;

export const Caption = styled.p`
  display: block;
  font-size: 12px;
  font-weight: 400;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const SubHeadingPermissions = styled.h2`
  margin: 0 8px 0 0;
  font-size: ${aulaTheme.font.size.copy};
`;

export const Permission = styled.div`
  min-height: auto;
  margin-top: 0 !important;
`;

export const DialogSpacing = styled.div`
  margin-bottom: ${aulaTheme.spacing.xLarge};
`;

export const RadioLabel = styled.span`
  font-size: ${aulaTheme.font.size.copy};
  font-weight: 500;
`;

export const RadioDescription = styled.p`
  font-size: ${aulaTheme.font.size.small};
  font-weight: ${aulaTheme.font.weight.regular};
  color: ${aulaTheme.color.designSystem.blackOpacity60};
  padding-left: 32px;
  margin: -8px 0 0 0;
`;

export const RadioError = styled.p`
  color: ${aulaTheme.color.designSystem.error};
  font-size: ${aulaTheme.font.size.small};
  font-weight: ${aulaTheme.font.weight.regular};
  margin-top: 4px;
  margin-bottom: 0;
  height: 14px;
`;

export const DialogActions = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 24px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 70px;
`;

export const BackButtonPlaceholder = styled.div`
  width: 42px;
`;
