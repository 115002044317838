/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@ublend-npm/aulaui-next';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import chunk from 'lodash/chunk';
import startCase from 'lodash/startCase';
import UserTable from '../../shared/Table/Table';
import BasicInfoForm from '../../shared/BasicInfoForm/BasicInfoForm';
import getSSO from '../../../utils/getSSO';
import { isValidEmail } from '../../../utils/email';

const styles = (theme) => ({
  loaderContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    height: 'calc(100% - 60px)',
    paddingTop: 60,
  },
  table: {
    paddingBottom: 60,
  },
  basicInfoForm: {
    padding: '0 16px',
  },
  menuItem: {
    padding: '4px 24px',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
    width: 172,
    '&:hover': {
      backgroundColor: theme.palette.common.grey,
    },
  },
});

const BtnTitle = (text, history, { name, externalId }) => (
  <Button
    color="primary"
    onClick={() =>
      history.push({
        search: `?addUser=true&userName=${name}&userId=${externalId}`,
      })
    }
    style={{ fontSize: '16px', marginLeft: '-8px' }}
  >
    {text}
  </Button>
);

const CHUNK_SIZE = 50;
class User extends Component<any> {
  state = {
    spaces: [],
    saved: false,
    textFields: {
      firstName: '',
      lastName: '',
      externalId: undefined,
      email: '',
      sso: false,
      userRoles: [],
    },
    loading: undefined,
    selected: undefined,
    custom: undefined,
    removeUser: undefined,
    isRemoving: undefined,
    avatar: undefined,
  };

  componentWillMount = async () => {
    void this.fetchUser(this.props.match.params.userId);
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.match.params.userId !== this.props.match.params.userId) {
      void this.fetchUser(nextProps.match.params.userId);
    }
  };

  handleSelect = (selected) => {
    this.setState({ selected });
  };

  fetchUser = async (id) => {
    this.setState({ loading: true });
    const user = await this.props.fetchUser(id);
    if (JSON.stringify(user) === '{}') {
      this.props.history.push('/');
      return;
    }

    this.setState({
      loading: false,
      textFields: {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        externalId: user.externalId,
        sso: getSSO({ user, institution: this.props as any }),
        userRoles: user.userRoles,
      },
      custom: user.custom ? user.custom : {},
      avatar: user.avatar,
      spaces: user.spaces.map((space) => ({
        ...space,
        custom: JSON.stringify(space.custom || {}, null, 2),
      })),
    });
  };

  preSaveCheck = async (data) => {
    const { firstName, lastName, email } = data;
    const errors: any = {};

    if (!firstName || firstName.trim() === '') {
      errors.firstName = 'Incorrect first name';
    }

    if (!lastName || lastName.trim() === '') {
      errors.lastName = 'Incorrect last name';
    }

    if (!email || email.trim() === '' || !isValidEmail(email)) {
      errors.email = 'Invalid email format';
    }

    if (Object.keys(errors).length > 0) {
      return errors;
    }

    return null;
  };

  handleSave = async ({ staff, admin, ...updateUser }) => {
    try {
      const userRoles = ['general'];
      if (admin) {
        userRoles.push('admin');
      }
      if (staff) {
        userRoles.push('staff');
      }
      const updated = await this.props.updateUser(
        this.props.match.params.userId,
        {
          ...updateUser,
          userRoles,
        },
      );

      if (updated) {
        this.setState({
          textFields: {
            firstName: updateUser.firstName,
            lastName: updateUser.lastName,
            email: updateUser.email,
            externalId: updateUser.externalId,
            sso: getSSO({ user: updateUser, institution: this.props as any }),
            userRoles,
          },
          avatar: 'avatar' in updated ? updated.avatar : null,
        });
      }
    } catch (_) {
      // Revert changes
      const { textFields, avatar } = this.state;
      this.setState({
        avatar,
        textFields,
      });
    }
  };

  onRoleChange = async (role, spaceExternalId) => {
    await this.props.updateRole({
      externalUserId: this.state.textFields.externalId,
      externalSpaceId: spaceExternalId,
      role,
    });

    this.setState({
      spaces: this.state.spaces.map((s) => {
        if (s.externalId === spaceExternalId) {
          return {
            ...s,
            role,
          };
        }
        return s;
      }),
    });
  };

  handleRemoveUser = () => {
    this.removeUserModal(true);
  };

  removeUserModal = (open) => {
    this.setState({ removeUser: open });
  };

  removeSpaceFromState = (ids) => {
    this.setState({
      spaces: this.state.spaces.filter((u) => !ids.includes(u.externalId)),
      selected: this.state.selected.filter((id) => !ids.includes(id)),
    });
  };

  removeUser = async () => {
    this.setState({ isRemoving: true });
    const { selected } = this.state;
    const dataToDelete = selected.map((externalSpaceId) => ({
      externalSpaceId,
      externalUserId: this.state.textFields.externalId,
    }));
    const chunks = chunk(dataToDelete, CHUNK_SIZE);
    for (const roleChunk of chunks) {
      try {
        await this.props.deleteRoles(roleChunk);
        this.removeSpaceFromState(selected);
      } catch (e) {
        // pick out only initial error message as all errors are joined together
        const message =
          `${e}`.split('\n')[0].split('"')[1] || 'Failed to remove space(s).';
        this.props.showToast({
          message,
        });
      }
    }
    this.setState({ isRemoving: false });
    this.removeUserModal(false);
  };

  renderCustomFields = () => {
    const { custom } = this.state;
    const isBooleanField = (value) => typeof value === 'boolean';

    if (JSON.stringify(custom) !== '{}') {
      const fields = Object.keys(custom).reduce(
        (visitedFields, currentField) => {
          visitedFields.push({
            label: startCase(currentField),
            value: custom[currentField],
            placeholder: '',
            type: isBooleanField(custom[currentField]) ? 'switch' : 'name',
            id: currentField,
            styles: {
              width: '150px',
            },
            disabled: true,
          });

          return visitedFields;
        },
        [],
      );

      return <BasicInfoForm fields={fields} showAvatar={false} />;
    }
    return null;
  };

  render() {
    const { classes, history, sso: institutionSSO } = this.props;
    const { firstName, lastName, email, externalId, sso, userRoles } =
      this.state.textFields;

    if (this.state.loading) {
      return (
        <div className={classes.loaderContainer}>
          <CircularProgress />
        </div>
      );
    }

    return (
      <div className={classes.container}>
        <div className={classes.basicInfoForm}>
          <BasicInfoForm
            fields={[
              {
                label: 'First Name',
                value: firstName,
                placeholder: 'John',
                type: 'name',
                id: 'firstName',
                styles: {
                  minWidth: '100px',
                },
              },
              {
                label: 'Last Name',
                value: lastName,
                placeholder: 'Doe',
                type: 'name',
                id: 'lastName',
                styles: {
                  minWidth: '100px',
                },
              },
              {
                label: 'Email',
                value: email,
                placeholder: 'Doe',
                type: 'email',
                id: 'email',
                check: true,
                styles: {
                  width: '240px',
                },
              },
              {
                label: 'External ID',
                value: externalId,
                placeholder: '',
                type: 'name',
                id: 'externalId',
                styles: {
                  width: '150px',
                },
              },
              {
                label: 'SSO Enabled',
                value: sso,
                type: 'switch',
                id: 'sso',
                // if institution wide sso flag is off, disable switch
                disabled: !institutionSSO,
              },
              {
                label: 'Admin',
                value: userRoles.includes('admin'),
                type: 'switch',
                id: 'admin',
              },
              {
                label: 'Staff',
                value: userRoles.includes('staff'),
                type: 'switch',
                id: 'staff',
              },
            ]}
            avatarName={`${firstName} ${lastName}`}
            avatarUrl={this.state.avatar}
            preSaveCheck={this.preSaveCheck}
            onSave={this.handleSave}
          />
          {this.renderCustomFields()}
        </div>
        <div className={classes.table}>
          <Paper elevation={5}>
            <UserTable
              title={BtnTitle('ADD SPACES', history, {
                name: `${firstName} ${lastName}`,
                externalId,
              })}
              data={this.state.spaces}
              columns={['name', 'id', 'externalId', 'role', 'custom']}
              onRoleChange={this.onRoleChange}
              onSelect={this.handleSelect}
              menuItems={
                <MenuItem
                  className={classes.menuItem}
                  onClick={this.handleRemoveUser}
                >
                  Remove from space
                </MenuItem>
              }
            />
          </Paper>
        </div>
        <Dialog
          id="remove-user-dialog"
          open={this.state.removeUser}
          width="600"
          height="190"
          onClose={() => this.removeUserModal(false)}
        >
          <DialogTitle closeIcon={false}>Remove user from space?</DialogTitle>
          <DialogContent>
            The user will not be able to access this space anymore.
          </DialogContent>
          <DialogActions
            primary={{
              label: 'Remove!',
              onClick: () => this.removeUser(),
              loading: this.state.isRemoving,
            }}
            secondary={{
              label: 'Cancel',
              onClick: () => this.removeUserModal(false),
            }}
          />
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(User);
