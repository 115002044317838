import { connect } from 'react-redux';
import {
  CreateLtiProviderRequestBody,
  UpdateLtiProviderRequestBody,
} from '@ublend-npm/aula-schema';
import IntegrationManagement from './IntegrationManagement';
import {
  fetchIntegrations,
  deleteIntegration,
  addIntegration,
  fetchIntegrationById,
  editIntegration,
} from '../../redux/actions/integration';

const mapDispatchToProps = (dispatch) => ({
  fetchIntegrations: () => dispatch(fetchIntegrations()),
  fetchIntegrationById: (id: string) => dispatch(fetchIntegrationById(id)),
  deleteIntegration: (id: string, description: string) =>
    dispatch(deleteIntegration(id, description)),
  addIntegration: (provider: CreateLtiProviderRequestBody) =>
    dispatch(addIntegration(provider)),
  editIntegration: (id: string, provider: UpdateLtiProviderRequestBody) =>
    dispatch(editIntegration(id, provider)),
});

export default connect(null, mapDispatchToProps)(IntegrationManagement);
