const getKey = (data, columns) =>
  columns.map((column) => data[column]).join(':');

/**
 * @param {Array} oldData
 * @param {Array} data
 *
 * @desc check duplicate with existing data
 * filter out duplicated data and will not send to backend
 * if the duplication happens inside csv, will leave the first record, and filter out next records
 * expected format for user:
 * {
 *   dataWithDup: [{
 *     firstName: '',
 *     lastName: '',
 *     email: '',
 *     duplicate: 'email',
 *   }],
 *   dataWithoutDup: [{
 *     firstName: '',
 *     lastName: '',
 *     email: '',
 *   }]
 * }
 * * * *
 * expected format for space:
 * {
 *   dataWithDup: [{
 *     code: '',
 *     name: '',
 *     duplicate: 'email',
 *   }],
 *   dataWithoutDup: [{
 *     code: '',
 *     name: '',
 *   }]
 * }
 */
export const checkDupFromExistingData = (oldData, data, columns) => {
  const addedData = oldData.map((entry) => getKey(entry, columns));
  const upcomingData = data.map((entry) => getKey(entry, columns));

  const dataWithDup = [];
  const dataWithoutDup = [];
  const dupInsideNewData = [];

  data.forEach((d) => {
    const key = getKey(d, columns);
    const isInAddedData = Boolean(addedData.find((e) => e === key));
    const isRepeatInNewData = upcomingData.filter((e) => e === key).length > 1;
    const isNoted = Boolean(dupInsideNewData.find((e) => e === key));

    if (isInAddedData) {
      dataWithDup.push({ ...d, duplicate: columns });
    } else if (isRepeatInNewData) {
      if (!isNoted) {
        dataWithoutDup.push(d);
        dupInsideNewData.push(key);
      } else {
        dataWithDup.push({ ...d, duplicate: columns });
      }
    } else {
      dataWithoutDup.push(d);
    }
  });

  return {
    dataWithDup,
    dataWithoutDup,
  };
};

// filter data contains objectId
// so that it will be checked again in backend
// useful for adding existing users / spaces
export const filterDataWithObjectId = (data) => {
  const dataWithObjId = [];
  const dataWithoutObjId = [];

  data.forEach((d) => {
    if (d.objectId) {
      dataWithObjId.push(d);
    } else {
      dataWithoutObjId.push(d);
    }
  });

  return {
    dataWithObjId,
    dataWithoutObjId,
  };
};

// sort data so that all data with error will go to top
export const sortErrorToTop = (data) => {
  const dataWithError = [];
  const dataWithoutError = [];

  data.forEach((d) => {
    if (d.duplicate || (d.error && d.error.field)) {
      dataWithError.push(d);
    } else {
      dataWithoutError.push(d);
    }
  });

  return [...dataWithError, ...dataWithoutError];
};
