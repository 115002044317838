import actions from '../../constants/actions';

const initialState = {
  activeToast: null,
};

const dismissToastReducer = (state) => ({
  ...state,
  activeToast: null,
});

const showToastReducer = (state, action) => ({
  ...state,
  activeToast: { ...action.payload.toast },
});

const reducerMap = {
  [actions.TOASTS_SHOW_TOAST]: showToastReducer,
  [actions.TOASTS_DISMISS_TOAST]: dismissToastReducer,
};

export default (state = initialState, action) => {
  const reducer = reducerMap[action.type];
  if (!reducer) return state;
  return reducer(state, action);
};
