/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ModalBox from '../../shared/ModalBox/ModalBox';
import createClassImage from '../AddUser/AddUserImage.png';
import { getUniqData } from '../../../utils/duplicate';
import AddRolesConfirmation from './AddRolesConfirmation';
import AddRolesSuccess from './AddRolesSuccess';
import chunkProcess from '../../../utils/chunkProcess';

const CHUNK_SIZE = 100;

const confirmationSectionConfig = {
  getConfirmationMessage: (data) => <AddRolesConfirmation {...data} />,
  getSuccessMessage: (data) => <AddRolesSuccess {...data} />,
};

const rightPanelConfig = {
  getRightPanelTitle: (entityName) => `Creating ${entityName}`,
  getPlaceholderTitle: () => 'Start by adding roles.',
};

interface CreateUserModalProps extends RouteComponentProps {
  createRoles?: (roles: unknown[]) => void;
}

class CreateUserModal extends Component<CreateUserModalProps> {
  static defaultProps: Partial<CreateUserModalProps>;

  state = {
    leftToProcess: null,
    errors: null,
    totalToProcess: undefined,
  };

  onSave = async (data) => {
    const roles = getUniqData(data.role);

    // if no new user to be added, close the modal
    if (!roles.length) {
      return true;
    }

    await chunkProcess({
      data: roles,
      chunkSize: CHUNK_SIZE,
      onRequest: this.props.createRoles,
      onProgress: (newData) => this.setState(newData),
      onError: (error) =>
        this.setState({
          errors: [...(this.state.errors || []), error],
        }),
    });

    return true;
  };

  render() {
    const { errors, leftToProcess, totalToProcess } = this.state;

    return (
      <ModalBox
        title="role"
        modalSubtitle="Add roles"
        rightPanelConfig={rightPanelConfig}
        confirmationSectionConfig={confirmationSectionConfig}
        manualAdd={{
          tabTitle: 'Add a new Role',
          textFields: {
            externalUserId: {
              title: 'User external ID',
              placeholder: '',
            },
            externalSpaceId: {
              title: 'Space external ID',
              placeholder: '',
            },
            role: {
              title: 'Role (student or educator)',
              placeholder: 'student',
            },
          },
          actionButton: {
            title: 'Add Role',
          },
          validateFields: (value, field) => {
            if (field === 'role') {
              if (value.trim() !== 'educator' && value.trim() !== 'student') {
                return { message: "Role isn't valid" };
              }
            }

            return null;
          },
        }}
        csvLeftPanelAction={{
          tabTitle: 'import a CSV of roles',
        }}
        columns={['externalUserId', 'externalSpaceId', 'role']}
        table={{
          image: createClassImage,
        }}
        leftToProcess={leftToProcess}
        errorsList={errors}
        totalToProcess={totalToProcess}
        onSave={this.onSave}
        uniqueness={{
          columns: ['externalUserId', 'externalSpaceId'],
        }}
        showInstitutionDetails
      />
    );
  }
}

CreateUserModal.defaultProps = {
  createRoles: () => {},
};

export default withStyles({}, { withTheme: true })(withRouter(CreateUserModal));
