import { connect } from 'react-redux';
import RemoveUsersFromSpaces from './RemoveUsersFromSpaces';
import { search } from '../../../redux/actions/search';
import { deleteRoles } from '../../../redux/actions/roles';
import { getSearchData as getUsersSearchData } from '../../../selectors/users';
import { getSearchData as getSpacesSearchData } from '../../../selectors/spaces';

const mapStateToProps = (state) => ({
  usersSearchData: getUsersSearchData(state),
  spacesSearchData: getSpacesSearchData(state),
  isSearching: state.search.isSearching,
});

const mapDispatchToProps = (dispatch) => ({
  search: (term, searchFor) => dispatch(search(term, searchFor)),
  deleteRoles: (roles) => dispatch(deleteRoles(roles)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RemoveUsersFromSpaces);
