import styled from '@emotion/styled';
import { theme as aulaTheme } from '@ublend-npm/aulaui-next';

export const OptionsContainer = styled.div`
  padding-left: ${aulaTheme.spacing.xLarge};
`;

export const OptionsHeading = styled.h2`
  font-size: ${aulaTheme.font.size.small};
  font-weight: ${aulaTheme.font.weight.regular};
  color: ${aulaTheme.color.designSystem.blackOpacity60};
`;

export const OptionRow = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${aulaTheme.spacing.small};
`;
