/* eslint-disable consistent-return */

type SSODetails = {
  sso?: unknown;
};

const getSSO = ({
  user: { sso: userSSO },
  institution: { sso: institutionSSO },
}: {
  user: SSODetails;
  institution: SSODetails;
}) => {
  // if institution wide flag is not configured, return undefined.
  // it will ignore sso field while parse user creation.
  if (!institutionSSO) {
    return undefined;
  }
  // user doesn't have sso, return institution wide sso
  if (typeof userSSO === 'undefined') {
    return institutionSSO;
  }

  return userSSO;
};

export default getSSO;
