import { connect } from 'react-redux';
import Space from './Space';
import {
  fetchSpaceById,
  fetchSpaceParticipants,
  updateSpace,
} from '../../../redux/actions/space';
import { addUsers } from '../../../redux/actions/addUsers';
import { createRoles, deleteRoles } from '../../../redux/actions/roles';
import { showToast } from '../../../redux/actions/toasts';

const mapStateToProps = (state) => ({
  institution: state.institution.general.institution,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSpace: (spaceId) => dispatch(fetchSpaceById(spaceId)),
  updateSpace: (space) => dispatch(updateSpace(space)),
  updateUserRole: (roles) => dispatch(createRoles(roles)),
  deleteRoles: (userIds, _spaceId) => dispatch(deleteRoles(userIds)),
  addUsersToAnotherSpace: (users) => dispatch(addUsers(users)),
  fetchParticipants: (spaceId, skip) =>
    dispatch(fetchSpaceParticipants({ spaceId, skip })),
  showToast: (payload) => dispatch(showToast(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Space);
