import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import classNames from 'classnames';

const styles = (theme) => ({
  container: {
    width: '100%',
  },
  inputLabel: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.common.darkGrey,
  },
  input: {
    fontSize: 14,
    paddingTop: 2,
  },
  error: {
    color: theme.palette.common.error,
    fontSize: 12,
    marginBottom: 24,
  },
  helperText: {
    color: theme.palette.common.darkGrey,
    fontSize: 12,
    marginBottom: 24,
  },
  inputLabelFocused: {
    color: theme.palette.common.green,
  },
  inputInkbar: {
    '&:after': {
      backgroundColor: theme.palette.common.green,
    },
  },
});

const TextField = ({
  classes,
  label,
  error,
  className,
  helperText,
  id,
  fullWidth,
  innerRef,
  InputLabelProps,
  ...rest
}) => (
  <FormControl
    ref={innerRef}
    className={classNames(className, { [classes.container]: fullWidth })}
    error={!!error}
    aria-describedby="name-error-text"
  >
    <InputLabel
      classes={{
        focused: classes.inputLabelFocused,
      }}
      htmlFor={id}
      {...InputLabelProps}
    >
      {label}
    </InputLabel>
    <Input
      classes={{
        input: classes.input,
      }}
      fullWidth={fullWidth}
      id={id}
      {...rest}
    />
    {!helperText && !error && <p>&nbsp;</p>}
    {!!helperText && !error && (
      <FormHelperText className={classes.helperText} id={`${id}-helperText`}>
        {helperText}
      </FormHelperText>
    )}
    {!!error && (
      <FormHelperText className={classes.error} id={`${id}-error`}>
        {error}
      </FormHelperText>
    )}
  </FormControl>
);

export default withStyles(styles, { withTheme: true })(TextField);
