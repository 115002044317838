import React from 'react';
import { SelectInput } from '@ublend-npm/aulaui-next';
import { LtiVersion } from '@ublend-npm/aula-schema';
import Configuration1p1, { Configuration1p1Args } from '../../Configuration1p1';
import { SubHeading } from '../DialogSteps.styles';
import { LtiVersionOptions } from '../../../../constants/ltiConstants';

type Lti1p1ConfigurationProps = {
  configuration: Configuration1p1Args;
  onConfigurationChange: (configuration1p1: Configuration1p1Args) => void;
  showErrors: boolean;
  ltiVersion: LtiVersion;
  onLtiVersionChange: (ltiVersion: LtiVersion) => void;
  isEditing: boolean;
};

const Lti1p1Configuration = ({
  configuration,
  onConfigurationChange,
  showErrors,
  ltiVersion,
  onLtiVersionChange,
  isEditing,
}: Lti1p1ConfigurationProps) => {
  return (
    <>
      <SubHeading>Configuration</SubHeading>
      <SelectInput
        name="LtiVersionInput"
        label="LTI version"
        data-testid="lti-version-select"
        data={LtiVersionOptions}
        value={ltiVersion}
        disabled={isEditing}
        handleChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          onLtiVersionChange(e.target.value as LtiVersion);
        }}
      />
      <Configuration1p1
        showErrors={showErrors}
        isEditing={isEditing}
        configuration={configuration}
        onChange={onConfigurationChange}
      />
    </>
  );
};

export default Lti1p1Configuration;
