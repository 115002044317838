import React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Button, TextField, SelectInput, icons } from '@ublend-npm/aulaui-next';
import { styles } from './CustomParameters.styles';
import {
  Parameter,
  STATIC_PARAM_VALUE,
  FormParameters,
  CustomOption,
  ParameterRenderTypes,
} from './types';
import DeleteParameterButton from './DialogSteps/components/DeleteParameterButton';
import CustomOptions from './DialogSteps/components/CustomOptions';

type ParametersProps = WithStyles<typeof styles> &
  Readonly<{
    allParameters: FormParameters;
    selectedCategory: keyof FormParameters;
    showErrors: boolean;
    onChange: (updatedParameters: FormParameters) => void;
    title: string;
    whitelistedParameters: Parameter[];
  }>;

const DUPLICATE_KEY_ERROR = 'Duplicate parameter name.';
const EMPTY_NAME_ERROR = 'Enter a name.';
const CONTAINS_SPACES_ERROR = "Name can't contain spaces.";
const EMPTY_DROPDOWN_LABEL_ERROR = 'Enter a label.';

const EMPTY_OPTION = {
  label: '',
  value: '',
  labelError: 'Enter a label.',
  valueError: 'Enter a value.',
};

const Parameters = ({
  classes,
  allParameters,
  selectedCategory,
  showErrors,
  onChange,
  title,
  whitelistedParameters,
}: ParametersProps) => {
  const getKeyError = (keyName: string): string | null => {
    const usedKeyNames = Object.values(allParameters)
      .flatMap((paramArray) => paramArray)
      .map(({ key }) => key);

    if (!keyName) {
      return EMPTY_NAME_ERROR;
    }

    if (keyName.indexOf(' ') >= 0) {
      return CONTAINS_SPACES_ERROR;
    }

    if (usedKeyNames.includes(keyName)) {
      return DUPLICATE_KEY_ERROR;
    }

    return null;
  };

  const getValueError = (value: string): string | null => {
    if (!value) {
      return 'Select a value.';
    }

    return null;
  };

  const getDropdownLabelError = (value: string): string | null => {
    if (!value.trim()) {
      return EMPTY_DROPDOWN_LABEL_ERROR;
    }

    return null;
  };

  const getStaticValueError = (value: string): string | null => {
    if (value.startsWith('$')) {
      return 'Value cannot start with a "$"';
    }

    return null;
  };

  const handleAddCustomParameter = () => {
    const updatedParameters = {
      ...allParameters,
      [selectedCategory]: [
        ...allParameters[selectedCategory],
        {
          key: '',
          value: '',
          keyError: getKeyError(''),
          valueError: getValueError(''),
          dropdownLabelError: getDropdownLabelError(''),
        },
      ],
    };

    onChange(updatedParameters);
  };

  const revalidateDuplicateKeyErrors =
    (updatedIndex?: number) =>
    (updatedParameters: FormParameters, paramKey: string) => {
      return updatedParameters[paramKey].map((param, idx) => {
        const occurrencesOfKey = Object.values(updatedParameters)
          .flat()
          .map(({ key }) => key)
          .filter((key) => key === param.key).length;

        if (
          (!param.keyError || param.keyError === DUPLICATE_KEY_ERROR) &&
          idx !== updatedIndex
        ) {
          return {
            ...param,
            keyError: occurrencesOfKey > 1 ? DUPLICATE_KEY_ERROR : null,
          };
        }
        return param;
      });
    };

  const getRevalidatedParameters = (updated: Parameter[], index?: number) => {
    const allUpdatedParameters = {
      ...allParameters,
      [selectedCategory]: updated,
    };

    const revalidateDuplicates = revalidateDuplicateKeyErrors(index);

    return Object.keys(allUpdatedParameters).reduce((acc, paramKey) => {
      acc[paramKey] = revalidateDuplicates(allUpdatedParameters, paramKey);
      return acc;
    }, {} as FormParameters);
  };

  const handleUpdateCustomParameterKey =
    (updatedIdx: number) =>
    ({ target: { value } }) => {
      const updated = allParameters[selectedCategory].map((param, idx) =>
        idx === updatedIdx
          ? { ...param, key: value, keyError: getKeyError(value) }
          : param,
      );

      onChange(getRevalidatedParameters(updated, updatedIdx));
    };

  const handleUpdateCustomParameterDropdownLabel =
    (updatedIdx: number) =>
    ({ target: { value } }) => {
      const updated = allParameters[selectedCategory].map((param, idx) =>
        idx === updatedIdx
          ? {
              ...param,
              dropdownLabel: value,
              dropdownLabelError: getDropdownLabelError(value),
            }
          : param,
      );

      onChange(getRevalidatedParameters(updated, updatedIdx));
    };

  const handleUpdateCustomParameterStaticValue =
    (updatedIdx: number) =>
    ({ target: { value } }) => {
      const updatedParameters = {
        ...allParameters,
        [selectedCategory]: allParameters[selectedCategory].map((param, idx) =>
          idx === updatedIdx
            ? {
                ...param,
                staticValue: value,
                valueError: getStaticValueError(value),
              }
            : param,
        ),
      };

      onChange(updatedParameters);
    };

  const handleUpdateCustomParameterValue =
    (updatedIdx: number) =>
    ({ target: { value } }) => {
      const updatedParameters = {
        ...allParameters,
        [selectedCategory]: allParameters[selectedCategory].map(
          (param, idx) => {
            if (idx !== updatedIdx) return param;

            const staticValue = '';

            const updates: Partial<Parameter> = {
              value,
              valueError: getValueError(value),
              ...(value === ParameterRenderTypes.DROPDOWN && {
                options: [EMPTY_OPTION],
              }),
              ...(value === STATIC_PARAM_VALUE && {
                staticValue,
                valueError: getStaticValueError(staticValue),
              }),
            };

            return { ...param, ...updates };
          },
        ),
      };

      onChange(updatedParameters);
    };

  const updateOptions = (parentKey, updateFn) => {
    const updatedParameters = {
      ...allParameters,
      [selectedCategory]: allParameters[selectedCategory].map((param, idx) =>
        idx === parentKey
          ? {
              ...param,
              options: updateFn(param.options || []),
            }
          : param,
      ),
    };

    onChange(updatedParameters);
  };

  const handleAddOption = (parentKey: number) => {
    updateOptions(parentKey, (options: CustomOption[]) => [
      ...options,
      EMPTY_OPTION,
    ]);
  };

  const handleDeleteOption = (parentKey: number, deletedIdx: number) => {
    updateOptions(parentKey, (options: CustomOption[]) =>
      options.filter((_, index) => index !== deletedIdx),
    );
  };

  const handleChangeOption =
    (parentKey: number) =>
    (updatedOption: CustomOption, optionIndex: number) => {
      updateOptions(parentKey, (options: CustomOption[]) =>
        options.map((option, idx) =>
          idx === optionIndex ? updatedOption : option,
        ),
      );
    };

  const handleDeleteCustomParameter = (deletedIdx: number) => () => {
    const updated = allParameters[selectedCategory].filter(
      (_, idx) => idx !== deletedIdx,
    );

    onChange(getRevalidatedParameters(updated));
  };

  const withStaticParam: Parameter[] = [
    ...whitelistedParameters,
    {
      key: STATIC_PARAM_VALUE,
      value: 'Other',
    },
  ];

  return (
    <div>
      {allParameters[selectedCategory].map(
        (
          {
            key,
            value,
            keyError,
            valueError,
            staticValue,
            dropdownLabel,
            dropdownLabelError,
            options = [],
          }: Parameter,
          idx,
        ) => {
          const isStatic = value === STATIC_PARAM_VALUE;
          const isDropdown = value === ParameterRenderTypes.DROPDOWN;

          return (
            <div key={idx} className={classes.customParameterContainer}>
              <div className={classes.customParameter}>
                <TextField
                  autoFocus={!key}
                  label="Name"
                  value={key}
                  onChange={handleUpdateCustomParameterKey(idx)}
                  error={showErrors && !!keyError}
                  helperText={showErrors ? keyError : undefined}
                  data-testid="custom-parameter-name-field"
                />
                <SelectInput
                  name="value"
                  label="Value"
                  data={withStaticParam}
                  value={value}
                  hasError={showErrors && !!valueError && !isStatic} // If static, then error is with free text input not this select
                  errorMessage={showErrors ? valueError : undefined}
                  classes={{
                    formControl: classes.customParameterInput,
                    select: classes.customParameterSelect,
                    selectInputLabelInLine: classes.customParameterLabel,
                  }}
                  handleChange={handleUpdateCustomParameterValue(idx)}
                  data-testid="custom-parameter-value-field"
                />
                <DeleteParameterButton
                  handleClick={handleDeleteCustomParameter(idx)}
                />
              </div>

              {isDropdown && (
                <>
                  <TextField
                    name="dropdown-label"
                    label="Dropdown label"
                    value={dropdownLabel}
                    onChange={handleUpdateCustomParameterDropdownLabel(idx)}
                    error={showErrors && !!dropdownLabelError}
                    helperText={showErrors ? dropdownLabelError : undefined}
                    data-testid="custom-parameter-dropdown-label-field"
                  />
                  <CustomOptions
                    options={options}
                    renderType={ParameterRenderTypes.DROPDOWN}
                    idx={idx}
                    showErrors={showErrors}
                    onAddOption={handleAddOption}
                    onDeleteOption={handleDeleteOption}
                    onChangeOption={handleChangeOption(idx)}
                  />
                </>
              )}
              {isStatic ? (
                <TextField
                  autoFocus={!staticValue}
                  name="static-value"
                  label="Value"
                  value={staticValue}
                  onChange={handleUpdateCustomParameterStaticValue(idx)}
                  error={showErrors && !!valueError}
                  helperText={showErrors ? valueError : undefined}
                  data-testid="custom-parameter-static-value-field"
                />
              ) : null}
            </div>
          );
        },
      )}
      <Button
        type="text"
        iconLeft={icons.AddIcon}
        onClick={handleAddCustomParameter}
      >
        {title}
      </Button>
    </div>
  );
};

export default withStyles(styles)(Parameters);
