import { isLocal } from './isLocal';

export const buildInstitutionURL = ({ subdomain = 'apiv2', path = '' }) => {
  const host = window.location.hostname;
  const institution = process.env.INSTITUTION || 'mock'; // for unit test we use 'mock' instead
  const re = /([.])(?:(?=(\\?))\2.)*?\1/;
  const inst = isLocal ? `.${institution}.` : host.match(re)[0];
  return `https://${subdomain}${inst}aula.education/${path}`;
};

export const redirectToGeneral = ({
  returnAfterLogin,
}: {
  returnAfterLogin: boolean;
}) => {
  const path = returnAfterLogin
    ? `/?redirect=${window.location.href}`
    : '/#/select';
  window.location.replace(
    `${process.env.WEB_AUTH_URL || 'https://general.aula.education'}${path}`,
  );
};
