import types from '../../constants/actions';

const initialState = {
  user: null,
  userId: null,
  isLoggingIn: false,
  isLoggingOut: false,
  isLogged: false,
  isErrored: false,
};

const userLoginReducer = (state = initialState, action) => {
  switch (action.status) {
    case 'started':
      return { ...state, isLoggingIn: true };
    case 'success':
      return {
        ...initialState,
        user: action.user,
        userId: action.user.id,
        isLogged: true,
      };
    case 'error':
      return {
        ...state,
        isErrored: true,
        isLoggingIn: false,
      };

    default:
      return state;
  }
};

const updateUserReducer = (state = initialState, action) => {
  switch (action.status) {
    case 'started':
      return { ...state, isFetching: true };
    case 'success': {
      const isLoggedInUserUpdate = action.user.id === state.user.id;
      return {
        ...state,
        user: isLoggedInUserUpdate
          ? {
              ...state.user,
              ...action.user,
            }
          : state.user,
        isFetching: false,
      };
    }
    case 'error':
      return {
        ...state,
        isErrored: true,
        isFetching: false,
      };

    default:
      return state;
  }
};

const userReducer = (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case types.USER_LOGIN:
      return userLoginReducer(state, action);
    case types.UPDATE_USER:
      return updateUserReducer(state, action);
    default:
      return state;
  }
};

export default userReducer;
