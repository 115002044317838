import { createStyles } from '@material-ui/core/styles';

export const styles = (theme) =>
  createStyles({
    customParameter: {
      display: 'flex',
      '&> div:not(:last-child)': {
        marginRight: 12,
      },
    },
    customParameterContainer: {
      marginBottom: 12,
    },
    deleteContainer: {
      display: 'flex',
      alignItems: 'center',
      height: 40,
      marginTop: 8,
    },
    customParameterSelect: {
      height: 40,
    },
    customParameterInput: {
      width: 197,
      marginBottom: 4,
    },
    customParameterLabel: {
      marginTop: 20,
    },
    deleteIcon: {
      color: theme.palette.common.black,
    },
  });
