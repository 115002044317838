import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
  button: {
    height: 40,
    textTransform: 'none',
    paddingLeft: 20,
    paddingRight: 20,
    minWidth: 73,
    borderRadius: 4,
  },
  green: {
    backgroundColor: theme.palette.common.green,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: lighten(theme.palette.common.green, 0.5),
    },
  },
  grey: {
    backgroundColor: theme.palette.common.grey,
    color: theme.palette.common.darkGrey,
    '&:hover': {
      backgroundColor: lighten(theme.palette.common.grey, 0.5),
    },
  },
  lightGrey: {
    backgroundColor: theme.palette.common.lightGrey,
    color: theme.palette.common.green,
    '&:hover': {
      backgroundColor: lighten(theme.palette.common.lightGrey, 0.5),
    },
  },
  white: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: lighten(theme.palette.common.white, 0.5),
    },
  },
  darkBlue: {
    backgroundColor: theme.palette.common.darkBlue,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: lighten(theme.palette.common.darkBlue, 0.5),
    },
  },
  disabled: {
    opacity: 0.3,
    color: `${theme.palette.common.white} !important`,
  },
  buttonProgress: {
    color: theme.palette.common.white,
  },
});

const FlatButton = ({
  classes,
  color,
  children,
  className,
  loading,
  ...rest
}) => {
  const Loader = () => (
    <CircularProgress size={24} className={classes.buttonProgress} />
  );

  return (
    <Button
      variant="text"
      className={classNames(classes.button, className, {
        [classes[color]]: true,
      })}
      classes={{
        disabled: classes.disabled,
      }}
      disabled={loading}
      {...rest}
    >
      {loading ? <Loader /> : children}
    </Button>
  );
};

export default withStyles(styles as any, { withTheme: true })(FlatButton);
