import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Modal } from '@ublend-npm/aulaui-next';
import Tile from './Tile';

const styles = (theme) => ({
  content: {
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
});

const LoaderScreen = ({ classes, open, tileProps }) => {
  if (!open) return null;

  return (
    <Modal open={open}>
      {!tileProps && (
        <div className={classes.content}>
          <div>
            <CircularProgress />
          </div>
          <div className={classes.bold}>
            The eggs are cooking.{' '}
            <span role="img" aria-label="fryingpan">
              🍳
            </span>
          </div>
          <div>
            This can take up to a few minutes. Please keep this page open.
          </div>
        </div>
      )}
      {tileProps && <Tile {...tileProps} />}
    </Modal>
  );
};

export default withStyles(styles as any, { withTheme: true })(LoaderScreen);
