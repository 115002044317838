import CONSTS from '../../constants/actions';
import { SEARCH } from '../../constants/endpoints';
import { request } from '../../utils/api';
import { transformElasticSearchObjects } from '../../utils/transform';

const searchStart = (dispatch) => {
  dispatch({
    type: CONSTS.SEARCH,
    status: 'started',
  });
};

const searchError = (dispatch) => {
  dispatch({
    type: CONSTS.SEARCH,
    status: 'error',
  });
};

const searchSuccess = (dispatch, results) => {
  dispatch({
    type: CONSTS.SEARCH,
    status: 'success',
    data: results,
  });
};

export const search =
  (query, searchFor = ['users', 'spaces'], size = 100) =>
  async (dispatch) => {
    searchStart(dispatch);
    try {
      const { users, spaces } = await request({
        route: SEARCH(query, searchFor.join(','), size),
        dispatch,
      });
      searchSuccess(
        dispatch,
        transformElasticSearchObjects([
          ...(users ? [{ users }] : []),
          ...(spaces ? [{ spaces }] : []),
        ]),
      );
    } catch (error) {
      searchError(dispatch);
    }
  };

export default search;
