import { request } from '../../../utils/api';
import {
  FETCH_USER,
  CREATE_USERS,
  UPDATE_USER,
  DELETE_USERS_FROM_INSTITUTION,
} from '../../../constants/endpoints';
import { showToast } from '../toasts';
import actionTypes from '../../../constants/actions';

export const fetchUserById = (userId) => async (dispatch) => {
  try {
    const { user } = await request({
      route: `${FETCH_USER}/${userId}`,
      dispatch,
    });
    return user;
  } catch (error) {
    return false;
  }
};

export const create = (users) => async (dispatch) => {
  try {
    const response = await request({
      route: CREATE_USERS,
      data: { users },
      method: 'post',
      dispatch,
    });

    if (response.errors.length) {
      const error = new Error(
        response.errors
          .map((e) => `"${e.message}" for user "${e.externalId}"`)
          .join('\n'),
      );
      (error as any).metadata = response.errors;
      throw error;
    }

    return response;
  } catch (e) {
    throw Error(e.message);
  }
};

const updateUserStart = () => ({
  type: actionTypes.UPDATE_USER,
  status: 'started',
});

const updateUserError = () => ({
  type: actionTypes.UPDATE_USER,
  status: 'started',
});

const updateUserSuccess = (user) => (dispatch) =>
  dispatch({
    type: actionTypes.UPDATE_USER,
    status: 'success',
    user,
  });

export const update =
  ({ userId, data }) =>
  async (dispatch) => {
    try {
      dispatch(updateUserStart());
      const { user } = await request({
        route: UPDATE_USER(userId),
        data,
        method: 'patch',
        dispatch,
      });
      const updatedUser = {
        ...user,
        avatar: 'avatar' in user ? user.avatar : undefined,
      };
      dispatch(updateUserSuccess(updatedUser));

      return user;
    } catch (e) {
      dispatch(
        showToast({
          message: e.response.data.message || e.response.data || e.message,
        }),
      );
      dispatch(updateUserError());
      throw Error(e.message);
    }
  };

export const deleteUsersFromInstitution = (users) => async (dispatch) => {
  try {
    return await request({
      route: DELETE_USERS_FROM_INSTITUTION,
      data: { users },
      method: 'post',
      dispatch,
    });
  } catch (e) {
    dispatch(
      showToast({
        message: e.response.data.message || e.response.data || e.message,
      }),
    );
    throw Error(e.message);
  }
};
