/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ModalBox from '../../shared/ModalBox/ModalBox';
import createClassImage from '../AddUser/AddUserImage.png';
import { getUniqData } from '../../../utils/duplicate';
import { isValidEmail } from '../../../utils/email';
import CreateUserConfirmation from './CreateUserConfirmation';
import CreateUserSuccess from './CreateUserSuccess';
import chunkProcess from '../../../utils/chunkProcess';

const CHUNK_SIZE = 50;

const formatUserToSave = ({ admin, staff, email, ...user }) => {
  const toSave = {
    ...user,
    email: email.toLowerCase(),
    userRoles: ['general'],
  };
  if (admin) {
    toSave.userRoles.push('admin');
  }
  if (staff) {
    toSave.userRoles.push('staff');
  }
  return toSave;
};

const confirmationSectionConfig = {
  getConfirmationMessage: (data) => <CreateUserConfirmation {...data} />,
  getSuccessMessage: (data) => <CreateUserSuccess {...data} />,
};

const rightPanelConfig = {
  getRightPanelTitle: (entityName) => `Creating ${entityName}`,
  getPlaceholderTitle: () => 'Start by adding users.',
};

interface CreateUserModalProps extends RouteComponentProps {
  sso?: boolean;
  createUsers?: (users: unknown[]) => void;
}

class CreateUserModal extends Component<CreateUserModalProps> {
  static defaultProps: Partial<CreateUserModalProps>;

  state = {
    leftToProcess: null,
    errors: null,
    totalToProcess: undefined,
  };

  onSave = async (data) => {
    const users = getUniqData(data.user).map(formatUserToSave);

    // if no new user to be added, close the modal
    if (!users.length) {
      return true;
    }

    await chunkProcess({
      data: users,
      chunkSize: CHUNK_SIZE,
      onRequest: this.props.createUsers,
      onProgress: (newData) => this.setState(newData),
      onError: (error) =>
        this.setState({
          errors: [...(this.state.errors || []), error],
        }),
    });

    return true;
  };

  render() {
    const { createUsers, sso: institutionSSO } = this.props;
    const { errors, leftToProcess, totalToProcess } = this.state;
    const columns = [
      'firstName',
      'lastName',
      'email',
      'externalId',
      'admin',
      'staff',
    ];
    // don't add sso column if the institution doesn't support it
    if (institutionSSO) {
      columns.push('sso');
    }

    return (
      <ModalBox
        title="user"
        create={createUsers}
        modalSubtitle="Create users"
        rightPanelConfig={rightPanelConfig}
        confirmationSectionConfig={confirmationSectionConfig}
        manualAdd={{
          tabTitle: 'Add a new User',
          textFields: {
            firstName: {
              title: 'First name',
              placeholder: 'First name',
            },
            lastName: {
              title: 'Last name',
              placeholder: 'Last name',
            },
            email: {
              title: 'Email',
              placeholder: 'email@university.edu',
            },
            externalId: {
              title: 'External ID',
              placeholder: 'email@university.edu',
            },
          },
          switchFields: {
            sso: {
              title: 'SSO Enabled',
              defaultChecked: institutionSSO,
              value: institutionSSO,
              name: 'sso',
              // if institution wide sso flag is off, disable switch
              disabled: !institutionSSO,
            },
            admin: {
              title: 'Admin',
              defaultChecked: false,
              name: 'admin',
            },
            staff: {
              title: 'Staff',
              defaultChecked: false,
              name: 'staff',
            },
          },
          actionButton: {
            title: 'Add User',
          },
          validateFields: (value, field) => {
            const invalidName = field === 'name' && value.trim() === '';
            const toMsg = (msg) => ({ message: msg });

            if (invalidName) {
              return toMsg("Field can't be empty");
            }

            if (field === 'email' && !isValidEmail(value)) {
              return toMsg('Invalid email format');
            }

            return null;
          },
        }}
        csvLeftPanelAction={{
          tabTitle: 'import a CSV of users',
        }}
        columns={columns}
        table={{
          image: createClassImage,
        }}
        leftToProcess={leftToProcess}
        errorsList={errors}
        totalToProcess={totalToProcess}
        onSave={this.onSave}
        uniqueness={{
          columns: ['externalId'],
        }}
        showInstitutionDetails
      />
    );
  }
}

CreateUserModal.defaultProps = {
  createUsers: () => {},
};

export default withStyles({}, { withTheme: true })(withRouter(CreateUserModal));
