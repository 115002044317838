import React from 'react';
import pluralize from 'pluralize';

interface AddUserSuccessProps {
  selectedRole: string;
  uniqUsersLength: number;
  uniqSpacesLength: number;
  parentClasses?: {
    bold?: string;
  };
}

const AddUserSuccess = ({
  parentClasses,
  uniqUsersLength,
  selectedRole,
  uniqSpacesLength,
}: AddUserSuccessProps) => (
  <span>
    You just added{' '}
    <span className={parentClasses.bold}>
      {pluralize('user', uniqUsersLength, true)}
    </span>{' '}
    as {pluralize(selectedRole, uniqUsersLength, false)} in{' '}
    <span className={parentClasses.bold}>
      {pluralize('space', uniqSpacesLength, true)}
    </span>
  </span>
);

export default AddUserSuccess;
