import types from '../../constants/actions';

const initialState = {
  name: 'Aula',
  fetched: false,
  isFetching: false,
  keys: {},
  general: {},
  user: {
    isCheckingEmail: false,
    email: '',
    institutions: [],
  },
};

const fetchInstituionInformation = (state = initialState, action) => {
  switch (action.status) {
    case 'started':
      return { ...state, isFetching: true };
    case 'success': {
      return {
        ...state,
        general: action.results.general,
        name: action.results.general.display_name,
        fetched: true,
        isFetching: false,
        keys: {
          ...state.keys,
          launchDarkly: action.results.launchDarkly,
        },
      };
    }
    case 'error':
      return {
        ...state,
      };

    default:
      return state;
  }
};

const checkInstitutionInformation = (state = initialState, action) => {
  switch (action.status) {
    case 'started': {
      return {
        ...state,
        user: {
          ...state.user,
          isCheckingEmail: true,
        },
      };
    }
    case 'success': {
      return {
        ...state,
        user: {
          ...state.user,
          institutions: action.results,
          isCheckingEmail: false,
          email: action.email,
        },
      };
    }
    case 'error':
      return {
        ...state,
      };

    default:
      return state;
  }
};

const institutionReducer = (state: any = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case types.FETCH_INSTITUTION:
      return fetchInstituionInformation(state, action);
    case types.CHECK_INSTITUTION:
      return checkInstitutionInformation(state, action);
    default:
      return state;
  }
};

export default institutionReducer;
