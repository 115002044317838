import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import Space from './Space.container';

const SpaceWithFeatureFlag = (props) => {
  const { adminFtOpenSpaceToggleEnabled202108 } = useFlags();
  return (
    <Space
      showOpenSpaceFeatureFlag={adminFtOpenSpaceToggleEnabled202108}
      {...props}
    />
  );
};

export default SpaceWithFeatureFlag;
