/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import curry from 'lodash/curryRight';
import ModalBox from '../../shared/ModalBox/ModalBox';
import DeleteUserConfirmation from './DeleteUserConfirmation';
import DeleteUserSuccess from './DeleteUserSuccess';
import deleteUserImage from './DeleteUserImage.png';
import chunkProcess from '../../../utils/chunkProcess';

const CHUNK_SIZE = 50;

const MODAL_PROPS = (
  props: any = {
    usersSearchData: [],
    search: null,
  },
) => {
  const curriedSearch = props.search ? curry(props.search) : (_) => {};
  return {
    deleteUsers: {
      title: 'user',
      modalSubtitle: 'Delete users from institution',
      uniqueness: {
        columns: ['externalId'],
      },
      csvLeftPanelAction: {
        tabTitle: 'import a CSV of users',
        actionButton: {
          title: 'Import',
        },
      },
      columns: ['email', 'externalId'],
      table: {
        image: deleteUserImage,
      },
      search: {
        data: props.usersSearchData,
        extractSelected: (item) => ({
          externalId: item.subtitle,
          email: item.email,
        }),
        search: curriedSearch(['users']),
        isSearching: props.isSearching,
      },
      rightPanelConfig: {
        getRightPanelTitle: (entities) => `Deleting ${entities}`,
        getPlaceholderTitle: () => 'Start by selecting users.',
      },
    },
  };
};

const confirmationSectionConfig = {
  getConfirmationMessage: (data) => <DeleteUserConfirmation {...data} />,
  getSuccessMessage: (data) => <DeleteUserSuccess {...data} />,
};

interface DeleteUsersFromInstitutionModalProps extends RouteComponentProps {
  search?: (term: unknown, searchFor?: unknown) => void;
  deleteUsers?: (users: unknown[]) => void;
  usersSearchData?: unknown[];
  isSearching?: boolean;
}

class DeleteUsersFromInstitutionModal extends Component<DeleteUsersFromInstitutionModalProps> {
  static defaultProps: Partial<DeleteUsersFromInstitutionModalProps>;

  state = {
    step: 'deleteUsers',
    modalProps: MODAL_PROPS().deleteUsers,
    loading: false,
    confirmModalProps: null,
    leftToProcess: null,
    selected: {
      user: [],
    },
    totalToProcess: undefined,
    errors: undefined,
  };

  static getDerivedStateFromProps = (nextProps, prevState) => ({
    ...prevState,
    modalProps: MODAL_PROPS(nextProps)[prevState.step],
  });

  componentWillMount = () => {
    this.setState({
      modalProps: MODAL_PROPS(this.props)[this.state.step],
    });
  };

  onSave = async (data) => {
    const users = data.user.map(({ externalId }) => externalId);

    if (!users.length) {
      return;
    }

    await chunkProcess({
      data: users,
      chunkSize: CHUNK_SIZE,
      onRequest: this.props.deleteUsers,
      onProgress: (newData) => this.setState(newData),
      onError: (error) =>
        this.setState({
          errors: [...(this.state.errors || []), error],
        }),
    });
  };

  render() {
    const {
      step,
      errors,
      selected,
      modalProps,
      leftToProcess,
      totalToProcess,
      confirmModalProps,
    } = this.state;

    return (
      <div>
        <ModalBox
          {...modalProps}
          danger
          selected={selected}
          confirmModalProps={confirmModalProps}
          onSave={this.onSave}
          leftToProcess={leftToProcess}
          totalToProcess={totalToProcess}
          errorsList={errors}
          step={step}
          showInstitutionDetails
          confirmationSectionConfig={confirmationSectionConfig}
        />
      </div>
    );
  }
}

DeleteUsersFromInstitutionModal.defaultProps = {
  search: () => {},
  deleteUsers: () => {},
};

export default withStyles(
  {},
  { withTheme: true },
)(withRouter(DeleteUsersFromInstitutionModal));
