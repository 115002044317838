import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import FlatButton from '../Buttons/FlatButton';

const styles = (theme) => ({
  container: {
    padding: 24,
    width: 600,
  },
  heading: {
    marginBottom: 20,
    fontSize: 18,
    fontWeight: theme.typography.fontWeightRegular,
    display: 'flex',
    justifyContent: 'space-between',
  },
  actions: {
    margin: '24px -4px -4px 0px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  confirmButton: {
    marginLeft: 20,
  },
  circularProgress: {
    color: theme.palette.common.green,
  },
});

const Tile = ({
  classes,
  heading,
  text,
  loader,
  cancelButton,
  confirmButton,
}) => (
  <Paper className={classes.container} elevation={6}>
    <div className={classes.content}>
      <div className={classes.heading}>
        <span>{heading}</span>
        {loader && (
          <span className={classes.circularProgress}>
            <CircularProgress size={24} />
          </span>
        )}
      </div>
      <div className={classes.text}>{text}</div>
      {(cancelButton || confirmButton) && (
        <div className={classes.actions}>
          {cancelButton && (
            <span>
              <FlatButton onClick={cancelButton.action} color="grey">
                {cancelButton.label || 'Cancel'}
              </FlatButton>
            </span>
          )}
          {confirmButton && (
            <span className={classes.confirmButton}>
              <FlatButton
                onClick={confirmButton.action}
                color="green"
                loading={confirmButton.loading}
              >
                {confirmButton.label || 'Confirm'}
              </FlatButton>
            </span>
          )}
        </div>
      )}
    </div>
  </Paper>
);

export default withStyles(styles, { withTheme: true })(Tile);
