import { theme } from '@ublend-npm/aulaui-next';

const { color } = theme;

export default {
  lightBlue: '#a6b4cc',
  darkBlue: '#31405c',
  overlay: color.overlay,
  purple0: color.purple0,
  purple1: color.purple1,
  grey90: color.designSystem.grey90,
  grey60: color.designSystem.grey60,
};
