import React from 'react';
import { StepperContainer, Step } from './Stepper.styled';

type StepperProps = {
  steps: number;
  completedSteps: number;
};

const Stepper = ({ steps, completedSteps }: StepperProps) => {
  return (
    <StepperContainer>
      {[...Array(steps)].map((_, index) => (
        <Step
          key={index}
          active={index < completedSteps}
          tabIndex={index + 1 === completedSteps ? 0 : -1}
          aria-label={`Step ${index + 1} of ${steps}`}
        />
      ))}
    </StepperContainer>
  );
};

export default Stepper;
