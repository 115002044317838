import { connect } from 'react-redux';
import CreateClass from './CreateUser';
import { create } from '../../../redux/actions/user';

const mapStateToProps = (state) => ({
  sso: state.institution.general.sso,
});

const mapDispatchToProps = (dispatch) => ({
  createUsers: (users) => dispatch(create(users)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateClass);
