import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import startCase from 'lodash/startCase';
import Checkbox from '@material-ui/core/Checkbox';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const styles = (theme) => ({
  checkbox: {
    color: theme.palette.common.green,
  },
  cell: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  checkboxCell: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: 0,
    textAlign: 'center',
  },
});

const TableHeader = (props) => {
  const {
    onSelectAllClick,
    numSelected,
    rowCount,
    columns,
    classes,
    orderBy,
    order,
    onRequestSort,
    readOnly,
  } = props;
  return (
    <TableHead>
      <TableRow>
        {!readOnly && (
          <TableCell className={classes.checkboxCell}>
            <Checkbox
              className={classes.checkbox}
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {columns.map((column) => (
          <TableCell
            className={classes.cell}
            key={column || Math.floor(Math.random() * 1000)}
          >
            <TableSortLabel
              active={orderBy === column}
              direction={order}
              onClick={(e) => onRequestSort(e, column)}
              // style={{ fontSize: 14, fontWeight: 'normal', color: '#9b9b9b' }}
            >
              {startCase(column)}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default withStyles(styles as any, { withTheme: true })(TableHeader);
