export const SEARCH = (query: string, indices: string, size: number) =>
  `search/admin/${encodeURIComponent(query)}?indices=${indices}&size=${size}`;
export const FETCH_USER = 'users';
export const UPDATE_SPACE = 'spaces';
export const CREATE_SPACE = 'admin/import/spaces';
export const FETCH_SPACE = 'spaces';
export const FETCH_SPACE_PARTICIPANTS = ({ spaceId, skip = 0 }) =>
  `spaces/${spaceId}/participants?skip=${skip}`;
export const CREATE_USERS = 'admin/import/users';
export const CREATE_ROLES = 'admin/import/roles';
export const DELETE_ROLES = 'admin/delete/roles';
export const UPDATE_USER = (userId) => `users/${userId}`;
export const DELETE_USERS_FROM_INSTITUTION = 'admin/delete/users';
export const FETCH_INTEGRATIONS = 'lti/providers';
export const FETCH_INTEGRATION_BY_ID = (providerId) =>
  `lti/providers/${providerId}`;
export const DELETE_INTEGRATION = (providerId) => `lti/providers/${providerId}`;
export const ADD_INTEGRATION = 'lti/providers';
export const EDIT_INTEGRATION = (providerId) => `lti/providers/${providerId}`;
