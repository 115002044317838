import { LtiLaunchContainer, LtiVersion } from '@ublend-npm/aula-schema';

export const LaunchContainerOptions = [
  { key: LtiLaunchContainer.WINDOW, value: 'As a new tab' },
  { key: LtiLaunchContainer.IFRAME, value: 'Embedded' },
];

export const LtiVersionOptions = [
  { key: LtiVersion.ONE_POINT_ONE, value: LtiVersion.ONE_POINT_ONE },
  { key: LtiVersion.ONE_POINT_THREE, value: LtiVersion.ONE_POINT_THREE },
];
