import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from '@ublend-npm/aulaui-next';

const styles = () => ({
  dialog: {
    width: 492,
  },
  dialogTitle: {
    paddingTop: 20,
    fontSize: 16,
  },
  dialogContent: {
    paddingTop: 0,
    paddingBottom: 8,
  },
  dialogActions: {
    paddingBottom: 22,
    paddingRight: 24,
  },
});

const ErrorDialog = ({ open, title, onClose, classes }) => (
  <Dialog
    classes={{ paper: classes.dialog }}
    open={open}
    onClose={onClose}
    data-testid="error-dialog"
  >
    <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
    <DialogContent className={classes.dialogContent}>
      An error occured, please contact the Aula Help Centre.
    </DialogContent>
    <DialogActions className={classes.dialogActions}>
      <Button type="primary" size="medium" onClick={onClose}>
        Done
      </Button>
    </DialogActions>
  </Dialog>
);

export default withStyles(styles)(ErrorDialog);
