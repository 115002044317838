import React from 'react';
import { TextField } from '@ublend-npm/aulaui-next';
import { getUrlError } from '../../utils/getURLError';
import { UrlWithError } from './types';

export type Configuration1p3Args = Readonly<{
  targetUrl: UrlWithError;
  loginUrl: UrlWithError;
  keysetUrl: UrlWithError;
}>;

type Configuration1p3Props = Readonly<{
  showErrors: boolean;
  configuration: Configuration1p3Args;
  onChange: (configuration: Configuration1p3Args) => unknown;
}>;

const Configuration1p3 = ({
  showErrors,
  configuration,
  onChange,
}: Configuration1p3Props) => {
  const { targetUrl, loginUrl, keysetUrl } = configuration;

  const handleChangeTargetUrl = ({ target: { value } }) => {
    onChange({
      ...configuration,
      targetUrl: { value, error: getUrlError(value) },
    });
  };

  const handleChangeLoginUrl = ({ target: { value } }) => {
    onChange({
      ...configuration,
      loginUrl: { value, error: getUrlError(value) },
    });
  };

  const handleChangeKeysetUrl = ({ target: { value } }) => {
    onChange({
      ...configuration,
      keysetUrl: { value, error: getUrlError(value) },
    });
  };

  return (
    <>
      <TextField
        label="Target URL"
        value={targetUrl.value}
        onChange={handleChangeTargetUrl}
        error={showErrors && !!targetUrl.error}
        helperText={showErrors ? targetUrl.error : undefined}
      />
      <TextField
        label="Login URL"
        value={loginUrl.value}
        onChange={handleChangeLoginUrl}
        error={showErrors && !!loginUrl.error}
        helperText={showErrors ? loginUrl.error : undefined}
      />
      <TextField
        label="Keyset URL"
        value={keysetUrl.value}
        onChange={handleChangeKeysetUrl}
        error={showErrors && !!keysetUrl.error}
        helperText={showErrors ? keysetUrl.error : undefined}
      />
    </>
  );
};

export default Configuration1p3;
