import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = () => ({
  linearProgress: {
    position: 'absolute',
    width: '100%',
    zIndex: 1000000,
    top: 0,
  },
});

const ProgressBar = ({ classes, loading }) => (
  <div className={classes.linearProgress}>{loading && <LinearProgress />}</div>
);

const ProgressComp = withStyles(styles as any, { withTheme: true })(
  ProgressBar,
);

const mapStateToProps = (state) => ({
  loading: state.loading.isLoading,
});

export default withRouter(connect(mapStateToProps, () => ({}))(ProgressComp));
