export const isDesktop = !!(window && (window as any).isElectron);
export const isWeb = !!(
  process &&
  process.env &&
  process.env.PLATFORM === 'web'
);
export const isNative = !isDesktop && !isWeb;

const platform = (() => {
  if (isDesktop) {
    return 'desktop';
  }
  if (isWeb) {
    return 'web';
  }
  return 'native';
})();

export default platform;
