import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ArrowLeftIcon from '@material-ui/icons/ChevronLeft';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import SwitchField from '../SwitchField/SwitchField';

import FlatButton from '../Buttons/FlatButton';
import TextField from '../TextField/TextField';

const topPadding = 24;
const bottomPadding = 20;

const styles = () => ({
  container: {
    padding: `${topPadding}px 20px ${bottomPadding}px 24px`,
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  backButton: {
    minWidth: 32,
    minHeight: 32,
    height: 32,
    padding: 0,
    marginBottom: 48,
  },
  textFields: {
    marginBottom: -10,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

class AddNew extends Component<any> {
  static defaultProps: any;

  state = {
    textFields: Object.keys(this.props.textFields).reduce(
      (acc, key) => ({
        ...acc,
        [key]: this.props.textFields[key].defaultValue,
      }),
      {},
    ),
    switchFields: Object.keys(this.props.switchFields).reduce(
      (acc, key) => ({
        ...acc,
        [key]: this.props.switchFields[key].value,
      }),
      {},
    ),
    errors: {},
  };

  handleFieldChange = (value, field) => {
    this.setState({
      textFields: {
        ...this.state.textFields,
        [field]: value,
      },
    });
  };

  handleSwitchChange = (value, field) => {
    if (field === 'userRoles') {
      this.setState({
        switchFields: {
          ...this.state.switchFields,
          [field]: value ? ['general', 'admin'] : ['general'],
        },
      });
      return;
    }
    this.setState({
      switchFields: {
        ...this.state.switchFields,
        [field]: value,
      },
    });
  };

  setStateAsync = (state) =>
    new Promise<void>((resolve) => this.setState(state, resolve));

  validateAllFields = () => {
    const { textFields } = this.state;
    const errors = {};

    Object.keys(textFields).forEach((field) => {
      const error = this.props.validateFields(textFields[field], field);

      if (error) {
        errors[field] = error.message;
      }
    });

    return this.setStateAsync({ errors });
  };

  addNewAction = async () => {
    // trim all fields first before checking anything
    const { textFields } = this.state;
    const trimFields = {};

    Object.entries(textFields).forEach(([key, value]: [string, any]) => {
      if (value && value.trim) {
        trimFields[key] = value.trim();
      } else {
        trimFields[key] = value;
      }
    });

    this.setState(
      {
        textFields: trimFields,
      },
      async () => {
        // check field error
        await this.validateAllFields();
        if (Object.keys(this.state.errors).length > 0) {
          return;
        }

        // check if key field is unique
        let isUnique = true;
        if (this.props.uniqueness) {
          const { columns } = this.props.uniqueness;
          this.props.startLoading();
          isUnique = !columns.some((column) => this.state.errors[column]);
        }

        // start adding
        if (isUnique) {
          this.props.onAddNew([
            { ...this.state.textFields, ...this.state.switchFields },
          ]);
          this.props.onBack();
          return;
        }
        this.props.stopLoading();
      },
    );
  };

  render() {
    const { classes, onBack, textFields, switchFields, actionButton } =
      this.props;
    let isDisabled = false;
    forEach(textFields, (_, field) => {
      const fieldNotRequired = textFields[field].required === false;
      if (fieldNotRequired) {
        return;
      }

      if (!this.state.textFields[field]) {
        isDisabled = true;
      }
    });

    return (
      <div
        role="button"
        tabIndex={0}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            void this.addNewAction();
          }
        }}
        className={classes.container}
      >
        <div>
          <FlatButton
            className={classes.backButton}
            color="grey"
            onClick={onBack}
          >
            <ArrowLeftIcon />
          </FlatButton>
          <div role="form">
            {map(textFields, (data, field) => (
              <TextField
                autoFocus={['firstName', 'name'].indexOf(field) > -1}
                key={data.title}
                onChange={(e) => this.handleFieldChange(e.target.value, field)}
                helperText={data.helperText}
                className={classes.textFields}
                fullWidth
                id={data.id || null}
                label={data.title}
                placeholder={data.placeholder}
                defaultValue={data.defaultValue}
                error={this.state.errors[field]}
              />
            ))}
            {switchFields && (
              <div>
                {map(switchFields, (data, field) => (
                  <SwitchField
                    id={data.id || null}
                    key={field}
                    disabled={data.disabled}
                    title={data.title}
                    defaultChecked={data.defaultChecked}
                    onChange={this.handleSwitchChange}
                    name={data.name}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
        <div className={classes.buttonContainer} data-testid="add-new-button">
          <FlatButton
            onClick={this.addNewAction}
            disabled={isDisabled}
            color="green"
          >
            {actionButton.title}
          </FlatButton>
        </div>
      </div>
    );
  }
}

AddNew.defaultProps = {
  switchFields: {},
  textFields: {},
};

export default withStyles(styles as any, { withTheme: true })(AddNew);
