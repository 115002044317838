import React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Button, IconButton, TextField, icons } from '@ublend-npm/aulaui-next';
import { getUrlError } from '../../utils/getURLError';
import { UrlWithError } from './types';
import { styles } from './RedirectUris.styles';

interface RedirectUrisProps extends WithStyles<typeof styles> {
  redirectUris: UrlWithError[];
  showErrors: boolean;
  onChange: (redirectUris: UrlWithError[]) => void;
}

const RedirectUris = ({
  classes,
  redirectUris,
  showErrors,
  onChange,
}: RedirectUrisProps) => {
  const handleAddRedirectUri = () => {
    onChange([
      ...redirectUris,
      {
        value: '',
        error: getUrlError(''),
      },
    ]);
  };

  const handleUpdateRedirectUri =
    (updatedIdx) =>
    ({ target: { value } }) => {
      onChange(
        redirectUris.map((param, idx) =>
          idx === updatedIdx ? { value, error: getUrlError(value) } : param,
        ),
      );
    };

  const handleDeleteRedirectUri = (deletedIdx) => () => {
    onChange(redirectUris.filter((_, idx) => idx !== deletedIdx));
  };

  return (
    <div className={classes.redirectWrapper}>
      {redirectUris.map(({ value, error }, index) => (
        <div key={index} className={classes.wrapper}>
          <div className={classes.inputContainer}>
            <TextField
              label="Redirect URI"
              value={value}
              onChange={handleUpdateRedirectUri(index)}
              error={showErrors && !!error}
              helperText={showErrors ? error : undefined}
              data-testid="redirect-uri-field"
            />
          </div>
          {redirectUris.length > 1 ? (
            <div className={classes.deleteContainer}>
              <IconButton
                type="secondary"
                icon={icons.Delete}
                size="small"
                onClick={handleDeleteRedirectUri(index)}
                label="Delete redirect URI"
                aria-describedby="Delete redirect URI"
              />
            </div>
          ) : null}
        </div>
      ))}
      <div>
        <Button
          type="text"
          iconLeft={icons.AddIcon}
          onClick={handleAddRedirectUri}
        >
          Add redirect URI
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(RedirectUris);
