import { connect } from 'react-redux';
import AddUser from './AddUser';
import { search } from '../../../redux/actions/search';
import { createRoles } from '../../../redux/actions/roles';
import { getSearchData as getUsersSearchData } from '../../../selectors/users';
import { getSearchData as getSpacesSearchData } from '../../../selectors/spaces';

const mapStateToProps = (state) => ({
  usersSearchData: getUsersSearchData(state),
  spacesSearchData: getSpacesSearchData(state),
  isSearching: state.search.isSearching,
  addingUsers: state.addUsers.users,
  sso: state.institution.general.sso,
});

const mapDispatchToProps = (dispatch) => ({
  search: (term, searchFor) => dispatch(search(term, searchFor)),
  createRoles: (roles) => dispatch(createRoles(roles)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
