import CONSTS from '../../constants/actions';

const initialState = {
  isSidebarOpen: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONSTS.SIDEBAR_OPEN:
      return { isSidebarOpen: true };
    case CONSTS.SIDEBAR_CLOSE:
      return { isSidebarOpen: false };
    default:
      return state;
  }
};
