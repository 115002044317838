import React from 'react';
import pluralize from 'pluralize';

interface CreateUserConfirmationProps {
  title: string;
  uniqDataLength: number;
  parentClasses?: {
    bold?: string;
  };
}

const CreateUserConfirmation = ({
  title,
  parentClasses,
  uniqDataLength,
}: CreateUserConfirmationProps) => (
  <span>
    You are creating{' '}
    <span className={parentClasses.bold}>
      {pluralize(title, uniqDataLength, true)}
    </span>
  </span>
);

export default CreateUserConfirmation;
