import React from 'react';
import pluralize from 'pluralize';

interface DeleteUserSuccessProps {
  title: string;
  uniqDataLength: number;
  parentClasses?: {
    bold?: string;
  };
}

const DeleteUserSuccess = ({
  title,
  parentClasses,
  uniqDataLength,
}: DeleteUserSuccessProps) => (
  <span>
    You just deleted{' '}
    <span className={parentClasses.bold}>
      {pluralize(title, uniqDataLength, true)}
    </span>
  </span>
);

export default DeleteUserSuccess;
