import React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { TextField } from '@ublend-npm/aulaui-next';
import { getUrlError } from '../../utils/getURLError';
import { styles } from './Configuration1p1.styles';

export const SECRET_PLACEHOLDER = '..........';

export type Configuration1p1Args = Readonly<{
  publicUrl: string;
  key: string;
  sharedSecret: string;
  publicUrlError: string | null;
}>;

type Configuration1p1Props = WithStyles<typeof styles> &
  Readonly<{
    showErrors: boolean;
    isEditing: boolean;
    configuration: Configuration1p1Args;
    onChange: (configuration: Configuration1p1Args) => void;
  }>;

const Configuration1p1 = ({
  classes,
  showErrors,
  configuration,
  onChange,
  isEditing,
}: Configuration1p1Props) => {
  const { publicUrl, key, sharedSecret, publicUrlError } = configuration;

  const handleChangePublicUrl = (value) => {
    onChange({
      ...configuration,
      publicUrl: value,
      publicUrlError: getUrlError(value),
    });
  };

  const handleChangeConsumerKey = (value) => {
    onChange({
      ...configuration,
      key: value,
    });
  };

  const handleChangeSharedSecret = (value) => {
    onChange({
      ...configuration,
      sharedSecret: value,
    });
  };

  return (
    <div className={classes.providerDetails}>
      <TextField
        autoFocus
        label="URL"
        value={publicUrl}
        onChange={(e) => {
          handleChangePublicUrl(e.target.value);
        }}
        error={showErrors && !!publicUrlError}
        helperText={showErrors ? publicUrlError : undefined}
      />
      <TextField
        label="Consumer Key"
        value={key}
        onChange={(e) => handleChangeConsumerKey(e.target.value)}
        error={!key && showErrors}
        helperText={!key && showErrors ? 'Enter a consumer key.' : undefined}
      />
      <TextField
        label="Shared Secret"
        value={sharedSecret}
        onChange={(e) => handleChangeSharedSecret(e.target.value)}
        onFocus={() => {
          if (sharedSecret === SECRET_PLACEHOLDER) {
            handleChangeSharedSecret('');
          }
        }}
        onBlur={() => {
          if (sharedSecret === '' && isEditing) {
            handleChangeSharedSecret('');
          }
        }}
        error={!sharedSecret && showErrors}
        helperText={
          !sharedSecret && showErrors ? 'Enter a shared secret.' : undefined
        }
      />
    </div>
  );
};

export default withStyles(styles)(Configuration1p1);
