import React, { useState, useRef, useEffect } from 'react';
import StandardInput from '@material-ui/core/Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import MagnifierIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';

import SearchResults from './SearchResults';

interface SearchInputProps {
  classes: {
    inputContainer?: string;
    input?: string;
    inputButton?: string;
  };
  onSearch: (text: string | undefined) => void;
  onSelect: (..._args: unknown[]) => void;
  loading: boolean;
  results: {
    id?: string;
    title?: string;
    subtitle?: string;
    data?: {
      id?: string;
      title?: string;
      subtitle?: string;
    }[];
  }[];
  label: string;
  buttonPosition: 'end' | 'start';
  variant: string;
  clearSearchOnSelect?: boolean;
  name: string;
}

const SearchInput = ({
  classes,
  onSearch,
  onSelect,
  loading,
  results,
  label,
  buttonPosition,
  variant,
  clearSearchOnSelect,
  name,
}: SearchInputProps) => {
  const [showResults, setShowResults] = useState(false);

  const inputRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    if (!showResults) return undefined;

    const outsideClickListener = (event) => {
      if (!containerRef.current.contains(event.target)) {
        setShowResults(false);
      }
    };

    document.addEventListener('click', outsideClickListener);

    return () => document.removeEventListener('click', outsideClickListener);
  }, [showResults]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  }, [name]);

  const handleChange = () => {
    setShowResults(false);
  };

  const handleSearch = () => {
    onSearch(inputRef.current && inputRef.current.value);
    setShowResults(true);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSelect = (item) => () => {
    onSelect(item);
    setShowResults(false);
    if (clearSearchOnSelect && inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const SearchButton = () => (
    <InputAdornment position={buttonPosition}>
      <IconButton className={classes.inputButton} onClick={handleSearch}>
        {loading ? <CircularProgress size={24} /> : <MagnifierIcon />}
      </IconButton>
    </InputAdornment>
  );

  const Input = variant === 'outlined' ? OutlinedInput : StandardInput;

  return (
    <div className={classes.inputContainer} ref={containerRef}>
      <Input
        name={name}
        inputRef={inputRef}
        type="text"
        className={classes.input}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        placeholder={label}
        aria-label={label}
        startAdornment={buttonPosition === 'start' ? <SearchButton /> : null}
        endAdornment={buttonPosition === 'end' ? <SearchButton /> : null}
      />
      {!loading && showResults ? (
        <SearchResults results={results} onSelect={handleSelect} />
      ) : null}
    </div>
  );
};

SearchInput.defaultProps = {
  clearSearchOnSelect: false,
};

export default SearchInput;
