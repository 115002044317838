import React, { useState, useEffect } from 'react';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  switchLabel: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.common.darkGrey,
  },
});

interface SwitchFieldProps {
  id?: string;
  name: string;
  onChange: (currentCheckedState: unknown, name: unknown) => void;
  defaultChecked?: boolean;
  title: string;
  ariaLabel?: string;
  disabled?: boolean;
  classes: {
    switchLabel?: string;
    container?: string;
  };
}

const SwitchField = (props: SwitchFieldProps) => {
  const {
    classes,
    title,
    ariaLabel,
    disabled,
    defaultChecked,
    name,
    onChange,
    id,
  } = props;
  const [checked, setChecked] = useState(defaultChecked);

  const onChangeEventHandler = (event) => {
    const currentCheckedState = event.target.checked;
    onChange(currentCheckedState, name);
    setChecked(currentCheckedState);
  };

  useEffect(() => {
    setChecked(defaultChecked);
  }, [defaultChecked]);

  const { container, switchLabel } = classes;
  return (
    <div className={container}>
      <label htmlFor={id} className={switchLabel}>
        {title}
      </label>
      <Switch
        color="primary"
        inputProps={
          {
            id,
            'aria-label': ariaLabel || title,
          } as any
        }
        disabled={disabled}
        checked={checked}
        onChange={onChangeEventHandler}
      />
    </div>
  );
};

SwitchField.defaultProps = {
  disabled: false,
};

SwitchField.defaultProps = {
  id: undefined,
  defaultChecked: false,
  ariaLabel: undefined,
};

export default withStyles(styles as any, { withTheme: true })(SwitchField);
