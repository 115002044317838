import { request } from '../../utils/api';
import { CREATE_ROLES, DELETE_ROLES } from '../../constants/endpoints';

const errorFromResponse = (response) => {
  const error = new Error(
    response.errors
      .map(
        (e) =>
          `"${e.message}" for user "${e.externalId.externalUserId}" and space "${e.externalId.externalSpaceId}"`,
      )
      .join('\n'),
  );
  (error as any).metadata = response.errors;
  return error;
};

export const createRoles = (roles) => async (dispatch) => {
  try {
    const response = await request({
      route: CREATE_ROLES,
      data: { roles },
      method: 'post',
      dispatch,
    });

    if (response.errors.length) {
      throw errorFromResponse(response);
    }

    return response;
  } catch (e) {
    throw Error(e.message);
  }
};

export const deleteRoles = (roles) => async (dispatch) => {
  try {
    const response = await request({
      route: DELETE_ROLES,
      data: { roles },
      method: 'post',
      dispatch,
    });

    if (response.errors.length) {
      throw errorFromResponse(response);
    }

    return response;
  } catch (e) {
    throw Error(e.message);
  }
};
