import React from 'react';
import { SelectInput } from '@ublend-npm/aulaui-next';
import { LtiVersion, Lti1P3Provider } from '@ublend-npm/aula-schema';
import Configuration1p3, { Configuration1p3Args } from '../../Configuration1p3';
import IntegrationIdentification from '../../IntegrationIdentification.container';
import RedirectUris from '../../RedirectUris';
import { SubHeading, DialogHorizontalRuler } from '../DialogSteps.styles';
import { UrlWithError } from '../../types';
import { LtiVersionOptions } from '../../../../constants/ltiConstants';

type Lti1p3ConfigurationProps = {
  configuration: Configuration1p3Args;
  onConfigurationChange: (configuration1p3: Configuration1p3Args) => void;
  showErrors: boolean;
  ltiVersion: LtiVersion;
  onLtiVersionChange: (ltiVersion: LtiVersion) => void;
  redirectUris: UrlWithError[];
  onRedirectUrisChange: (redirectUris: UrlWithError[]) => void;
  isEditing: boolean;
  providerToBeEdited: Lti1P3Provider;
};

const Lti1p3Configuration = ({
  configuration,
  onConfigurationChange,
  showErrors,
  ltiVersion,
  onLtiVersionChange,
  redirectUris,
  onRedirectUrisChange,
  isEditing,
  providerToBeEdited,
}: Lti1p3ConfigurationProps) => {
  return (
    <>
      {isEditing ? (
        <>
          <SubHeading>Integration identification</SubHeading>
          <IntegrationIdentification
            iss={providerToBeEdited.iss}
            clientId={providerToBeEdited.clientId}
            deploymentId={providerToBeEdited.deploymentId}
          />
          <DialogHorizontalRuler />
        </>
      ) : null}
      <SubHeading>Configuration</SubHeading>
      <SelectInput
        name="LtiVersionInput"
        label="LTI version"
        data-testid="lti-version-select"
        data={LtiVersionOptions}
        value={ltiVersion}
        disabled={isEditing}
        handleChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          onLtiVersionChange(e.target.value as LtiVersion);
        }}
      />
      <Configuration1p3
        showErrors={showErrors}
        configuration={configuration}
        onChange={onConfigurationChange}
      />
      <RedirectUris
        redirectUris={redirectUris}
        showErrors={showErrors}
        onChange={onRedirectUrisChange}
      />
    </>
  );
};

export default Lti1p3Configuration;
