import { createStyles } from '@material-ui/core/styles';
import { theme as aulaTheme } from '@ublend-npm/aulaui-next';

export const styles = createStyles({
  dialogTitle: {
    paddingTop: 20,
  },
  cancelButton: {
    marginRight: 8,
  },
  removeButton: {
    backgroundColor: `${aulaTheme.color.error} !important`,
  },
  dialogContent: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  formControlLabel: {
    paddingTop: 12,
  },
  label: {
    fontSize: aulaTheme.font.size.copy,
    fontWeight: aulaTheme.font.weight.regular,
  },
  dialogActions: {
    paddingBottom: 22,
    paddingRight: 24,
  },
});
