import React from 'react';
import { Alert } from '@ublend-npm/aulaui-next';
import { LtiPlacement } from '@ublend-npm/aula-schema';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Parameters from '../Parameters';
import { assignmentParameterWhitelist } from '../customParametersWhitelist';
import { FormParameters, Parameter } from '../types';
import {
  DialogContent,
  SubHeading,
  DialogSpacing,
  DialogHorizontalRuler,
  ParametersTitle,
  ParametersSubtitle,
  Caption,
  RadioLabel,
  RadioDescription,
  RadioError,
} from './DialogSteps.styles';

type PlacementStepProps = {
  allParameters: FormParameters;
  onAllParametersChange: (parameters: FormParameters) => void;
  placement: LtiPlacement;
  onPlacementChange: (placement: LtiPlacement) => void;
  assignmentParameters: Parameter[];
  showErrors: boolean;
  isEditing: boolean;
};

const PlacementStep = ({
  allParameters,
  onAllParametersChange,
  placement,
  onPlacementChange,
  assignmentParameters,
  showErrors,
  isEditing,
}: PlacementStepProps) => {
  const assignmentParametersDisplayCount =
    assignmentParameters.length > 0 ? `(${assignmentParameters.length}) ` : '';

  return (
    <DialogContent>
      <SubHeading>Integration availability in Aula</SubHeading>
      <Caption>
        Select what this integration will be used for to determine where it will
        become available in Aula
      </Caption>
      <DialogSpacing>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="Availability"
            value={placement}
            onChange={(e) => onPlacementChange(e.target.value as LtiPlacement)}
          >
            <FormControlLabel
              value={LtiPlacement.MATERIALS}
              control={<Radio color="primary" />}
              label={<RadioLabel>Materials</RadioLabel>}
              disabled={isEditing}
            />
            <RadioDescription>
              The integration will be available for creating materials and feed
              posts
            </RadioDescription>
            <FormControlLabel
              value={LtiPlacement.ASSIGNMENTS}
              control={<Radio color="primary" />}
              label={<RadioLabel>Assignments</RadioLabel>}
              disabled={isEditing}
            />
            <RadioDescription>
              The integration will be available for creating assignments
            </RadioDescription>
          </RadioGroup>
        </FormControl>
        {!isEditing ? (
          <RadioError>
            {showErrors && !placement ? 'Please select an option' : ''}
          </RadioError>
        ) : null}
      </DialogSpacing>
      {isEditing ? (
        <DialogSpacing>
          <Alert type="info">
            These options cannot be amended once an integration has been
            configured
          </Alert>
        </DialogSpacing>
      ) : null}
      <DialogHorizontalRuler />
      {placement === LtiPlacement.ASSIGNMENTS ? (
        <div>
          <ParametersTitle>
            Custom assignment parameters {assignmentParametersDisplayCount}
          </ParametersTitle>
          <ParametersSubtitle>
            Refer to the tool’s documentation to see which parameters are
            required and map them to Aula’s fields
          </ParametersSubtitle>
          <Parameters
            allParameters={allParameters}
            selectedCategory="assignmentParameters"
            showErrors={showErrors}
            onChange={onAllParametersChange}
            whitelistedParameters={assignmentParameterWhitelist}
            title="Add assignment parameter"
          />
        </div>
      ) : null}
    </DialogContent>
  );
};

export default PlacementStep;
