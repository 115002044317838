import React from 'react';
import {
  CreateLtiProviderRequestBody,
  UpdateLtiProviderRequestBody,
} from '@ublend-npm/aula-schema';
import { request } from '../../utils/api';
import {
  FETCH_INTEGRATIONS,
  DELETE_INTEGRATION,
  ADD_INTEGRATION,
  FETCH_INTEGRATION_BY_ID,
  EDIT_INTEGRATION,
} from '../../constants/endpoints';
import { showToast } from './toasts';

// actions
export const fetchIntegrations = () => async (dispatch) => {
  try {
    const { providers } = await request({
      route: FETCH_INTEGRATIONS,
      dispatch,
    });
    return providers;
  } catch (error) {
    // TODO: Show toast?
    return false;
  }
};

export const fetchIntegrationById = (id: string) => async (dispatch) => {
  try {
    const provider = await request({
      route: FETCH_INTEGRATION_BY_ID(id),
      method: 'get',
      dispatch,
    });
    return provider;
  } catch (error) {
    return false;
  }
};

export const deleteIntegration =
  (id: string, description: string) => async (dispatch) => {
    await request({
      route: DELETE_INTEGRATION(id),
      method: 'delete',
      dispatch,
    });
    dispatch(
      showToast({
        message: (
          <>
            <b>{description}</b> was removed.
          </>
        ),
      }),
    );
  };

export const addIntegration =
  (provider: CreateLtiProviderRequestBody) => async (dispatch) => {
    const response = await request({
      route: ADD_INTEGRATION,
      data: { ...provider },
      method: 'post',
      dispatch,
    });

    dispatch(
      showToast({
        message: (
          <>
            <b>{provider.description}</b> integration added. It is now available
            to use in Aula.
          </>
        ),
      }),
    );

    return response;
  };

export const editIntegration =
  (id: string, provider: UpdateLtiProviderRequestBody) => async (dispatch) => {
    const response = await request({
      route: EDIT_INTEGRATION(id),
      data: provider,
      method: 'patch',
      dispatch,
    });

    dispatch(
      showToast({
        message: (
          <>
            <b>{provider.description}</b> integration edited.
          </>
        ),
      }),
    );

    return response;
  };
