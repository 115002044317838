/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@ublend-npm/aulaui-next';
import chunk from 'lodash/chunk';
import Button from '@material-ui/core/Button';
import Table from '../../shared/Table/Table';
import BasicInfoForm from '../../shared/BasicInfoForm/BasicInfoForm';
import MenuItems from './MenuItems';

const styles = () => ({
  loaderContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    height: 'calc(100% - 60px)',
    paddingTop: 60,
  },
  table: {
    paddingBottom: 60,
  },
  basicInfoForm: {
    padding: '0 16px',
  },
  formContainer: {
    flexWrap: 'wrap',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
});

const BtnTitle = (text, history, { name, externalId }) => (
  <Button
    color="primary"
    onClick={() =>
      history.push({
        search: `?addUser=true&spaceName=${name}&spaceId=${externalId}`,
      })
    }
    style={{ fontSize: '16px', marginLeft: '-8px' }}
  >
    {text}
  </Button>
);

const CHUNK_SIZE = 50;

const formatParticipants = (participants) =>
  participants.map((participant) => ({
    ...participant,
    custom: JSON.stringify(participant.custom || {}, null, 2),
  }));

class Space extends Component<any> {
  state = {
    users: [],
    textFields: {
      name: '',
      id: '',
      externalId: '',
      code: '',
      startDate: '',
      endDate: '',
      status: '',
      faculty: '',
      school: '',
      open: false,
      openSpaceCode: undefined,
    },
    participantCount: null,
    removeUser: false,
    selected: [],
    confirmSave: false,
    confirmedSaveSpace: null,
    isSaving: false,
    avatar: undefined,
    changeRoleMenu: undefined,
    isRemoving: undefined,
    loading: undefined,
    readOnly: undefined,
  };

  componentWillMount = async () => {
    // this.props.match.params.spaceId
    // fetch user information
    void this.fetchSpace(this.props.match.params.spaceId);
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.match.params.spaceId !== this.props.match.params.spaceId) {
      void this.fetchSpace(nextProps.match.params.spaceId);
    }
  };

  fetchSpace = async (id) => {
    this.setState({ loading: true });
    const space = await this.props.fetchSpace(id);
    if (JSON.stringify(space) === '{}') {
      this.props.history.push('/');
      return;
    }
    this.setState({
      loading: false,
      textFields: {
        name: space.name,
        id: space.id,
        externalId: space.externalId,
        code: space.code || '',
        startDate: space.startDate || null,
        endDate: space.endDate || null,
        status: space.archived ? 'archived' : 'live',
        faculty: space.faculty || '',
        school: space.school || '',
        open: space.open || false,
        openSpaceCode: space.openSpaceCode ? space.openSpaceCode.code : '',
      },
      participantCount: space.participantCount,
      users: formatParticipants(space.users),
      avatar: space.avatar,
    });
  };

  preSaveCheck = (data) => {
    const { name, status, startDate, endDate } = data;
    const startDateChanged = startDate !== this.state.textFields.startDate;
    const endDateChanged = endDate !== this.state.textFields.endDate;
    const oneDateFieldEmpty =
      (!startDate && endDate) || (startDate && !endDate);

    const errors: {
      name?: string;
      status?: string;
      startDate?: string;
      endDate?: string;
    } = {};

    if (!name || name.trim() === '') {
      errors.name = 'Incorrect name';
    }

    if (oneDateFieldEmpty && (startDateChanged || endDateChanged)) {
      if (!startDate) errors.startDate = 'Start date required';
      if (!endDate) errors.endDate = 'End date required';
    }

    if (!status || status.trim() === '') {
      errors.status = 'Incorrect status';
    }

    if (Object.keys(errors).length > 0) {
      return errors;
    }

    return null;
  };

  handleFetchMorePages = async () => {
    const participants = await this.props.fetchParticipants(
      this.props.match.params.spaceId,
      this.state.users.length,
    );
    this.setState({
      users: [...this.state.users, ...formatParticipants(participants)],
    });
  };

  handlePreSave = async (updatedSpace) => {
    if (updatedSpace.externalId !== this.state.textFields.externalId) {
      this.setState({ confirmSave: true, confirmedSaveSpace: updatedSpace });
      return;
    }

    await this.handleSave(updatedSpace);
  };

  handleSave = async (updatedSpace) => {
    try {
      this.setState({ isSaving: true });

      const updated = await this.props.updateSpace({
        id: this.props.match.params.spaceId,
        name: updatedSpace.name,
        archived: updatedSpace.status === 'archived',
        externalId: updatedSpace.externalId,
        open: updatedSpace.open,
        startDate: updatedSpace.startDate,
        endDate: updatedSpace.endDate,
        ...('avatar' in updatedSpace ? { avatar: updatedSpace.avatar } : {}),
      });

      this.setState({
        textFields: {
          name: updated.name,
          id: updatedSpace.id,
          externalId: updatedSpace.externalId,
          status: updated.archived ? 'archived' : 'live',
          open: updated.open,
          startDate: updated.startDate,
          endDate: updated.endDate,
          openSpaceCode: updated.openSpaceCode
            ? updated.openSpaceCode.code
            : '',
        },
        confirmSave: false,
        isSaving: false,
        avatar: 'avatar' in updated ? updated.avatar : null,
      });
    } catch (_) {
      const { textFields, avatar } = this.state;
      this.setState({
        avatar,
        textFields,
        confirmSave: false,
        isSaving: false,
      });
    }
  };

  handleBulkRoleChange = (role) => {
    const { selected } = this.state;
    void this.onRoleChange(role, selected);
    (this as any).closeMenu();
  };

  onRoleChange = async (role, externalUserIds) => {
    const roles = externalUserIds.map((id) => ({
      externalUserId: id,
      externalSpaceId: this.state.textFields.externalId,
      role,
    }));

    await this.props.updateUserRole(roles);

    this.setState({
      users: this.state.users.map((user) => {
        if (externalUserIds.includes(user.externalId)) {
          return {
            ...user,
            role,
          };
        }
        return user;
      }),
    });
  };

  removeUserModal = (open) => {
    this.setState({ removeUser: open });
  };

  removeUserFromState = (ids) => {
    this.setState({
      users: this.state.users.filter((u) => ids.indexOf(u.externalId) < 0),
    });
  };

  removeUser = async () => {
    this.setState({ isRemoving: true });
    const { selected } = this.state;
    const dataToDelete = selected.map((externalUserId) => ({
      externalUserId,
      externalSpaceId: this.state.textFields.externalId,
    }));
    const chunks = chunk(dataToDelete, CHUNK_SIZE);
    for (const roleChunk of chunks) {
      try {
        await this.props.deleteRoles(roleChunk);
        this.removeUserFromState(selected);
      } catch (e) {
        // pick out only initial error message as all errors are joined together
        const message =
          `${e}`.split('\n')[0].split('"')[1] ||
          'Failed to remove user(s) from space.';
        this.props.showToast({
          message,
        });
      }
    }
    this.setState({ isRemoving: false });
    this.removeUserModal(false);
  };

  handleAddToAnotherSpace = () => {
    const { users } = this.state;
    const { selected } = this.state;
    this.props.addUsersToAnotherSpace(
      users.filter((u) => selected.includes(u.externalId)),
    );
    this.props.history.push({
      search: '?addUser=true',
    });
    (this as any).closeMenu();
  };

  handleCloseMenu = (close) => {
    (this as any).closeMenu = close;
  };

  handleSelect = (selected) => {
    this.setState({ selected });
  };

  changeRoleMenuClick = (e) => {
    if (this.state.changeRoleMenu) {
      this.setState({ changeRoleMenu: null });
    } else {
      this.setState({ changeRoleMenu: e.currentTarget });
    }
  };

  changeRoleMenuClose = () => this.setState({ changeRoleMenu: null });

  render() {
    const { classes, history, institution, showOpenSpaceFeatureFlag } =
      this.props;
    const { selected } = this.state;
    const {
      name,
      id,
      externalId,
      code,
      status,
      startDate,
      endDate,
      faculty,
      school,
      open,
      openSpaceCode,
    } = this.state.textFields;
    if (this.state.loading) {
      return (
        <div className={classes.loaderContainer}>
          <CircularProgress />
        </div>
      );
    }

    const multipleUsers = selected.length > 1;

    return (
      <div className={classes.container}>
        <div className={classes.basicInfoForm} data-testid="basic-info-form">
          <BasicInfoForm
            classes={{
              container: classes.formContainer,
            }}
            fields={[
              {
                label: 'Space name',
                value: name,
                placeholder: 'Graphic Design',
                type: 'name',
                id: 'name',
                styles: {
                  width: '240px',
                },
              },
              {
                label: 'Space Id',
                value: id,
                placeholder: '12345678',
                type: 'name',
                id: 'id',
                disabled: true,
              },
              {
                label: 'External Id',
                value: externalId,
                placeholder: '12345678',
                type: 'name',
                id: 'externalId',
              },
              {
                label: 'Space code',
                value: code,
                type: 'name',
                id: 'code',
                disabled: true,
              },
              {
                type: 'break',
                id: 'breakBeforeStartDate',
              },
              {
                label: 'Start date',
                value: startDate,
                type: 'dateInput',
                id: 'startDate',
                getInputProps: (fields: { endDate?: string }) => ({
                  maxDate: fields.endDate,
                }),
              },
              {
                label: 'End date',
                value: endDate,
                type: 'dateInput',
                id: 'endDate',
                getInputProps: (fields: { startDate?: string }) => ({
                  minDate: fields.startDate,
                }),
              },
              {
                label: 'Status',
                value: status,
                type: 'radio',
                id: 'status',
              },
              {
                label: 'Faculty',
                value: faculty,
                type: 'name',
                id: 'faculty',
                disabled: true,
              },
              {
                label: 'School',
                value: school,
                type: 'name',
                id: 'school',
                disabled: true,
              },
              ...(showOpenSpaceFeatureFlag
                ? [
                    {
                      type: 'break',
                      id: 'breakBeforeOpenSpace',
                    },
                    {
                      label: 'Open Space',
                      value: open,
                      type: 'switch',
                      id: 'open',
                    },
                    {
                      label: 'Open space code',
                      value: open
                        ? openSpaceCode
                        : 'Code will appear here once space is opened',
                      type: 'name',
                      id: 'openSpaceCode',
                      styles: {
                        width: '320px',
                      },
                      copyButton: {
                        show: open,
                        baseText: `https://signup.${institution}.aula.education/?spaceCode=`,
                      },
                      disabled: true,
                    },
                  ]
                : []),
            ]}
            avatarName={name}
            avatarUrl={this.state.avatar}
            preSaveCheck={this.preSaveCheck}
            onSave={this.handlePreSave}
          />
        </div>
        <div className={classes.table}>
          <Paper elevation={5}>
            <Table
              title={
                <div className={classes.buttonContainer}>
                  {BtnTitle('ADD USERS', history, { name, externalId })}
                  <a
                    href={`https://${institution}.aula.education/#/dashboard/${id}/feed`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    OPEN IN AULA
                  </a>
                </div>
              }
              data={this.state.users}
              dataCount={this.state.participantCount || this.state.users.length}
              columns={[
                'firstName',
                'lastName',
                'email',
                'externalId',
                'role',
                'custom',
              ]}
              onRoleChange={(role, userId) => this.onRoleChange(role, [userId])}
              onSelect={this.handleSelect}
              closeMenu={this.handleCloseMenu}
              onMenuClose={this.changeRoleMenuClose}
              readOnly={this.state.readOnly}
              reachedLastPage={this.handleFetchMorePages}
              menuItems={
                <MenuItems
                  removeUserModal={this.removeUserModal}
                  handleAddToAnotherSpace={this.handleAddToAnotherSpace}
                  changeRoleMenuClick={this.changeRoleMenuClick}
                  changeRoleMenuClose={this.changeRoleMenuClose}
                  changeRoleMenu={this.state.changeRoleMenu}
                  handleChangeRole={this.handleBulkRoleChange}
                  closeMenu={(this as any).closeMenu}
                />
              }
            />
          </Paper>
        </div>
        <Dialog
          id="remove-user-dialog"
          open={this.state.removeUser}
          width="600"
          height="190"
          onClose={() => this.removeUserModal(false)}
        >
          <DialogTitle closeIcon={false}>
            {`Remove ${multipleUsers ? 'users' : 'user'} from space?`}
          </DialogTitle>
          <DialogContent>
            {`The ${
              multipleUsers ? 'users' : 'user'
            } will not be able to access this space anymore.`}
          </DialogContent>
          <DialogActions
            primary={{
              label: 'Remove!',
              onClick: () => this.removeUser(),
              loading: this.state.isRemoving,
            }}
            secondary={{
              label: 'Cancel',
              onClick: () => this.removeUserModal(false),
            }}
          />
        </Dialog>
        <Dialog
          id="space-external-id-edit-dialog"
          open={this.state.confirmSave}
          width="600"
          height="190"
          onClose={() => this.setState({ confirmSave: false })}
        >
          <DialogTitle closeIcon={false}>
            You are about to edit the external ID of this space.
          </DialogTitle>
          <DialogContent>Are you sure?</DialogContent>
          <DialogActions
            primary={{
              label: 'Yes!',
              onClick: () => this.handleSave(this.state.confirmedSaveSpace),
              loading: this.state.isSaving,
            }}
            secondary={{
              label: 'Forget it',
              onClick: () =>
                this.setState({ confirmSave: false, confirmedSaveSpace: null }),
            }}
          />
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles as any, { withTheme: true })(Space);
