import React from 'react';
import { WithStyles, withStyles, createStyles } from '@material-ui/core/styles';
import { SelectInput } from '@ublend-npm/aulaui-next';

export const styles = createStyles({
  formControl: {
    width: 'auto',
  },
});

export enum FilterType {
  ALL = 'all',
  CUSTOM = 'custom',
  AULA = 'aula',
}

type FilterProps = WithStyles<typeof styles> &
  Readonly<{
    value: FilterType;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  }>;

const Filter = ({ classes, value, onChange }: FilterProps) => {
  const data = [
    {
      key: FilterType.ALL,
      value: 'All integrations',
    },
    {
      key: FilterType.CUSTOM,
      value: 'Custom integrations',
    },
    {
      key: FilterType.AULA,
      value: 'Aula integrations',
    },
  ];

  return (
    <SelectInput
      classes={{ formControl: classes.formControl }}
      label="Integrations"
      name="Integrations"
      data={data}
      value={value}
      handleChange={onChange}
      data-testid="integration-filter"
    />
  );
};

export default withStyles(styles)(Filter);
