import { keys, first } from 'lodash';

const transformSearchResults = (data) => {
  if (!data) return [];
  const results = data.map((item) => {
    if (item.firstName) {
      return {
        title: `${item.firstName} ${item.lastName}`,
        subtitle: item.externalId,
        id: item.id,
        email: item.email,
      };
    }
    return {
      title: item.name,
      subtitle: item.externalId,
      id: item.id,
      email: item.email,
    };
  });
  return results;
};

const fillResults = (results) => {
  const newResults = [...results];
  if (!results.find((i) => i.title === 'spaces')) {
    newResults.push({
      title: 'spaces',
      data: [],
    });
  }
  if (!results.find((i) => i.title === 'users')) {
    newResults.push({
      title: 'users',
      data: [],
    });
  }

  return newResults;
};

export const transformElasticSearchObjects = (esData) => {
  const result = [];
  esData.forEach((i) => {
    const key = first(keys(i));
    result.push({
      title: key,
      data: transformSearchResults(i[key]),
    });
  });
  return fillResults(result);
};

export default {};
