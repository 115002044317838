// format ISO date to yyyy-mm-dd
const formatISODate = (date) => {
  const INVALID_DATE = 'No Date';

  if (!date) {
    return INVALID_DATE;
  }

  const dateValue = date.split('T');
  if (dateValue.length !== 2) {
    return INVALID_DATE;
  }

  return dateValue[0];
};

export const DATE_FIELD_ERROR = {
  invalidFormat: 'Incorrect format: the correct is YYYY-MM-DD',
  invalidDate: 'Date is invalid',
};

/**
 *
 * @param {string} date
 * @returns {{ error?: string, parsedDate?: Date }}
 *
 * parseDate accepts a string and checks to see if it matches the
 * expected `YYYY-MM-DD` format. If it does, then it will return
 * the `parsedDate`. If it doesn't, it will return `error`.
 */
export const parseDate = (date: string) => {
  if (typeof date !== 'string') {
    return { error: DATE_FIELD_ERROR.invalidFormat };
  }

  const trimmedDate = date.trim();
  const pattern = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/;
  if (!pattern.test(trimmedDate)) {
    return { error: DATE_FIELD_ERROR.invalidFormat };
  }

  const parsedDate = new Date(trimmedDate);
  if (parsedDate.toString() === 'Invalid Date') {
    return { error: `${DATE_FIELD_ERROR.invalidDate}: ${trimmedDate}` };
  }

  return { parsedDate };
};

export default formatISODate;
