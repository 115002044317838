import React from 'react';
import DeleteParameterButton from './DeleteParameterButton';
import { TextField, Button, icons } from '@ublend-npm/aulaui-next';
import {
  OptionsContainer,
  OptionsHeading,
  OptionRow,
} from './CustomOptions.styles';
import { CustomOption, ParameterRenderType } from '../../types';

type CustomOptionsProps = {
  options: CustomOption[];
  idx: number;
  showErrors?: boolean;
  renderType: ParameterRenderType;
  onAddOption: (parentKey: number) => void;
  onDeleteOption: (parentKey: number, deletedIdx: number) => void;
  onChangeOption: (option: CustomOption, updatedIdx: number) => void;
};

const getError =
  (field: string, options: CustomOption[], emptyCheckSkip = false) =>
  (newValue: string) => {
    if (!newValue && !emptyCheckSkip) {
      return `Enter a ${field}.`;
    }

    const alreadyExists = options.some((option) => option[field] === newValue);

    if (alreadyExists) {
      return `Duplicate ${field} name.`;
    }

    return null;
  };

const CustomOptions = ({
  options,
  idx,
  renderType,
  showErrors = false,
  onAddOption,
  onDeleteOption,
  onChangeOption,
}: CustomOptionsProps) => {
  const { AddIcon } = icons;

  const getLabelError = getError('label', options);
  const getValueError = getError('value', options, true);

  return (
    <OptionsContainer data-testId={`option-container-${idx}`}>
      <OptionsHeading>
        Add {renderType} items to populate the {renderType} below
      </OptionsHeading>
      {options.map(({ label, value, labelError, valueError }, index) => (
        <OptionRow
          key={index}
          data-testId={`option-container-${idx}-row-${index}`}
        >
          <TextField
            autoFocus={!label}
            label="Option label"
            value={label}
            onChange={(e) =>
              onChangeOption(
                {
                  label: e.target.value,
                  labelError: getLabelError(e.target.value),
                  value,
                  valueError,
                },
                index,
              )
            }
            error={showErrors && !!labelError}
            helperText={showErrors ? labelError : undefined}
          />
          <TextField
            label="Value"
            value={value}
            onChange={(e) =>
              onChangeOption(
                {
                  label,
                  labelError,
                  value: e.target.value,
                  valueError: getValueError(e.target.value),
                },
                index,
              )
            }
            error={showErrors && !!valueError}
            helperText={showErrors ? valueError : undefined}
          />
          <DeleteParameterButton
            handleClick={() => onDeleteOption(idx, index)}
          />
        </OptionRow>
      ))}
      <Button type="text" iconLeft={AddIcon} onClick={() => onAddOption(idx)}>
        Add {renderType} item
      </Button>
    </OptionsContainer>
  );
};

export default CustomOptions;
