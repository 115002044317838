import axios from 'axios';
import { getStore } from '../redux/store';
import CONSTS from '../constants/actions';
import { buildInstitutionURL } from './institutionUrl';

const getBaseHeaders = (): {
  'x-user-id'?: string;
} => {
  const user = { ...getStore().getState().user.user };

  return {
    'x-user-id': user.id,
  };
};

export const request = async ({
  route,
  method = 'get',
  data = {},
  dispatch,
  showLoader = true,
  headers = getBaseHeaders(),
}) => {
  if (showLoader) {
    dispatch({
      type: CONSTS.LOADING,
      isLoading: true,
    });
  }
  const res = await axios({
    method,
    url: buildInstitutionURL({ path: route }),
    data,
    headers,
    withCredentials: true,
  });
  dispatch({
    type: CONSTS.LOADING,
    isLoading: false,
  });
  return res.data;
};

export const getUserBySessionToken = async () => {
  const { user } = await request({
    route: 'users/getBySessionToken',
    dispatch: () => {},
    headers: {},
  });
  return user;
};
