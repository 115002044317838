import isHttpsUrl from './isHttpsUrl';

export const emptyUrlDefaultText = 'Enter a URL.';
export const invalidUrlHTTPSText = 'Enter a secure HTTPS URL.';

export const getUrlError = (value) => {
  if (!value) {
    return emptyUrlDefaultText;
  }

  if (!isHttpsUrl(value)) {
    return invalidUrlHTTPSText;
  }

  return null;
};
