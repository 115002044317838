/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import curry from 'lodash/curryRight';
import ModalBox from '../../shared/ModalBox/ModalBox';
import removeUserImage from './RemoveUsersImage.png';
import createSpaceImage from '../CreateSpace/createSpaceImage.png';
import RemoveUserConfirmation from './RemoveUserConfirmation';
import RemoveUserSuccess from './RemoveUserSuccess';
import chunkProcess from '../../../utils/chunkProcess';

const CHUNK_SIZE = 50;

const MODAL_PROPS = (
  props: any = {
    usersSearchData: [],
    search: null,
  },
  onSelect?,
) => {
  const curriedSearch = props.search ? curry(props.search) : (_) => {};
  return {
    users: {
      title: 'user',
      modalSubtitle: 'Remove users from spaces',
      uniqueness: {
        columns: ['externalId'],
      },
      csvLeftPanelAction: {
        tabTitle: 'import a CSV of users',
        actionButton: {
          title: 'Import',
        },
      },
      columns: ['email', 'externalId'],
      table: {
        image: removeUserImage,
      },
      search: {
        data: props.usersSearchData,
        extractSelected: (item) => ({
          externalId: item.subtitle,
          email: item.email,
        }),
        search: curriedSearch(['users']),
        isSearching: props.isSearching,
      },
      rightPanelConfig: {
        getRightPanelTitle: (entities) => `Removing ${entities}`,
        getPlaceholderTitle: () => 'Start by selecting users.',
      },
    },
    spaces: {
      title: 'space',
      modalSubtitle: 'Remove from spaces',
      rightPanelConfig: {
        getRightPanelTitle: (entityName) => `Removing from ${entityName}`,
        getPlaceholderTitle: () =>
          'Select the spaces from where to remove the users',
      },
      csvLeftPanelAction: {
        tabTitle: 'import a CSV of spaces',
        actionButton: {
          title: 'Add spaces',
        },
      },
      columns: ['externalId'],
      table: {
        image: createSpaceImage,
        onSelect: (selected) => onSelect(selected, 'space'),
      },
      uniqueness: {
        columns: ['externalId'],
      },
      search: {
        search: curriedSearch(['spaces']),
        isSearching: props.isSearching,
        data: props.spacesSearchData,
        extractSelected: (item) => ({
          name: item.title,
          externalId: item.subtitle,
        }),
      },
    },
  };
};

const confirmationSectionConfig = {
  getConfirmationMessage: (data) => <RemoveUserConfirmation {...data} />,
  getSuccessMessage: (data) => <RemoveUserSuccess {...data} />,
};

interface RemoveUsersFromSpacesModalProps extends RouteComponentProps {
  search?: (term: unknown, searchFor?: unknown) => void;
  deleteUsers?: (users: unknown[]) => void;
  deleteRoles: (roles: unknown[]) => void;
  usersSearchData?: unknown[];
  spacesSearchData?: unknown[];
  isSearching?: boolean;
}

class RemoveUsersFromSpacesModal extends Component<RemoveUsersFromSpacesModalProps> {
  static defaultProps: Partial<RemoveUsersFromSpacesModalProps>;

  state = {
    step: 'users',
    modalProps: MODAL_PROPS().users,
    loading: false,
    confirmModalProps: null,
    leftToProcess: null,
    role: 'student',
    selected: {
      user: [],
    },
    errors: undefined,
    totalToProcess: undefined,
  };

  componentWillMount = () => {
    this.setState({
      modalProps: MODAL_PROPS(this.props)[this.state.step],
    });
  };

  static getDerivedStateFromProps = (nextProps, prevState) => ({
    ...prevState,
    modalProps: MODAL_PROPS(nextProps)[prevState.step],
  });

  onSave = async (data) => {
    if (!data.user.length || !data.space.length) {
      return;
    }

    const dataToProcess = data.space
      .map((space) =>
        data.user.map((user) => ({
          externalSpaceId: space.externalId,
          externalUserId: user.externalId,
        })),
      )
      .flat();

    await chunkProcess({
      data: dataToProcess,
      chunkSize: CHUNK_SIZE,
      onRequest: this.props.deleteRoles,
      onProgress: (newData) => this.setState(newData),
      onError: (error) =>
        this.setState({
          errors: [...(this.state.errors || []), error],
        }),
    });
  };

  nextStep = () => {
    if (this.state.step === 'users') {
      this.setState({
        step: 'spaces',
        modalProps: MODAL_PROPS(this.props, () => undefined).spaces,
      });
    }
  };

  prevStep = () => {
    this.setState({
      step: 'users',
      modalProps: MODAL_PROPS(this.props, () => undefined).users,
    });
  };

  render() {
    const {
      role,
      step,
      errors,
      selected,
      modalProps,
      leftToProcess,
      totalToProcess,
      confirmModalProps,
    } = this.state;

    return (
      <div>
        <ModalBox
          {...modalProps}
          danger
          selectedRole={role}
          selected={selected}
          confirmModalProps={confirmModalProps}
          onSave={this.onSave}
          onNext={this.nextStep}
          onPrev={this.prevStep}
          leftToProcess={leftToProcess}
          totalToProcess={totalToProcess}
          errorsList={errors}
          step={step}
          showInstitutionDetails
          confirmationSectionConfig={confirmationSectionConfig}
        />
      </div>
    );
  }
}

RemoveUsersFromSpacesModal.defaultProps = {
  search: () => {},
  deleteUsers: () => {},
};

export default withStyles(
  {},
  { withTheme: true },
)(withRouter(RemoveUsersFromSpacesModal));
