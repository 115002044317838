import { connect } from 'react-redux';
import DeleteUsersFromInstitution from './DeleteUsersFromInstitution';
import { search } from '../../../redux/actions/search';
import { deleteUsersFromInstitution } from '../../../redux/actions/user';
import { getSearchData as getUsersSearchData } from '../../../selectors/users';

const mapStateToProps = (state) => ({
  usersSearchData: getUsersSearchData(state),
  isSearching: state.search.isSearching,
});

const mapDispatchToProps = (dispatch) => ({
  search: (term, searchFor) => dispatch(search(term, searchFor)),
  deleteUsers: (users) => dispatch(deleteUsersFromInstitution(users)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteUsersFromInstitution);
