import colors from '../../../styles/colors';

export default {
  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    backgroundColor: colors.overlay,
    zIndex: 9999,
  },
  footerIcons: {
    color: '#000',
    marginRight: 12,
  },
  listItemIcon: {
    minWidth: 36,
  },
  listItemText: {
    padding: 0,
  },
  listItemPrimary: {
    fontSize: 14,
  },
};
