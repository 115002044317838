import omit from 'object.omit';

export const checkDuplicates = (a1, a2) => a1.filter((a) => a2.indexOf(a) > -1);
const count = (emails) =>
  emails.reduce((a, b) => Object.assign(a, { [b]: (a[b] || 0) + 1 }), {});

export const checkSelfDuplicates = (arr) => {
  const dict = count(arr);
  return Object.keys(dict).filter((a) => dict[a] > 1);
};

/**
 * extract elements that doesn't contain: duplicate, error.field, erorr.msg
 * will remove error field afterward
 *
 * @param {Array} data
 */
export const getUniqData = (data, cleanError = true) => {
  const fData = (data || []).filter(
    (d) => !d.duplicate && (!d.error || (!d.error.field && !d.error.msg)),
  );

  return cleanError ? fData.map((da) => omit(da, 'error')) : fData;
};

export const getUniqDataLength = (data) => getUniqData(data, false).length;
