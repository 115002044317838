import styled from '@emotion/styled';
import RemoveCircle from '@material-ui/icons/RemoveCircle';

export const BreakContainer = styled('div')`
  flex-basis: 100%;
  height: 0;
`;

export const RemoveCircleIcon = styled(RemoveCircle)`
  color: rgba(0, 0, 0, 0.87);
`;
