/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import shortid from 'shortid';
import { useFlags } from 'launchdarkly-react-client-sdk';

import ModalBox from '../../shared/ModalBox/ModalBox';
import createSpaceImage from './createSpaceImage.png';
import { getUniqData } from '../../../utils/duplicate';
import CreateSpaceConfirmation from './CreateSpaceConfirmation';
import CreateSpaceSuccess from './CreateSpaceSuccess';
import chunkProcess from '../../../utils/chunkProcess';
import { acceptedKeys, CSV_TYPES } from '../../../utils/csv';
import { parseDate } from '../../../utils/date';

const CHUNK_SIZE = 100;

const rightPanelConfig = {
  getRightPanelTitle: (entityName) => `Creating ${entityName}`,
  getPlaceholderTitle: () => 'Start by adding spaces.',
};

const confirmationSectionConfig = {
  getConfirmationMessage: (data) => <CreateSpaceConfirmation {...data} />,
  getSuccessMessage: (data) => <CreateSpaceSuccess {...data} />,
};

interface CreateSpaceModalProps extends RouteComponentProps {
  createSpaces?: (spaces: unknown[]) => void;
}

const CreateSpaceModal = (props: CreateSpaceModalProps) => {
  const [progressData, setProgressData] = useState<any>({
    leftToProcess: null,
  });
  const [errors, setErrors] = useState(null);
  const { adminFtOpenSpaceToggleEnabled202108 } = useFlags();

  // You can read more about the choice for the titleAndKey value
  // in the `utils/csv.js` file.
  const titleAndKey = adminFtOpenSpaceToggleEnabled202108
    ? CSV_TYPES.spaceWithOpenSpace
    : CSV_TYPES.space;

  const openSpaceSwitchField = adminFtOpenSpaceToggleEnabled202108
    ? {
        switchFields: {
          open: {
            id: 'open',
            name: 'open',
            title: 'Open for self sign-up',
            defaultChecked: false,
            value: false,
          },
        },
      }
    : {};

  const columns = acceptedKeys[titleAndKey];
  const endDateTextField = adminFtOpenSpaceToggleEnabled202108
    ? {
        endDate: {
          id: 'end-date',
          title: 'End Date',
          placeholder: 'YYYY-MM-DD',
          required: false,
        },
      }
    : {};

  const onSave = async (data) => {
    let spaces = getUniqData(data[titleAndKey]);

    if (columns.includes('endDate')) {
      spaces = spaces.map((space) => {
        const { endDate } = space;
        const { parsedDate } = parseDate(endDate);
        if (endDate && parsedDate) {
          return {
            ...space,
            endDate: parsedDate.toISOString(),
          };
        }

        return space;
      });
    }

    // if no new space to be added, close the modal
    if (!spaces.length) {
      return true;
    }

    await chunkProcess({
      data: spaces,
      chunkSize: CHUNK_SIZE,
      onRequest: props.createSpaces,
      onProgress: (onProgressData) => setProgressData(onProgressData),
      onError: (e) => setErrors([...(errors || []), e]),
    });

    return true;
  };

  const { createSpaces } = props;
  const { leftToProcess, totalToProcess } = progressData;

  return (
    <ModalBox
      title={titleAndKey}
      create={createSpaces}
      modalSubtitle="Create spaces"
      rightPanelConfig={rightPanelConfig}
      confirmationSectionConfig={confirmationSectionConfig}
      manualAdd={{
        tabTitle: 'Add a new Space',
        textFields: {
          name: {
            id: 'space-name',
            title: 'Space name',
            placeholder: 'Space name',
          },
          externalId: {
            id: 'external-id',
            title: 'External ID (should be unique)',
            placeholder: 'External ID',
            defaultValue: shortid.generate(),
          },
          ...endDateTextField,
        },
        ...openSpaceSwitchField,
        actionButton: {
          title: 'Add Space',
        },
        validateFields: (value, field) => {
          const invalidName = field === 'name' && value.trim() === '';

          const toMsg = (msg) => ({ message: msg });

          if (invalidName) {
            return toMsg("Field can't be empty");
          }

          if (field === 'endDate' && value !== undefined) {
            const { error } = parseDate(value);
            if (error) {
              return toMsg(error);
            }
          }

          return null;
        },
      }}
      csvLeftPanelAction={{
        tabTitle: 'import a CSV of spaces',
      }}
      columns={columns}
      table={{
        image: createSpaceImage,
      }}
      leftToProcess={leftToProcess}
      errorsList={errors}
      totalToProcess={totalToProcess}
      onSave={onSave}
      uniqueness={{
        columns: ['externalId'],
      }}
      showInstitutionDetails
    />
  );
};

CreateSpaceModal.defaultProps = {
  createSpaces: () => {},
};

export default withRouter(
  withStyles({}, { withTheme: true })(CreateSpaceModal),
);
