import React from 'react';
import HelpIcon from '@material-ui/icons/Help';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - IntercomAPI does not exist in this package. https://www.npmjs.com/package/react-intercom
import Intercom, { IntercomAPI as intercomAPI } from 'react-intercom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import styles from './style';
import { isLocal } from '../../../utils/isLocal';

const config = {
  prod: {
    APPID: 'vxd45up6',
  },
  dev: {
    APPID: 'l440w6dl',
  },
};

interface IntercomWrapperProps {
  userId: string;
  userName: string;
  email?: string;
}

class IntercomWrapper extends React.Component<
  IntercomWrapperProps,
  { open: boolean }
> {
  static defaultProps: Partial<IntercomWrapperProps>;

  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    intercomAPI('onHide', () => {
      this.setState({ open: false });
    });
    intercomAPI('onShow', () => {
      this.setState({ open: true });
    });
  }

  open() {
    this.setState({ open: true });
    intercomAPI('show');
  }

  close() {
    this.setState({ open: false });
    intercomAPI('hide');
  }

  toggle() {
    if (this.state.open) {
      this.close();
    } else {
      this.open();
    }
  }

  render() {
    return (
      <div style={{ width: '100%', position: 'relative' }}>
        <ListItem button onClick={this.toggle} style={{ paddingLeft: '16px' }}>
          <ListItemIcon style={styles.listItemIcon}>
            <HelpIcon style={styles.footerIcons} />
          </ListItemIcon>
          <ListItemText style={styles.listItemText} primary="Help" />
        </ListItem>
        <Intercom
          appID={isLocal ? config.dev.APPID : config.prod.APPID}
          user_id={this.props.userId}
          email={this.props.email}
          name={this.props.userName}
          custom_launcher_selector="#no-selector--"
        />
      </div>
    );
  }
}

IntercomWrapper.defaultProps = {
  email: '',
};

export default IntercomWrapper;
