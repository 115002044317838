/* eslint-disable consistent-return */
import Raven from './raven';

const ravenWrapper = (fn) => async (dispatch, getState) => {
  try {
    return await fn(dispatch, getState);
  } catch (error) {
    Raven.captureException(error);
  }
};

export default ravenWrapper;
