import styled from '@emotion/styled';
import { theme } from '@ublend-npm/aulaui-next';

export const StepperContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  & > div:not(:last-child) {
    margin-right: 4px;
  }
`;

export const Step = styled('div')<{ active: boolean }>`
  width: 30px;
  height: 4px;
  border-radius: ${theme.borderRadius.small};
  background-color: ${({ active }) =>
    active ? theme.color.purple : theme.color.gray};
`;
