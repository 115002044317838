import types from '../../constants/actions';

export const dismissToast = () => (dispatch) => {
  dispatch({
    type: types.TOASTS_DISMISS_TOAST,
    status: 'success',
    payload: {},
  });
};

export const showToast = (toast) => (dispatch) => {
  dispatch({
    type: types.TOASTS_SHOW_TOAST,
    status: 'success',
    payload: { toast },
  });
};
