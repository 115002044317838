import CONSTS from '../../constants/actions';

const initialState = {
  isSearching: false,
  isErrored: false,
  results: [
    {
      title: 'users',
      data: [],
    },
    {
      title: 'spaces',
      data: [],
    },
  ],
};

const searchReducer = (state, action) => {
  switch (action.status) {
    case 'started':
      return {
        ...initialState,
        isSearching: true,
      };
    case 'error':
      return {
        ...state,
        isErrored: true,
        isSearching: false,
      };

    case 'success':
      return {
        ...state,
        isSearching: false,
        isErrored: false,
        results: action.data,
      };
    default:
      return state;
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONSTS.SEARCH:
      return searchReducer(state, action);
    default:
      return state;
  }
};
