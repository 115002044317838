import chunk from 'lodash/chunk';

/**
 * Perform requests in chunk
 * @param data - Data to be split into smaller pieces
 * @param chunkSize - Size of the chunk
 * @param onRequest - Callback to be called for performing an actual request
 * @param onProgress - The callback to be called for updating the progress of the request
 * @param onError - Callback to be called for displaying errors of particular chunk
 * @returns {Promise<unknown[]>}
 */
const chunkProcess = ({
  data = [],
  chunkSize,
  onRequest,
  onProgress,
  onError,
}) => {
  const totalToProcess = data.length;
  let leftToProcess = totalToProcess;
  const chunks = chunk(data, chunkSize);
  const promises = [];

  // Update the initial progress
  onProgress({ totalToProcess, leftToProcess });

  chunks.forEach((items) => {
    try {
      const request = onRequest(items)
        .catch(onError)
        .finally(() => {
          leftToProcess -= chunkSize;
          onProgress({ leftToProcess });
        });

      promises.push(request);
    } catch (e) {
      onError(e);
    }
  });

  return Promise.all(promises);
};

export default chunkProcess;
