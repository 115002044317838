import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  wrapper: {
    position: 'absolute',
    maxHeight: '492px',
    overflow: 'auto',
    width: '100%',
    zIndex: 100,
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[100]}`,
  },
  list: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  item: {
    padding: 8,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  notFound: {
    padding: 8,
  },
  categoryHeader: {
    padding: 8,
    fontWeight: 'bold',
    color: theme.palette.grey[600],
  },
  title: {
    fontWeight: 'bold',
    color: theme.palette.common.black,
  },
  subtitle: {
    fontSize: 12,
    color: theme.palette.grey[600],
  },
});

interface SearchResultsProps {
  classes: {
    wrapper?: string;
    list?: string;
    item?: string;
    notFound?: string;
    categoryHeader?: string;
    title?: string;
    subtitle?: string;
  };
  results: {
    id?: string;
    title?: string;
    subtitle?: string;
    data?: {
      id?: string;
      title?: string;
      subtitle?: string;
    }[];
  }[];
  onSelect(..._args: unknown[]): React.MouseEventHandler<HTMLLIElement>;
}

const SearchResults = ({ classes, results, onSelect }: SearchResultsProps) => {
  if (!results) return null;

  if (!results.length) {
    return (
      <div className={classes.wrapper}>
        <div className={classes.notFound}>No results found.</div>
      </div>
    );
  }

  if (results[0].data !== undefined) {
    return (
      <div className={classes.wrapper}>
        <ul className={classes.list}>
          {results.map((result) => (
            <React.Fragment key={result.title}>
              <li className={classes.categoryHeader}>
                <div>{result.title}</div>
              </li>
              {result.data.map((item) => (
                <li
                  key={item.id}
                  className={classes.item}
                  onClick={onSelect({ result, item })}
                >
                  <div>
                    <div className={classes.title}>{item.title}</div>
                    <div className={classes.subtitle}>{item.subtitle}</div>
                  </div>
                </li>
              ))}
            </React.Fragment>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <ul className={classes.list}>
        {results.map((item) => (
          <li key={item.id} className={classes.item} onClick={onSelect(item)}>
            <div>
              <div className={classes.title}>{item.title}</div>
              <div className={classes.subtitle}>{item.subtitle}</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default withStyles(styles as any, { withTheme: true })(SearchResults);
