import React from 'react';
import pluralize from 'pluralize';

interface CreateSpaceSuccessProps {
  title: string;
  uniqDataLength: number;
  parentClasses?: {
    bold?: string;
  };
}

const CreateSpaceSuccess = ({
  title,
  parentClasses,
  uniqDataLength,
}: CreateSpaceSuccessProps) => (
  <span>
    You just created{' '}
    <span className={parentClasses.bold}>
      {pluralize(title, uniqDataLength, true)}
    </span>
  </span>
);

CreateSpaceSuccess.defaultProps = {
  parentClasses: {},
};

export default CreateSpaceSuccess;
