import { request } from '../../utils/api';
import {
  FETCH_SPACE,
  FETCH_SPACE_PARTICIPANTS,
  UPDATE_SPACE,
  CREATE_SPACE,
} from '../../constants/endpoints';
import { showToast } from './toasts';

// actions
export const fetchSpaceParticipants =
  ({ spaceId, skip }) =>
  async (dispatch) => {
    try {
      const { participants } = await request({
        route: FETCH_SPACE_PARTICIPANTS({ spaceId, skip }),
        dispatch,
      });
      return participants;
    } catch (error) {
      return false;
    }
  };

export const fetchSpaceById = (spaceId) => async (dispatch) => {
  try {
    const { space } = await request({
      route: `${FETCH_SPACE}/${spaceId}`,
      dispatch,
    });
    const participants = await fetchSpaceParticipants({ spaceId, skip: 0 })(
      dispatch,
    );
    return {
      ...space,
      users: participants,
    };
  } catch (error) {
    return false;
  }
};

export const updateSpace = (space) => async (dispatch) => {
  try {
    const { space: updatedSpace } = await request({
      route: `${UPDATE_SPACE}/${space.id}`,
      method: 'patch',
      data: space,
      dispatch,
    });
    return updatedSpace;
  } catch (error) {
    dispatch(
      showToast({
        message:
          error.response.data.message || error.response.data || error.message,
      }),
    );
    throw Error(error.message);
  }
};

export const create = (spaces) => async (dispatch) => {
  try {
    const response = await request({
      route: CREATE_SPACE,
      data: { spaces },
      method: 'post',
      dispatch,
    });

    if (response.errors.length) {
      const error = new Error(
        response.errors
          .map((e) => `"${e.message}" for space "${e.externalId}"`)
          .join('\n'),
      );
      (error as any).metadata = response.errors;
      throw error;
    }

    return response;
  } catch (e) {
    const detail =
      e.response && e.response.data
        ? `: ${JSON.stringify(e.response.data)}`
        : '';
    throw Error(`${e.message}${detail}`);
  }
};
