import { connect } from 'react-redux';
import Search from './Search';
import { search } from '../../../redux/actions/search';

const mapStateToProps = (state) => ({
  searchData: state.search.results,
  isSearching: state.search.isSearching,
});

const mapDispatchToProps = (dispatch) => ({
  search: (term) => dispatch(search(term)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
