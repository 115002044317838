import actionTypes from '../../../constants/actions';
import ravenWrapper from '../../../utils/ravenAsyncWrapper';
import { getUserBySessionToken } from '../../../utils/api';

const loginStart = () => ({
  type: actionTypes.USER_LOGIN,
  status: 'started',
});

const loginSuccess = (user, dispatch) => {
  dispatch({
    type: actionTypes.USER_LOGIN,
    status: 'success',
    user,
  });
};

export const loginWithSecureAuthCookie = () =>
  ravenWrapper(async (dispatch) => {
    dispatch(loginStart());
    try {
      const user = await getUserBySessionToken();
      loginSuccess(user, dispatch);
      return user;
    } catch (error) {
      throw new Error(error.message);
    }
  });

export default loginWithSecureAuthCookie;
