import React, { useState, useEffect } from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const tooltipText = 'Copy Sign-Up URL to Clipboard';

interface CopyTextIconButtonProps {
  baseText?: string;
  value?: string;
}

const CopyTextIconButton = ({ baseText, value }: CopyTextIconButtonProps) => {
  const [buttonEnabled, setEnabled] = useState(true);
  const [tooltipTitle, setTooltipTitle] = useState(tooltipText);

  useEffect(() => {
    let resetPropTimer;
    if (!buttonEnabled) {
      resetPropTimer = setTimeout(() => {
        setEnabled(true);
        setTooltipTitle(tooltipText);
      }, 1000);
    }

    return () => {
      if (resetPropTimer) {
        clearTimeout(resetPropTimer);
      }
    };
  }, [buttonEnabled]);

  if (!navigator.clipboard) {
    return null;
  }

  return (
    <Tooltip placement="right" title={tooltipTitle} leaveDelay={500}>
      <IconButton
        aria-label="options"
        onClick={() => {
          void navigator.clipboard.writeText(`${baseText}${value}`);
          setTooltipTitle('Copied!');
          setEnabled(false);
        }}
        disabled={!buttonEnabled}
      >
        <FileCopyIcon />
      </IconButton>
    </Tooltip>
  );
};

CopyTextIconButton.defaultProps = {
  baseText: '',
  value: '',
};

export default CopyTextIconButton;
