import React from 'react';
import { IconButton } from '@ublend-npm/aulaui-next';
import { DeleteOutline } from '@material-ui/icons';
import { ButtonContainer } from './DeleteParameterButton.styles';

type DeleteParameterButtonProps = { handleClick: () => void };

const DeleteParameterButton = ({ handleClick }: DeleteParameterButtonProps) => (
  <ButtonContainer>
    <IconButton
      icon={() => <DeleteOutline />}
      size="small"
      onClick={handleClick}
      label="Delete custom parameter"
      aria-describedby="Delete custom parameter"
    />
  </ButtonContainer>
);

export default DeleteParameterButton;
