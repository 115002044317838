import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Route } from 'react-router-dom';
import User from './User/User.container';
import Space from './Space/SpaceWithFeatureFlag';
import InfoCard from './InfoCard/InfoCard';
import LoaderScreen from '../shared/Loaders/LoaderScreen';

const styles = () => ({
  main: {
    height: '100%',
  },
  mainContainer: {
    height: '100%',
    display: 'flex',
  },
  fabContainer: {
    position: 'absolute',
    bottom: 24,
    right: 24,
  },
});

class DataManagement extends Component<any> {
  state = {
    showCreateClassModal: false,
    showAddUserModal: false,
    leftToCreate: 0,
  };

  render() {
    const { classes, match } = this.props;
    const { leftToCreate } = this.state;
    const InfoCardComponent = () => (
      <div className={classes.mainContainer}>
        <InfoCard />
      </div>
    );
    return (
      <div className={classes.main}>
        <LoaderScreen
          open={leftToCreate > 0}
          tileProps={{
            heading: `${leftToCreate} rows remaining to be created...`,
            text: 'This may take a moment.',
            loader: true,
          }}
        />
        <Route path={match.url} exact component={InfoCardComponent} />

        <Route path={`${match.url}/users/:userId`} component={User} />
        <Route path={`${match.url}/spaces/:spaceId`} component={Space} />
      </div>
    );
  }
}

export default withStyles(styles as any, { withTheme: true })(DataManagement);
