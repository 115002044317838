export type UrlWithError = { value: string; error: string | null };

export const STATIC_PARAM_VALUE = 'static' as const;

export const ParameterRenderTypes = {
  DROPDOWN: 'dropdown',
} as const;

export type ParameterRenderType =
  (typeof ParameterRenderTypes)[keyof typeof ParameterRenderTypes];

type DynamicParameter = Readonly<{
  key: string;
  value: string;
  keyError?: string | null;
  valueError?: string | null;
  staticValue?: undefined;
  dropdownLabel?: string;
  dropdownLabelError?: string | null;
  options?: CustomOption[];
}>;

export type CustomOption = Readonly<{
  label: string;
  value: string;
  labelError?: string | null;
  valueError?: string | null;
}>;

type StaticParameter = Readonly<{
  key: string;
  value: typeof STATIC_PARAM_VALUE;
  keyError?: string | null;
  valueError?: string | null;
  staticValue: string;
  dropdownLabel: undefined;
  dropdownLabelError?: string | null;
  options: CustomOption[];
}>;

export type Parameter = DynamicParameter | StaticParameter;

export type FormParameters = {
  customParameters: Parameter[];
  assignmentParameters: Parameter[];
};
