import React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@ublend-npm/aulaui-next';
import IntegrationIdentification from './IntegrationIdentification.container';
import { styles } from './IntegrationIdentificationDialog.styles';

type IntegrationIdentificationDialogProps = WithStyles<typeof styles> &
  Readonly<{
    open: boolean;
    onClose: () => void;
    description: string;
    iss: string;
    clientId: string;
    classes: any;
    deploymentId: string | null;
  }>;

const IntegrationIdentificationDialog = ({
  open,
  onClose,
  description,
  iss,
  clientId,
  deploymentId,
  classes,
}: IntegrationIdentificationDialogProps) => (
  <Dialog
    open={open}
    onClose={onClose}
    data-testid="integration-identification-dialog"
  >
    <DialogTitle className={classes.dialogTitle}>
      <div className={classes.dialogTitleInner}>
        <div>Integration added</div>
        <IconButton
          label="Close Dialog"
          disableRipple
          onClick={onClose}
          icon={CloseIcon}
          size="xSmall"
        />
      </div>
    </DialogTitle>
    <DialogContent className={classes.dialogContent}>
      <div className={classes.paragraph}>
        <b>{description}</b> was added.
        <br />
        Share the identification details below with the tool vendor. You can
        retrieve these details again from the edit integration dialog.
      </div>
      <IntegrationIdentification
        iss={iss}
        clientId={clientId}
        deploymentId={deploymentId}
      />
    </DialogContent>
  </Dialog>
);

export default withStyles(styles)(IntegrationIdentificationDialog);
