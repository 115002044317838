import axios from 'axios';
import actionTypes from '../../constants/actions';
import { buildInstitutionURL } from '../../utils/institutionUrl';

const fetchInstitutionStarted = () => ({
  type: actionTypes.FETCH_INSTITUTION,
  status: 'started',
});

export const fetchInstitutionSuccess = (results, dispatch) => {
  dispatch({
    type: actionTypes.FETCH_INSTITUTION,
    status: 'success',
    results: results.data,
  });
};

const fetchInstitutionFailed = (error, dispatch) => {
  dispatch({
    type: actionTypes.FETCH_INSTITUTION,
    status: 'error',
    error,
  });
};

export const fetchInstitution = () => async (dispatch) => {
  dispatch(fetchInstitutionStarted());

  const url = buildInstitutionURL({ path: 'conf/read' });

  try {
    const results = await axios.post(url, {
      modules: ['general', 'launchDarkly'],
    });
    fetchInstitutionSuccess(results, dispatch);
  } catch (error) {
    fetchInstitutionFailed(error, dispatch);
    throw new Error(error.message);
  }
};
