import CONSTS from '../../constants/actions';

const initialState = {
  users: [],
};

const addUsersReducer = (state, action) => {
  switch (action.status) {
    case 'success':
      return {
        users: action.data.map(({ firstName, lastName, externalId }) => ({
          name: `${firstName} ${lastName}`,
          externalId,
        })),
      };
    default:
      return state;
  }
};

const removeUsersReducer = (state, action) => {
  switch (action.status) {
    case 'success':
      return {
        users: [],
      };
    default:
      return state;
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONSTS.ADD_USERS:
      return addUsersReducer(state, action);
    case CONSTS.REMOVE_USERS:
      return removeUsersReducer(state, action);
    default:
      return state;
  }
};
