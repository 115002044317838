import CONSTS from '../../constants/actions';

const initialState = {
  isLoading: false,
};

const loadingReducer = (state, action) => ({
  ...state,
  isLoading: action.isLoading,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case CONSTS.LOADING:
      return loadingReducer(state, action);
    default:
      return state;
  }
};
