import React from 'react';
import { Avatar } from '@ublend-npm/aulaui-next';
import { withStyles } from '@material-ui/core/styles';

const S3_BUCKET_LOCATION =
  'https://s3-eu-west-1.amazonaws.com/aula-general-storage';

const styleClasses = (theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 12px',
  },
  avatar: {
    marginRight: 12,
  },
  name: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightRegular,
  },
  content: {
    fontSize: 15,
    fontWeight: theme.typography.fontWeightRegular,
  },
  separator: {
    fontSize: 16,
    margin: '0 10px',
  },
});

const InstitutionDetails = (props) => (
  <div className={props.classes.wrapper}>
    <Avatar
      className={props.classes.avatar}
      size="large"
      name={props.institution.general.display_name}
      src={`${S3_BUCKET_LOCATION}/${props.institution.general.institution}-logo.png`}
    />
    <div>
      <span className={props.classes.name}>
        {props.institution.general.display_name}
      </span>
      {props.children ? (
        <React.Fragment>
          <span className={props.classes.separator}>-</span>
          <span className={props.classes.content}>{props.children}</span>
        </React.Fragment>
      ) : null}
    </div>
  </div>
);

export default withStyles(styleClasses, { withTheme: true })(
  InstitutionDetails,
);
