import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { Provider as AulaThemeProvider } from '@ublend-npm/aulaui-next';
import { createTheme } from '@material-ui/core/styles';
import { BrowserRouter, Route } from 'react-router-dom';
import { configureStore } from './redux/store';
import THEME from './styles/theme';
import DataManagement from './components/DataManagement/DataManagement';
import IntegrationManagement from './components/IntegrationManagement/IntegrationManagement.container';
import HeaderAndSidebar from './components/shared/HeaderAndSidebar/HeaderAndSidebar';
import ProgressBar from './components/shared/ProgressBar/ProgressBar';
import Toast from './components/shared/Toast/Toast';
import CreateSpaceModal from './components/DataManagement/CreateSpace/CreateSpace.container';
import CreateUserModal from './components/DataManagement/CreateUser/CreateUser.container';
import AddUserModal from './components/DataManagement/AddUser/AddUser.container';
import AddRoles from './components/DataManagement/AddRoles/AddRoles.container';
import RemoveUsersFromSpaces from './components/DataManagement/RemoveUsersFromSpaces/RemoveUsersFromSpaces.container';
import DeleteUsersFromInstitution from './components/DataManagement/DeleteUsersFromInstitution/DeleteUsersFromInstitution.container';
import { fetchInstitution } from './redux/actions/institution';
import { loginWithSecureAuthCookie } from './redux/actions/user/login';
import { getInstitutionShortName, getKeys } from './selectors/institution';
import { getCookie } from './utils/cookie';

import { redirectToGeneral } from './utils/institutionUrl';
import addAxiosInterceptors from './utils/addAxiosInterceptors';

const MainTheme = createTheme(THEME);

const renderApp = (store, LDProvider) => {
  const appElement = document.getElementById('app');

  render(
    <LDProvider>
      <Provider store={store}>
        <AulaThemeProvider theme={MainTheme}>
          <BrowserRouter>
            <div
              style={{
                height: '100vh',
                overflow: 'hidden',
                fontSize: 14,
              }}
            >
              <HeaderAndSidebar>
                <Route path="/manage" component={DataManagement} />
                <Route path="/integrations" component={IntegrationManagement} />
                <Route path="/keys" component={DataManagement} />

                <Route
                  path="/"
                  render={({ location }) =>
                    location.search.indexOf('createClass=true') > 0 && (
                      <CreateSpaceModal />
                    )
                  }
                />
                <Route
                  path="/"
                  render={({ location }) =>
                    location.search.indexOf('createUser=true') > 0 && (
                      <CreateUserModal />
                    )
                  }
                />
                <Route
                  path="/"
                  render={({ location }) =>
                    location.search.indexOf('addUser=true') > 0 && (
                      <AddUserModal />
                    )
                  }
                />
                <Route
                  path="/"
                  render={({ location }) =>
                    location.search.indexOf('addRoles=true') > 0 && <AddRoles />
                  }
                />
                <Route
                  path="/"
                  render={({ location }) =>
                    location.search.indexOf('removeUsersFromSpaces=true') >
                      0 && <RemoveUsersFromSpaces />
                  }
                />
                <Route
                  path="/"
                  render={({ location }) =>
                    location.search.indexOf('deleteUsersFromInstitution=true') >
                      0 && <DeleteUsersFromInstitution />
                  }
                />
                <Route path="/" exact component={DataManagement} />
              </HeaderAndSidebar>
              <ProgressBar />
              <Toast />
            </div>
          </BrowserRouter>
        </AulaThemeProvider>
      </Provider>
    </LDProvider>,
    appElement,
  );
};

if (module.hot) {
  module.hot.accept();
}

const startApp = async () => {
  const store = await configureStore();
  await store.dispatch(fetchInstitution());

  const ssoCookie = getCookie('sso-session-t');
  if (ssoCookie) {
    localStorage.clear(); // clean parse current user
  }

  await store.dispatch(loginWithSecureAuthCookie());

  const userState = store.getState().user;
  const isAdmin = userState.user && userState.user.userRoles.includes('admin');
  if (!isAdmin) {
    redirectToGeneral({ returnAfterLogin: true });
    return;
  }

  const { launchDarkly } = getKeys(store.getState());

  const institution = getInstitutionShortName(store.getState());

  const LDProvider = await asyncWithLDProvider({
    clientSideID: launchDarkly.clientSideId,
    context: {
      kind: 'user',
      key: userState.user.id,
      institution,
    },
  });

  addAxiosInterceptors();

  renderApp(store, LDProvider);
};

void startApp();
