import { createStyles } from '@material-ui/core/styles';

export const styles = createStyles({
  wrapper: {
    display: 'flex',
    // Required for consistent vertical spacing between fields
    // with or without error text when validated
    marginBottom: -4,
    marginTop: -8,
  },
  inputContainer: {
    flex: 1,
    minHeight: 76,
  },
  deleteContainer: {
    display: 'flex',
    alignItems: 'center',
    height: 40,
    marginLeft: 12,
    marginTop: 8,
  },
  redirectWrapper: {
    paddingBottom: '0 !important',
  },
});
