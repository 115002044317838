import React from 'react';
import { SelectInput, TextField } from '@ublend-npm/aulaui-next';
import { LtiLaunchContainer, LtiVersion } from '@ublend-npm/aula-schema';
import {
  DialogContent,
  SubHeading,
  Caption,
  Permission,
  SubHeadingPermissions,
  DialogHorizontalRuler,
} from './DialogSteps.styles';
import { LaunchContainerOptions } from '../../../constants/ltiConstants';
import SwitchField from '../../shared/SwitchField/SwitchField';

type AppearanceStepProps = {
  description: string;
  onDescriptionChange: (event: string) => void;
  showErrors: boolean;
  iconUrl: string;
  onIconURLChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  launchContainer: string;
  onLaunchContainerChange: (value: LtiLaunchContainer) => void;
  ltiVersion: LtiVersion;
  isDeepLinking: boolean;
  onIsDeepLinkingChange: (state: boolean) => void;
  canReadContextMembership: boolean;
  onCanReadContextMembershipChange: (state: boolean) => void;
  disabled: boolean;
  onDisabledChange: (value: boolean) => void;
  iconUrlError: string;
};

const AppearanceStep = ({
  description,
  onDescriptionChange,
  showErrors,
  iconUrl,
  onIconURLChange,
  launchContainer,
  onLaunchContainerChange,
  ltiVersion,
  isDeepLinking,
  onIsDeepLinkingChange,
  canReadContextMembership,
  onCanReadContextMembershipChange: setCanReadContextMembership,
  disabled,
  onDisabledChange,
  iconUrlError,
}: AppearanceStepProps) => {
  return (
    <DialogContent>
      <SubHeading>Appearance</SubHeading>
      <Caption>
        Customise how the integration appears to educators and students.
      </Caption>
      <TextField
        label="Name"
        value={description}
        onChange={(event) => onDescriptionChange(event.target.value)}
        error={!description && showErrors}
        helperText={
          !description && showErrors
            ? 'Enter a name.'
            : `${description.length}/20 characters`
        }
        data-testid="integration-name-field"
        autoFocus
        maxLength={20}
      />
      <TextField
        label="Icon URL"
        value={iconUrl}
        onChange={onIconURLChange}
        error={showErrors && !!iconUrlError}
        helperText={showErrors ? iconUrlError : undefined}
      />
      <SelectInput
        disabled={ltiVersion === LtiVersion.ONE_POINT_THREE}
        name="LaunchContainerInput"
        label="Launch container"
        data-testid="launch-container-select"
        data={LaunchContainerOptions}
        value={launchContainer}
        helperText={
          ltiVersion === LtiVersion.ONE_POINT_THREE
            ? 'LTI 1.3 integrations can only open in a new tab.'
            : undefined
        }
        handleChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
          onLaunchContainerChange(event.target.value as LtiLaunchContainer)
        }
      />
      {ltiVersion === LtiVersion.ONE_POINT_THREE ? (
        <>
          <DialogHorizontalRuler />
          <SubHeading>Enable deep linking</SubHeading>
          <Permission>
            <SwitchField
              name="enableDeepLinking"
              ariaLabel="Enable deep linking: This allows educators more control over the configuration of their integrations"
              title="This allows educators more control over the configuration of their integrations"
              defaultChecked={isDeepLinking}
              onChange={onIsDeepLinkingChange}
            />
          </Permission>
        </>
      ) : null}
      {ltiVersion === LtiVersion.ONE_POINT_THREE ? (
        <>
          <SubHeadingPermissions>Permissions</SubHeadingPermissions>
          <Permission>
            <SwitchField
              ariaLabel="Permissions: Can retrieve participants of the space"
              name="canReadContextMembership"
              title="Can retrieve participants of the space"
              defaultChecked={canReadContextMembership}
              onChange={setCanReadContextMembership}
            />
          </Permission>
        </>
      ) : null}
      <>
        <SubHeading>Disable integration</SubHeading>
        <Permission>
          <SwitchField
            ariaLabel="Disable integration: This hides the integration in Aula. We recommend disabling new integrations until configuration is completed."
            name="isDisabled"
            title="This hides the integration in Aula. We recommend disabling new integrations until configuration is completed."
            defaultChecked={disabled}
            onChange={onDisabledChange}
          />
        </Permission>
      </>
    </DialogContent>
  );
};

export default AppearanceStep;
