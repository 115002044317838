import { connect } from 'react-redux';
import User from './User';
import { fetchUserById, update } from '../../../redux/actions/user';
import { createRoles, deleteRoles } from '../../../redux/actions/roles';
import { showToast } from '../../../redux/actions/toasts';

const mapStateToProps = (state) => ({
  sso: state.institution.general.sso,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUser: (userId) => dispatch(fetchUserById(userId)),
  updateUser: (userId, data) => dispatch(update({ userId, data })),
  updateRole: (role) => dispatch(createRoles([role])),
  deleteRoles: (userId, _spaceId) => dispatch(deleteRoles(userId)),
  showToast: (payload) => dispatch(showToast(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(User);
