/* eslint-disable import/prefer-default-export */
import CSVParser, { ParseResult } from 'papaparse';
import { parseDate } from './date';

import { isValidEmail } from './email';

export const GENERAL_ERROR = 'We could not import your CSV 🤔';
export const EMAIL_ERROR = 'Invalid email. Will not be added.';

/**
 * use CSVParser to parse file
 *
 * @param {files} files
 */
export const parse = (files): Promise<ParseResult<any>> =>
  new Promise((resolve, reject) => {
    CSVParser.parse(files[0], {
      header: true,
      error: () => reject(GENERAL_ERROR),
      complete: (results) => resolve(results),
    });
  });

export const isErrorEmpty = (obj) =>
  !obj.error ||
  (Object.keys(obj.error).length === 0 && obj.error.constructor === Object);
export const notAlphaNumeric = (str) => !/^[A-Za-z0-9]+$/.test(str);

/**
 * custom validation, return error that can be used in table cell
 *
 * @param {String} key
 * @param {String} value
 */
export const validate = (key, value) => {
  if (key === 'email' && !isValidEmail(value)) {
    return {
      field: 'email',
      msg: EMAIL_ERROR,
    };
  }

  if (key === 'endDate') {
    const { error } = parseDate(value);
    if (error) {
      return {
        field: 'endDate',
        msg: `${error}. Will not be added`,
      };
    }
  }

  return {};
};

/**
 * match object's keys with listed keys
 * - invalid if object has key(s) that not in key list
 * - valid if object doesn't have key(s) that is listed in key list
 *
 * @param {Array} keys
 * @param {Object} object
 */
export const matchKeys = (keys, object) =>
  Object.keys(object).every((key) => keys.includes(key));

// Using `spaces` was a conscious decision but it is a confusing name.
// `space` and `spaces` are both used as the "title"/"key" in the `CreateSpace`
// component. They coexist because we need to differentiate the types when
// the feature toggle of open spaces is ON. The problem is that this key is
// also used as a user facing title, so using `spacesWithOpenSpaceToggle`
// was not going to be very user friendly.
export const CSV_TYPES = {
  space: 'space',
  spaceWithOpenSpace: 'spaces',
  user: 'user',
  role: 'role',
};

export const acceptedKeys = {
  [CSV_TYPES.space]: ['name', 'externalId'],
  [CSV_TYPES.spaceWithOpenSpace]: ['name', 'externalId', 'open', 'endDate'],
  [CSV_TYPES.user]: [
    'externalId',
    'name',
    'firstName',
    'lastName',
    'email',
    'sso',
    'admin',
    'staff',
  ],
  [CSV_TYPES.role]: ['externalSpaceId', 'externalUserId', 'role'],
};

/**
 * validate object base on file type
 *
 * @param {Object} data
 * @param {String} type
 */
export const checkDataByType = (data, type) => {
  const acceptedKeysForType = acceptedKeys[type];
  const allAceptedKeys = [...acceptedKeysForType, 'error'];
  const hasInvalidKeys = data.some((d) => !matchKeys(allAceptedKeys, d));

  return acceptedKeys[type] && hasInvalidKeys ? GENERAL_ERROR : null;
};

/**
 * normalize and convert key to its correct name
 * - ex1: FirstName => firstname => firstName
 * - ex2: LASTNAME => lastname => lastName
 * - ex3: KeY => key
 *
 * @param {String} key
 */
export const transformKey = (key) => {
  const lKey = key.toLowerCase();

  switch (lKey) {
    case 'firstname':
      return 'firstName';

    case 'lastname':
      return 'lastName';

    case 'externalid':
      return 'externalId';

    case 'externalspaceid':
      return 'externalSpaceId';

    case 'externaluserid':
      return 'externalUserId';

    case 'enddate':
      return 'endDate';

    default:
      return lKey;
  }
};

const truthy = ['true', 't', 'yes', 'y'];
const falsy = ['false', 'f', 'no', 'n'];

const getSSOValue = (sso) => {
  if (truthy.includes(sso)) return true;
  if (falsy.includes(sso)) return false;

  return undefined;
};

const mapValueForUser = (key, value) => {
  switch (key) {
    case 'sso':
      return getSSOValue(value);
    case 'staff':
    case 'admin':
      return truthy.includes(value);
    default:
      return value;
  }
};

const mapValueForSpaceWithOpenSpaces = (key, value) => {
  if (key === 'open') {
    if (truthy.includes(value.toLowerCase()) && !!value) {
      return true;
    }

    return false;
  }

  return value;
};

const mapValueGeneric = (key, value) => value;

const ValueMappers = {
  user: mapValueForUser,
  [CSV_TYPES.spaceWithOpenSpace]: mapValueForSpaceWithOpenSpaces,
};

/**
 * transform, normalize key, and validate file
 *
 * @param {Array} pFiles parsed files from CSV parser
 * @param {String} type type of file, either `user` or `space`
 */
export const transform = (pFiles: ParseResult<any>, type) => {
  const { data } = pFiles;
  const lowerCaseFields = ['email', 'sso', 'staff', 'admin'];

  const nData = data.map((datum) =>
    Object.entries(datum).reduce((obj, [key, value]: [string, string]) => {
      const transformedKey = transformKey(key);
      const trimmedValue = value ? value.trim() : '';
      const transformedValue = lowerCaseFields.includes(transformedKey)
        ? trimmedValue.toLowerCase()
        : trimmedValue;

      if (!transformedKey) {
        return obj;
      }

      const mapValueFn = ValueMappers[type] || mapValueGeneric;
      const newObj = {
        ...obj,
        [transformedKey]: mapValueFn(transformedKey, transformedValue),
      };

      if (isErrorEmpty(newObj)) {
        (newObj as any).error = validate(transformedKey, transformedValue);
      }

      return newObj;
    }, {}),
  );

  const errorMsg = checkDataByType(nData, type);

  return errorMsg
    ? Promise.reject(errorMsg)
    : Promise.resolve(
        nData.filter((obj) =>
          Object.values(obj).every((value) => value !== ''),
        ),
      );
};
