import { createStyles } from '@material-ui/core/styles';

export const styles = createStyles({
  dialogTitle: {
    paddingTop: 20,
    border: 0,
  },
  dialogTitleInner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 600,
  },
  dialogContent: {
    paddingTop: 0,
    paddingBottom: 20,
    '& > div:not(:last-child)': {
      // Required for consistent vertical spacing between fields
      // with or without error text when validated
      minHeight: 64,
      marginTop: 0,
    },
  },
  paragraph: {
    marginBottom: 8,
  },
});
