import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import SearchInput from '../SearchInput/SearchInput';

const styles = (theme) => ({
  inputContainer: {
    position: 'relative',
    width: '100%',
  },
  input: {
    width: '100%',
    fontFamily: 'unset',
  },
  inputButton: {
    color: theme.palette.primary.main,
  },
});

interface SearchProps {
  extractSelected;
  onSelect;
  title;
  showToast;
  classes;
  data;
  isSearching;
  search;
  clearSearchOnSelect;
}

class Search extends Component<SearchProps> {
  handleSelect = (item) => {
    const { extractSelected, onSelect, title, showToast } = this.props;

    const selected = extractSelected(item);

    if (!selected.externalId) {
      showToast({
        message: `The selected ${title} doesn't contain an external ID, please give it one before trying again.`,
      });
    }

    onSelect([selected]);
  };

  render() {
    const { classes, data, title, isSearching, search, clearSearchOnSelect } =
      this.props;

    return (
      <SearchInput
        onSearch={(text) => {
          if (text) search(text);
        }}
        name={`${title}-search`}
        onSelect={this.handleSelect}
        loading={isSearching}
        label={`Search for ${title}s...`}
        classes={classes}
        results={data}
        buttonPosition="end"
        clearSearchOnSelect={clearSearchOnSelect}
        variant="standard"
      />
    );
  }
}

export default withStyles(styles as any, { withTheme: true })(Search);
