import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';

const styles = (theme) => ({
  root: {
    paddingRight: theme.spacing(),
    borderBottom: `1px solid ${theme.palette.divider}`,
    color: theme.palette.common.green,
  },
  highlight: {
    color: theme.palette.common.darkGreen,
    backgroundColor: 'rgba(0,175,170, 0.1)',
    // opacity: 0.1,
  },
  spacer: {
    flex: '1 1 100%',
  },
  action: {
    color: theme.palette.common.green,
  },
  titleContainer: {
    flex: '0 0 auto',
  },
  title: {
    fontSize: 16,
  },
  menuRoot: {
    zIndex: 1000001,
  },
});

const TableToolbar = (props) => {
  const { classes, numSelected, title, disableToolbarTitle, titleStyles } =
    props;

  if (disableToolbarTitle && numSelected === 0) {
    return null;
  }

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.titleContainer}>
        {numSelected > 0 && (
          <span className={classes.numSelected}>{numSelected} selected</span>
        )}
        {numSelected === 0 && !disableToolbarTitle && (
          <span className={classes.title} style={titleStyles}>
            {title}
          </span>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.action}>
        {numSelected > 0 ? (
          <div>
            <IconButton
              aria-label="More"
              aria-owns={props.anchorEl ? 'long-menu' : null}
              aria-haspopup="true"
              onClick={props.onMenuClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id={`${Math.floor(Math.random() * 1000)}-menu`}
              anchorEl={props.anchorEl}
              open={!!props.anchorEl}
              onClose={props.onMenuClose}
              className={classes.menuRoot}
              anchorOrigin={{
                horizontal: 'right',
                vertical: 'top',
              }}
              transformOrigin={{
                horizontal: 'right',
                vertical: 'top',
              }}
            >
              {React.cloneElement(props.menuItems, {
                onClick: (e) => {
                  props.menuItems.props.onClick(e);
                  props.onMenuClose();
                },
              })}
            </Menu>
          </div>
        ) : null}
      </div>
    </Toolbar>
  );
};

export default withStyles(styles, { withTheme: true })(TableToolbar);
