import React from 'react';
import Slide from '@material-ui/core/Slide';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import classNames from 'classnames';
import Tile from './Tile';
import AddNew from './AddNew';
import CSV from './CSV';
import Search from './Search.container';
import InstitutionDetails from '../InstitutionDetails/InstitutionDetails.container';

const Slider = ({ show, direction, component }) => (
  <Slide
    direction={direction}
    timeout={{
      enter: 225, // theme.transitions.duration.enteringScreen
      exit: 225, // theme.transitions.duration.leavingScreen
    }}
    in={show}
    mountOnEnter
    unmountOnExit
  >
    {component}
  </Slide>
);

const LeftPanel = ({
  classes,
  clicked,
  search,
  manualAdd,
  csvAction,
  title,
  columns,
  handleNavClick,
  handleAddNew,
  onBack,
  startLoading,
  stopLoading,
  uniqueness,
  theme,
  danger,
  modalSubtitle,
  showInstitutionDetails,
}) => (
  <div className={classes.left}>
    {showInstitutionDetails ? (
      <div
        className={classNames(classes.institutionDetailsWrapper, {
          [classes.danger]: danger,
        })}
      >
        <InstitutionDetails>{modalSubtitle}</InstitutionDetails>
      </div>
    ) : null}
    <div className={classes.sliderContainer}>
      <Slider
        direction="left"
        show={!clicked}
        component={
          <div>
            <List component="nav">
              {search && (
                <ListItem>
                  <Search
                    title={title}
                    onSelect={handleAddNew}
                    style={{ width: '100%' }}
                    theme={theme}
                    {...search}
                  />
                </ListItem>
              )}
              {manualAdd && (
                <Tile
                  onClick={handleNavClick}
                  id="add"
                  value={manualAdd.tabTitle}
                  hideOr={!search}
                />
              )}
              {csvAction && (
                <Tile
                  onClick={handleNavClick}
                  id="csv"
                  value={csvAction.tabTitle}
                  hideOr={undefined}
                />
              )}
            </List>
          </div>
        }
      />
    </div>
    <div className={classes.sliderContainer}>
      <Slider
        direction="right"
        show={clicked === 'add'}
        component={
          <AddNew
            {...manualAdd}
            uniqueness={uniqueness}
            onAddNew={handleAddNew}
            startLoading={startLoading}
            stopLoading={stopLoading}
            onBack={onBack}
          />
        }
      />
    </div>
    <div className={classes.sliderContainer}>
      <Slider
        direction="right"
        show={clicked === 'csv'}
        component={
          <CSV
            {...csvAction}
            actionButton={
              (csvAction && csvAction.actionButton) ||
              (manualAdd && manualAdd.actionButton)
            }
            title={title}
            columns={columns}
            onAddNew={handleAddNew}
            onBack={onBack}
            startLoading={startLoading}
          />
        }
      />
    </div>
  </div>
);

export default LeftPanel;
