import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const cardWidth = 577;

const styles = (theme) => ({
  container: {
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 96,
    width: cardWidth,
    fontSize: 15,
    paddingLeft: 24,
    boxShadow: theme.shadows[23],
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    margin: '0 auto',
  },
});

const InfoCard = ({ classes }) => (
  <div className={classes.container}>
    <div>
      Search for an existing user or space{' '}
      <span role="img" aria-label="point">
        ☝️
      </span>
    </div>
    <div>
      Or create new ones.{' '}
      <span role="img" aria-label="point">
        ✨
      </span>
    </div>
  </div>
);

export default withStyles(styles as any, { withTheme: true })(InfoCard);
