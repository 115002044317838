import axios, { AxiosError, AxiosResponse } from 'axios';
import { redirectToGeneral } from './institutionUrl';

export const onFullfilled = (response: AxiosResponse) => {
  // Interceptor handling a successful response
  return response;
};
export const onRejected = (error: AxiosError<any>) => {
  // Interceptor handling a failed response
  if (
    error.config?.url?.includes('aula.education') &&
    error.response?.status === 401 &&
    !error.response.data?.errors?.length
  ) {
    redirectToGeneral({ returnAfterLogin: true });
  }

  return Promise.reject(error);
};

export default () => {
  axios.interceptors.response.use(onFullfilled, onRejected);
};
