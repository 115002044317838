import { createStyles } from '@material-ui/core/styles';

export const styles = createStyles({
  providerDetails: {
    display: 'flex',
    flexDirection: 'column',
    '&> div': {
      // Required for consistent vertical spacing between fields
      // with or without error text when validated
      minHeight: 64,
      marginTop: 0,
    },
  },
});
