import React from 'react';
import pluralize from 'pluralize';

interface AddRolesConfirmationProps {
  title: string;
  uniqDataLength: number;
  parentClasses?: {
    bold?: string;
  };
}

const AddRolesConfirmation = ({
  title,
  parentClasses,
  uniqDataLength,
}: AddRolesConfirmationProps) => (
  <span>
    You are creating{' '}
    <span className={parentClasses.bold}>
      {pluralize(title, uniqDataLength, true)}
    </span>
  </span>
);

export default AddRolesConfirmation;
