import React, { useState } from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { icons, IconButton, Tooltip } from '@ublend-npm/aulaui-next';
import {
  LtiProvider,
  LtiPlacement,
  UpdateLtiProviderRequestBody,
} from '@ublend-npm/aula-schema';
import RemoveDialog from './RemoveDialog';
import ModifyDialog from './ModifyDialog';
import { styles } from './IntegrationListItem.styles';

const { ExtensionIcon, EditIcon } = icons;

type IntegrationListItemProps = WithStyles<typeof styles> &
  Readonly<{
    id: string;
    description: string;
    iconUrl?: string;
    isCustom: boolean;
    disabled: boolean;
    placement?: LtiPlacement;
    setError: React.Dispatch<React.SetStateAction<string>>;
    fetchIntegrationsHandler: () => Promise<void>;
    onDeleteProvider: (providerId: string, description: string) => void;
    editIntegration: (
      providerId: string,
      args: UpdateLtiProviderRequestBody,
    ) => LtiProvider;
    onFetchProviderById: (providerId: string) => LtiProvider;
  }>;

const IntegrationListItem = ({
  classes,
  id,
  description,
  iconUrl,
  isCustom,
  disabled,
  placement,
  setError,
  fetchIntegrationsHandler,
  onDeleteProvider,
  editIntegration,
  onFetchProviderById,
}: IntegrationListItemProps) => {
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [providerToBeEdited, setProviderToBeEdited] = useState<LtiProvider>();
  const [iconFailed, setIconFailed] = useState(false);

  const isAssignments = placement === LtiPlacement.ASSIGNMENTS;

  const openEditDialog = async () => {
    const provider = await onFetchProviderById(id);
    setProviderToBeEdited(provider);
    setIsDialogOpen(true);
  };

  const closeEditDialog = () => {
    setIsDialogOpen(false);
  };

  const openRemoveDialog = () => {
    setIsRemoveDialogOpen(true);
  };

  const closeRemoveDialog = () => {
    setIsRemoveDialogOpen(false);
  };

  const onEditProviderHandler = async (
    provider: UpdateLtiProviderRequestBody,
  ) => {
    try {
      await editIntegration(id, provider);
      closeEditDialog();
    } catch {
      setError(`Editing ${provider.description} was unsuccessful`);
    } finally {
      await fetchIntegrationsHandler();
    }
  };

  const noop = () => {};

  return (
    <>
      <ListItem
        data-testid="integration-list-item"
        button
        onClick={isCustom ? openEditDialog : undefined}
        classes={{
          root: classes.listItemRoot,
          container: classes.listItemContainer,
        }}
        aria-label={
          isCustom
            ? ''
            : `${description}. This integration was added by Aula and can't be edited or removed.`
        }
      >
        <ListItemIcon>
          {iconUrl && !iconFailed ? (
            <img
              className={classes.toolIcon}
              src={iconUrl}
              alt={description}
              onError={() => setIconFailed(true)}
            />
          ) : (
            <ExtensionIcon data-testid="puzzle-icon" fontSize="large" />
          )}
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={description}
          classes={{ root: classes.listItemText }}
          data-testid="integration-list-item-text"
        />
        {disabled ? (
          <span
            className={classes.disabledContainer}
            aria-label={`${description} (Disabled)`}
          >
            Disabled
            <Tooltip title="This integration has been set as disabled and will not appear in Aula. Enable this integration in the settings.">
              <InfoIcon
                className={classes.disabledIcon}
                aria-label={`${description} (Disabled) Explanation`}
                fontSize="small"
              />
            </Tooltip>
          </span>
        ) : null}
        <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
          {isCustom ? (
            <div className={classes.actionContainer}>
              <Tooltip title="Edit">
                <IconButton
                  type="secondary"
                  icon={EditIcon}
                  size="small"
                  onClick={openEditDialog}
                  label="Edit integration"
                />
              </Tooltip>
              <Tooltip
                title={
                  isAssignments
                    ? 'Assignment integrations cannot be removed. You may disable this integration in the settings.'
                    : 'Remove'
                }
              >
                <IconButton
                  className={classes.removeButton}
                  type="secondary"
                  icon={RemoveCircleIcon}
                  size="small"
                  buttonAttributes={{
                    'aria-disabled': isAssignments,
                  }}
                  onClick={isAssignments ? noop : openRemoveDialog}
                  label="Remove integration"
                />
              </Tooltip>
            </div>
          ) : (
            <Tooltip
              title="This integration was added by Aula and can't be edited or removed."
              describeChild={false}
            >
              <IconButton
                disabled
                className={classes.removeButton}
                type="secondary"
                icon={InfoIcon}
                size="small"
                label="Information about this integration"
              />
            </Tooltip>
          )}
        </ListItemSecondaryAction>
      </ListItem>
      <ModifyDialog
        isOpen={isDialogOpen}
        onCloseDialog={closeEditDialog}
        providerToBeEdited={providerToBeEdited}
        onSaveProvider={onEditProviderHandler}
      />
      <RemoveDialog
        open={isRemoveDialogOpen}
        description={description}
        onClose={closeRemoveDialog}
        onConfirm={() => onDeleteProvider(id, description)}
      />
    </>
  );
};

export default withStyles(styles)(IntegrationListItem);
