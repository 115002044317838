import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@ublend-npm/aulaui-next';
import TextField from '../TextField/TextField';
import Button from '../Buttons/FlatButton';
import SwitchField from '../SwitchField/SwitchField';
import { BreakContainer, RemoveCircleIcon } from './BasicInfoForm.style';
import CopyTextIconButton from './CopyTextIconButton';
import ClearableDateInput from '../ClearableDateInput/ClearableDateInput';

const styles = () => ({
  container: {
    display: 'flex',
    marginTop: -5,
  },
  avatar: {
    width: 40,
    marginRight: 12,
  },
  avatarWrapper: {
    position: 'relative',
  },
  textField: {
    marginLeft: 20,
  },
  greenBtn: {
    marginLeft: 20,
    borderRadius: 3,
  },
  greyBtn: {
    borderRadius: 3,
  },
  fields: {
    marginTop: 4,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  buttons: {
    float: 'right',
    marginTop: 5,
    marginBottom: 10,
  },
  divider: {
    clear: 'both',
  },
  switchContainer: {
    marginLeft: 20,
  },
  removeAvatarIcon: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    height: 40,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    top: 0,
    width: 40,
    zIndex: 1,
  },
});

const setPropsFieldToState = (props) => {
  const fields = {};
  props.fields.forEach((field) => {
    if (field.type === 'break') {
      return;
    }
    fields[field.id] = field.value;
  });
  return fields;
};

const AccountTypes = {
  users: 'user',
  spaces: 'space',
};

class BasicInfoForm extends Component<any> {
  state = {
    fields: setPropsFieldToState(this.props),
    errors: {},
    loading: false,
    saved: false,
    isRemovingAvatar: false,
    showRemoveAvatarModal: false,
    showRemoveIcon: false,
    accountType: null,
  };

  componentDidMount = () => {
    const path = window.location.pathname.split('/')[2];
    this.setState({ accountType: AccountTypes[path] });
  };

  componentWillReceiveProps = (nextProps) => {
    if (
      JSON.stringify(setPropsFieldToState(nextProps)) !==
      JSON.stringify(this.state.fields)
    ) {
      this.setState({ fields: setPropsFieldToState(nextProps) });
    }
  };

  handleSwitchChange = (name) => (event) => {
    this.handleChange(event.target.checked, name);
  };

  handleChange = (value, field) => {
    this.setState({
      fields: {
        ...this.state.fields,
        [field]: value,
      },
    });
  };

  handleStatusChange = (e) => {
    this.props.onStatusChange(e.target.value);
  };

  handleDiscard = () => {
    this.setState({ fields: setPropsFieldToState(this.props), errors: {} });
  };

  showSaved = () => {
    this.setState({ saved: true });
    setTimeout(() => {
      this.setState({ saved: false });
    }, 3000);
  };

  handleSave = async () => {
    this.setState({ loading: true, errors: {} });
    const errors = await this.props.preSaveCheck(this.state.fields);
    if (errors) {
      this.setState({
        errors,
        loading: false,
      });
      return;
    }
    await this.props.onSave(this.state.fields);
    this.setState({ loading: false });
    this.showSaved();
  };

  hasAvatarURL = (avatarUrl) => avatarUrl && avatarUrl !== 'null';

  showRemoveAvatarModal = () => {
    if (this.hasAvatarURL(this.props.avatarUrl)) {
      this.setState({ showRemoveAvatarModal: true });
    }
  };

  hideRemoveAvatarModal = () => {
    this.setState({ showRemoveAvatarModal: false });
  };

  removeAvatar = async () => {
    this.setState({ isRemovingAvatar: true });
    await this.props.onSave({ ...this.state.fields, avatar: 'null' });
    this.hideRemoveAvatarModal();
    this.setState({ isRemovingAvatar: false });
  };

  onFocus = () => this.setState({ showRemoveIcon: true });

  onBlur = () => this.setState({ showRemoveIcon: false });

  handleKeyDown = (e) => e.key === 'Enter' && this.showRemoveAvatarModal();

  showActions = () =>
    JSON.stringify(this.state.fields) !==
    JSON.stringify(setPropsFieldToState(this.props));

  render() {
    const {
      classes,
      fields,
      avatarUrl,
      avatarName,
      showAvatar = true,
    } = this.props;
    const {
      fields: fieldsFromState,
      errors,
      loading,
      saved,
      accountType,
      showRemoveAvatarModal,
      isRemovingAvatar,
    } = this.state;

    const hasAvatar = this.hasAvatarURL(avatarUrl);
    const avatar = hasAvatar ? avatarUrl : null;

    return (
      <div className={classes.container}>
        <div className={classes.fields}>
          <div className={classes.avatar}>
            {showAvatar && (
              <div
                className={classes.avatarWrapper}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onMouseEnter={this.onFocus}
                onMouseLeave={this.onBlur}
                onKeyDown={this.handleKeyDown}
                onClick={this.showRemoveAvatarModal}
                role="button"
                tabIndex={0}
              >
                {hasAvatar && this.state.showRemoveIcon && (
                  <div
                    className={classes.removeAvatarIcon}
                    title="Remove avatar"
                  >
                    <RemoveCircleIcon />
                  </div>
                )}
                <Avatar name={avatarName} src={avatar} size="large" />
              </div>
            )}
          </div>
          {fields.map((field) => {
            if (field.type === 'break') {
              return <BreakContainer key={field.id} />;
            }
            if (field.type === 'name' || field.type === 'email') {
              return (
                <div key={field.id} className={classes.textField}>
                  <TextField
                    label={field.label}
                    placeholder={field.placeholder}
                    onChange={(e) =>
                      this.handleChange(e.target.value, field.id)
                    }
                    margin="none"
                    type={field.type}
                    value={fieldsFromState[field.id]}
                    error={errors[field.id]}
                    style={field.styles}
                    disabled={this.props.readOnly || field.disabled}
                  />
                  {field.copyButton && field.copyButton.show && (
                    <CopyTextIconButton
                      baseText={field.copyButton.baseText}
                      value={fieldsFromState[field.id]}
                    />
                  )}
                </div>
              );
            }
            if (field.type === 'dateInput') {
              const { id, label, getInputProps } = field;
              return (
                <div key={id} className={classes.textField}>
                  <ClearableDateInput
                    value={fieldsFromState[id]}
                    error={errors[id]}
                    label={label}
                    {...getInputProps(fieldsFromState)}
                    handleValueChange={(date) => this.handleChange(date, id)}
                  />
                </div>
              );
            }
            if (field.type === 'switch') {
              return (
                <div key={field.id} className={classes.switchContainer}>
                  <SwitchField
                    disabled={field.disabled}
                    title={field.label}
                    defaultChecked={fieldsFromState[field.id]}
                    onChange={this.handleChange}
                    name={field.id}
                  />
                </div>
              );
            }
            return (
              <div key={field.id} className={classes.textField}>
                <Select
                  value={fieldsFromState[field.id]}
                  onChange={(e) => this.handleChange(e.target.value, field.id)}
                  disabled={this.props.readOnly}
                >
                  <MenuItem value="live">Live</MenuItem>
                  <MenuItem value="archived">Archived</MenuItem>
                </Select>
              </div>
            );
          })}
        </div>
        {this.showActions() && (
          <span className={classes.buttons}>
            <Button
              color="grey"
              className={classes.greyBtn}
              onClick={this.handleDiscard}
            >
              Discard
            </Button>
            <Button
              loading={loading}
              className={classes.greenBtn}
              color="green"
              onClick={this.handleSave}
            >
              Save
            </Button>
          </span>
        )}
        {saved && (
          <span className={classes.buttons}>
            <Button disabled className={classes.greenBtn} color="green">
              Saved
            </Button>
          </span>
        )}
        <div className={classes.divider} />
        <Dialog
          id="remove-avatar-dialog"
          open={showRemoveAvatarModal}
          width="600"
          height="190"
          onClose={this.hideRemoveAvatarModal}
        >
          <DialogTitle closeIcon={false}>
            {`Remove ${accountType} avatar?`}
          </DialogTitle>
          <DialogContent>
            {`This ${accountType} will no longer have an avatar assigned to ${
              accountType === AccountTypes.users ? 'them' : 'it'
            }.`}
          </DialogContent>
          <DialogActions
            primary={{
              label: 'Remove!',
              onClick: this.removeAvatar,
              loading: isRemovingAvatar,
            }}
            secondary={{
              label: 'Cancel',
              onClick: this.hideRemoveAvatarModal,
            }}
          />
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles as any, { withTheme: true })(BasicInfoForm);
