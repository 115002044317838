import React from 'react';
import { Button } from '@ublend-npm/aulaui-next';
import Stepper from '../../shared/Stepper/Stepper';
import {
  DialogActions,
  BackButtonPlaceholder,
  ButtonContainer,
  ButtonGroup,
} from './DialogSteps.styles';

type ActionButtonsProps = {
  totalSteps: number;
  setStep: (step: number) => void;
  step: number;
  onSaveClickHandler: () => void;
  isEditing: boolean;
};

const ActionButtons = ({
  totalSteps,
  setStep,
  step,
  onSaveClickHandler,
  isEditing,
}: ActionButtonsProps) => {
  const incrementStep = () => {
    setStep(step + 1);
  };

  const decrementStep = () => {
    setStep(step - 1);
  };

  const finalStep = step === totalSteps - 1;

  return (
    <DialogActions>
      {step > 0 ? (
        <ButtonGroup>
          <Button type="text" onClick={decrementStep}>
            Back
          </Button>
        </ButtonGroup>
      ) : (
        <BackButtonPlaceholder />
      )}

      <Stepper steps={totalSteps} completedSteps={step + 1} />
      <ButtonContainer>
        <ButtonGroup>
          {finalStep ? (
            <Button type="primary" onClick={onSaveClickHandler}>
              {isEditing ? 'Save' : 'Add'}
            </Button>
          ) : (
            <Button type="primary" onClick={incrementStep}>
              Next
            </Button>
          )}
        </ButtonGroup>
      </ButtonContainer>
    </DialogActions>
  );
};

export default ActionButtons;
