import React, { Component } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import SearchInput from '../../shared/SearchInput/SearchInput';

const styles = (theme) =>
  createStyles({
    inputContainer: {
      position: 'relative',
      width: '100%',
      margin: 'auto',
      maxWidth: 600,
    },
    input: {
      width: '100%',
      backgroundColor: theme.palette.common.searchBar,
      color: theme.palette.common.white,
    },
    inputButton: {
      color: theme.palette.common.white,
    },
    icons: {
      color: theme.palette.common.white,
    },
  });

class SearchBar extends Component<any> {
  handleSelect = ({ result, item }) => {
    this.props.history.push({
      pathname: `/manage/${result.title}/${item.id}`,
    });
  };

  render() {
    const { classes, searchData, isSearching, search } = this.props;

    return (
      <SearchInput
        onSearch={(text) => {
          if (text) search(text);
        }}
        name="search"
        onSelect={this.handleSelect}
        loading={isSearching}
        label="Search"
        classes={classes}
        results={searchData}
        buttonPosition="end"
        variant="outlined"
      />
    );
  }
}
export default withStyles(styles, { withTheme: true })(withRouter(SearchBar));
