import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

interface CopyLinkProps {
  className: string;
}

const CopyLink = ({ className }: CopyLinkProps) => (
  <SvgIcon className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 1H4C2.9 1 2 1.9 2 3V17H4V3H16V1ZM19 5H8C6.9 5 6 5.9 6 7V21C6 22.1 6.9 23 8 23H19C20.1 23 21 22.1 21 21V7C21 5.9 20.1 5 19 5ZM19 21H8V7H19V21Z"
      fill="black"
      fillOpacity="0.6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 13.5C10 12.645 10.645 12 11.5 12H13V11H11.5C10.12 11 9 12.12 9 13.5C9 14.88 10.12 16 11.5 16H13V15H11.5C10.645 15 10 14.355 10 13.5ZM12 14H15V13H12V14ZM15.5 11H14V12H15.5C16.355 12 17 12.645 17 13.5C17 14.355 16.355 15 15.5 15H14V16H15.5C16.88 16 18 14.88 18 13.5C18 12.12 16.88 11 15.5 11Z"
      fill="black"
      fillOpacity="0.6"
    />
  </SvgIcon>
);

export default CopyLink;
