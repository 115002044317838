import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ArrowLeftIcon from '@material-ui/icons/ChevronLeft';
import Dropzone from 'react-dropzone';
import map from 'lodash/map';
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';

import FlatButton from '../Buttons/FlatButton';
import { parse, transform } from '../../../utils/csv';

const topPadding = 24;
const bottomPadding = 20;

const styles = (theme) => ({
  container: {
    padding: `${topPadding}px 20px ${bottomPadding}px 24px`,
    height: `${450 - (topPadding + bottomPadding)}px`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  backButton: {
    minWidth: 32,
    minHeight: 32,
    height: 32,
    padding: 0,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.black,
  },
  text: {
    marginTop: 25,
    marginBottom: 20,
    color: theme.palette.common.darkGrey,
    fontSize: '15px',
    lineHeight: '23px',
  },
  dropzoneText: {
    color: theme.palette.common.grey3,
    display: 'inline-block',
  },
  dropzoneFileName: {
    color: theme.palette.common.black,
    margin: 0,
  },
  dropzone: {
    width: 342,
    height: 100,
    borderRadius: '5px',
    backgroundColor: `${theme.palette.common.lightGrey}`,
    border: `dashed 1px ${theme.palette.common.darkGrey}`,
    overflow: 'hidden',
    textAlign: 'center',
    color: theme.palette.common.darkGrey,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dropzoneActiveStyle: {
    border: `solid 1px ${theme.palette.common.green}`,
  },
  dropzoneAccepteStyle: {
    border: `solid 1px ${theme.palette.common.green}`,
  },
  dropzoneRejectStyle: {
    border: `solid 1px ${theme.palette.common.error}`,
  },
  checkIcon: {
    color: theme.palette.common.green,
    marginLeft: 16,
    verticalAlign: 'middle',
  },
});

class CSV extends Component<any> {
  state = {
    files: [],
    dropzoneRef: undefined,
    fileAccepted: false,
    dropzoneText: 'Drop CSV file here',
    loading: false,
  };

  setFiles = (hasError, files, isAccepted, text) => {
    if (!(this as any).dropzoneRef) {
      return;
    }

    this.setState({
      dropzoneError: hasError,
      files,
      fileAccepted: isAccepted,
      dropzoneText: <p style={(styles as any).dropzoneFileName}>{text}</p>,
      loading: false,
    });
  };

  onDrop = async (files, rejectedFiles) => {
    this.setState({ loading: true });
    if (rejectedFiles.length) {
      return this.setFiles(false, [], false, 'Your file is not a CSV');
    }

    const type = this.props.title;

    return parse(files)
      .then((pFiles) => transform(pFiles, type))
      .then((tFiles) => this.setFiles(false, tFiles, true, files[0].name))
      .catch((error) => this.setFiles(true, [], false, error));
  };

  // return only keys present in given columns
  filterColumnData = (files, columns) =>
    files.map((file) => {
      const result = {};
      Object.keys(file).map((key) => {
        // "userRoles" represents "admin" column
        if (columns.indexOf(key) !== -1 || key === 'userRoles') {
          result[key] = file[key];
        }
        (result as any).error = file.error;
        return file;
      });
      return result;
    });

  addCSV = () => {
    const csvData = this.filterColumnData(this.state.files, this.props.columns);
    this.props.startLoading();
    this.props.onAddNew(csvData);
  };

  render() {
    const { classes, onBack, columns, actionButton, title } = this.props;

    // work around since acceptClassName isn't working
    const dropzoneAcceptClass = this.state.fileAccepted
      ? classes.dropzoneAccepteStyle
      : '';

    const ColumnNames = ({ cols }) =>
      map(cols, (col) => (
        <span key={`${cols.indexOf(col)}`}>
          {cols.indexOf(col) === 0 && <span>&nbsp;</span>}
          {cols.indexOf(col) < cols.length - 1 && cols.indexOf(col) !== 0 && (
            <span>, </span>
          )}
          {cols.length > 1 && cols.indexOf(col) === cols.length - 1 && (
            <span> and </span>
          )}
          <span className={classes.boldText}>{col}</span>
          {cols.indexOf(col) === cols.length - 1 && <span>. </span>}
        </span>
      )) as any;

    return (
      <div className={classes.container}>
        <div>
          <FlatButton
            className={classes.backButton}
            color="grey"
            onClick={onBack}
          >
            <ArrowLeftIcon />
          </FlatButton>
          <div className={classes.text}>
            <span>
              {`The ${title} CSV should contain ${columns.length} column${
                columns.length > 1 ? 's' : ''
              }: `}
              <ColumnNames cols={columns} />
            </span>
          </div>
          <div>
            <Dropzone
              aria-label="Drop CSV file zone"
              ref={(node) => {
                (this as any).dropzoneRef = node;
              }}
              accept=".csv"
              onDrop={(files, rejectedFiles) =>
                this.onDrop(files, rejectedFiles)
              }
              className={`${classes.dropzone} ${dropzoneAcceptClass}`}
              activeClassName={classes.dropzoneActiveStyle}
              rejectClassName={classes.dropzoneRejectStyle}
              multiple={false}
            >
              {(dropzone) => {
                if (dropzone.isDragReject) {
                  return (
                    <div className={classes.dropzoneText}>
                      Your file is not a CSV
                    </div>
                  );
                }
                if (this.state.loading) {
                  return <CircularProgress size={30} />;
                }
                return (
                  <div className={classes.dropzoneText}>
                    {this.state.dropzoneText}
                  </div>
                );
              }}
            </Dropzone>
          </div>
          <div>
            <FlatButton
              onClick={() => (this as any).dropzoneRef.open()}
              color="lightGrey"
            >
              Select a File
            </FlatButton>
            {this.state.fileAccepted && (
              <CheckIcon className={classes.checkIcon} />
            )}
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <FlatButton
            onClick={() => {
              this.addCSV();
              onBack();
            }}
            color="green"
            disabled={this.state.files.length < 1}
          >
            {actionButton.title}
          </FlatButton>
        </div>
      </div>
    );
  }
}

export default withStyles(styles as any, { withTheme: true })(CSV);
