import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import ListItem from '@material-ui/core/ListItem';

const styles = (theme) => ({
  text: {
    color: theme.palette.common.green,
    fontWeight: theme.typography.fontWeightRegular,
  },
  textContainer: {
    flex: '1 1 auto',
  },
  or: {
    color: theme.palette.common.black,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: 4,
  },
});

const Tile = ({ classes, value, id, onClick, hideOr }) => (
  <ListItem className={classes.listItem} onClick={() => onClick(id)} button>
    {!hideOr && <span className={classes.or}>Or </span>}
    <span className={classNames(classes.text, classes.textContainer)}>
      {value}
    </span>
  </ListItem>
);

export default withStyles(styles, { withTheme: true })(Tile);
