/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Drawer from '@material-ui/core/Drawer';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import GroupIcon from '@material-ui/icons/SupervisorAccount';
import { icons, theme as aulaTheme } from '@ublend-npm/aulaui-next';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import shortid from 'shortid';
import Header from '../Header/Header';
import ActionButton from './ActionButton';
import toggleSidebarAction from '../../../redux/actions/sidebar';
import Intercom from '../Intercom/Intercom.container';

const { ExtensionIcon } = icons;

const drawerWidth = 240;
const headerHeight = 80;
const leftMargins = window.innerWidth > 1020 ? 140 : 50;

const styles = (theme) =>
  createStyles({
    root: {
      width: '100%',
      height: 430,
      marginTop: theme.spacing(3),
      zIndex: 1,
      overflow: 'hidden',
    },
    appFrame: {
      // position: 'relative',
      // display: 'flex',
      // width: '100%',
      height: '100%',
    },
    appBar: {
      position: 'absolute',
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    'appBarShift-left': {
      marginLeft: drawerWidth,
    },
    'appBarShift-right': {
      marginRight: drawerWidth,
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 20,
    },
    hide: {
      display: 'none',
    },
    drawerPaper: {
      position: 'relative',
      height: '100%',
      width: drawerWidth,
    },
    drawerInner: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    drawerFooter: {
      paddingBottom: '12px',
      marginTop: 'auto',
    },
    content: {
      left: 0,
      flexGrow: 1,
      height: `calc(100% - ${headerHeight}px)`,
      position: 'absolute',
      top: 80,
      padding: `0 ${leftMargins}px`,
      overflow: 'auto',
      transition: theme.transitions.create(['all'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    contentLeft: {
      left: drawerWidth,
    },
    list: {
      padding: 0,
      borderBottom: `1px solid ${aulaTheme.color.designSystem.blackOpacity12}`,
    },
    'content-left': {
      marginLeft: -drawerWidth,
    },
    'content-right': {
      marginRight: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    'contentShift-left': {
      marginLeft: 0,
    },
    'contentShift-right': {
      marginRight: 0,
    },
    footerIcons: {
      color: theme.palette.common.black,
      marginRight: 12,
    },
    navListItemIcon: {
      minWidth: 36,
    },
    navIcons: {
      color: theme.palette.common.lightBlue,
    },
    navIconsSelected: {
      color: theme.palette.common.darkBlue,
    },
    navIconsDisabled: {
      color: theme.palette.common.darkGrey,
    },
    navItem: {
      display: 'block',
    },
    activeTab: {
      backgroundColor: theme.palette.common.grey,
    },
    disabledTab: {
      cursor: 'not-allowed',
    },
    fabContainer: {
      position: 'absolute',
      bottom: 24,
      right: 24,
    },
    linearProgress: {
      position: 'absolute',
      width: '100%',
      zIndex: 999999,
    },
    listItemText: {
      color: 'rgba(0, 0, 0, 0.87)',
      padding: 0,
    },
    listItemPrimary: {
      fontSize: 14,
    },
    listItemSelected: {
      fontWeight: theme.typography.fontWeightBold,
    },
    listItemDisabled: {
      color: theme.palette.common.darkGrey,
    },
  });

const getListItem = (l, Icon, classes, isActive, isDisabled?) => (
  <ListItem button disabled={isDisabled}>
    <ListItemIcon className={classes.navListItemIcon}>
      <Icon
        className={classNames(classes.navIcons, {
          [classes.navIconsSelected]: isActive && !isDisabled,
          [classes.navIconsDisabled]: isDisabled,
        })}
      />
    </ListItemIcon>
    <ListItemText
      className={classes.listItemText}
      classes={{
        primary: classNames(classes.listItemPrimary, {
          [classes.listItemSelected]: isActive && !isDisabled,
          [classes.listItemDisabled]: isDisabled,
        }),
      }}
      primary={l.title}
    />
  </ListItem>
);

interface HeaderAndSidebarProps
  extends WithStyles<typeof styles>,
    Pick<RouteComponentProps, 'history' | 'location'> {
  children: React.ReactNode[] | React.ReactNode;
  toggleSidebar: (isOpen: boolean) => void;
  user: Record<string, any>;
  institution: Record<string, any>;
}

const HeaderAndSidebar = ({
  toggleSidebar,
  classes,
  children,
  history,
  location,
  user,
  institution,
}: HeaderAndSidebarProps) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('/manage');

  const activeCheck = (match) => {
    if (!match) {
      return false;
    }
    if (activeLink === match.url) {
      return true;
    }
    setActiveLink(match.url);
    return true;
  };

  const sidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
    toggleSidebar(!sidebarOpen);
  };

  const { ftSelfServeLtiEnabled202204 } = useFlags();

  const Links = [
    {
      name: 'manage',
      title: 'Users / Spaces',
      icon: GroupIcon,
      url: '/manage',
      isExternal: false,
    },
  ];

  if (ftSelfServeLtiEnabled202204) {
    Links.push({
      name: 'integrations',
      title: 'Integrations',
      icon: ExtensionIcon,
      url: '/integrations',
      isExternal: false,
    });
  }

  const activeName = activeLink.substring(1);
  const activeItem = Links.find((l) => l.name === activeName);

  return (
    <div className={classes.appFrame}>
      <Header
        sidebarOpen={sidebarOpen}
        onIconClick={sidebarToggle}
        title={activeItem.title}
        url={activeLink}
        user={user}
        institution={institution}
        drawerWidth={drawerWidth}
      />
      <Drawer
        variant="persistent"
        anchor="left"
        open={sidebarOpen}
        classes={{
          paper: classes.drawerPaper,
          docked: classes.appFrame,
        }}
      >
        <div className={classes.drawerInner}>
          <List className={classes.list}>
            {Links.map((l: any) => {
              const Icon = l.icon;

              if (l.isDisabled) {
                return (
                  <div className={classes.disabledTab} key={shortid.generate()}>
                    {getListItem(
                      l,
                      Icon,
                      classes,
                      l.name === activeName,
                      l.isDisabled,
                    )}
                  </div>
                );
              }

              if (l.isExternal) {
                return (
                  <a href={l.url} key={shortid.generate()}>
                    {getListItem(l, Icon, classes, l.name === activeName)}
                  </a>
                );
              }

              return (
                <NavLink
                  key={shortid.generate()}
                  activeClassName={classes.activeTab}
                  className={classes.navItem}
                  to={l.url}
                  isActive={activeCheck}
                >
                  {getListItem(l, Icon, classes, l.name === activeName)}
                </NavLink>
              );
            })}
          </List>
          <div className={classes.drawerFooter}>
            <List>
              <Intercom />
            </List>
          </div>
        </div>
      </Drawer>
      <div
        className={classNames(classes.content, {
          [classes.contentLeft]: sidebarOpen,
        })}
        style={{
          width: `calc(100% - ${
            (sidebarOpen ? drawerWidth : 0) + 2 * leftMargins
          }px)`,
        }}
      >
        {children}
      </div>
      <div className={classes.fabContainer}>
        <ActionButton history={history} location={location} />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  toggleSidebar: (isOpen) => dispatch(toggleSidebarAction(isOpen)),
});

const mapStateToProps = (state) => ({
  user: state.user.user,
  institution: state.institution,
});

const HSComponent = withStyles(styles as any, { withTheme: true })(
  HeaderAndSidebar,
);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HSComponent),
);
