import { connect } from 'react-redux';
import IntegrationIdentification from './IntegrationIdentification';
import { showToast } from '../../redux/actions/toasts';

const mapDispatchToProps = (dispatch) => ({
  showToast: (messagePayload: { message: string }) =>
    dispatch(showToast(messagePayload)),
});

export default connect(null, mapDispatchToProps)(IntegrationIdentification);
