import React from 'react';
import pluralize from 'pluralize';

interface DeleteUserConfirmationProps {
  title: string;
  uniqDataLength: number;
  parentClasses?: {
    bold?: string;
  };
}
// TODO: Refactor this component to make it more generic and update usage around the app.
const DeleteUserConfirmation = ({
  title,
  parentClasses,
  uniqDataLength,
}: DeleteUserConfirmationProps) => (
  <span>
    You are deleting{' '}
    <span className={parentClasses.bold}>
      {pluralize(title, uniqDataLength, true)}
    </span>
  </span>
);

export default DeleteUserConfirmation;
