import { createStyles } from '@material-ui/core/styles';
import { theme } from '@ublend-npm/aulaui-next';

export const styles = createStyles({
  removeButton: {
    marginLeft: 8,
  },
  listItemText: {
    fontSize: 16,
    fontWeight: 600,
  },
  listItemSecondaryAction: {
    visibility: 'hidden',
    paddingRight: 0,
  },
  listItemRoot: {
    height: 64,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  listItemContainer: {
    '&:hover $listItemSecondaryAction': {
      visibility: 'inherit',
    },
    '&:focus-within $listItemSecondaryAction': {
      visibility: 'inherit',
    },
  },
  removeCircleIcon: {
    height: 24,
    width: 24,
    color: theme.color.designSystem.blackOpacity60,
    marginRight: theme.spacing.xxSmall,
    marginLeft: theme.spacing.medium,
  },
  infoIcon: {
    height: 24,
    width: 24,
    color: theme.color.designSystem.blackOpacity60,
    marginRight: theme.spacing.xxSmall,
  },
  disabledContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing.xxGiant,
    color: theme.color.error,
    fontWeight: theme.font.weight.regular,
    fontSize: theme.font.size.copy,
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  disabledIcon: {
    marginLeft: theme.spacing.xxSmall,
    marginRight: theme.spacing.xxSmall,
  },
  toolIcon: {
    height: 30,
    width: 30,
  },
});
