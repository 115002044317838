import React from 'react';
import Parameters from '../Parameters';
import { Parameter, FormParameters } from '../types';
import { customParameterWhitelist } from '../customParametersWhitelist';
import {
  DialogContent,
  DialogHorizontalRuler,
  ParametersTitle,
} from './DialogSteps.styles';

type ConfigurationStepProps = {
  customParameters: Parameter[];
  showErrors: boolean;
  parameters: FormParameters;
  onParametersChange: (parameters: FormParameters) => void;
  children: React.ReactNode;
};

const ConfigurationStep = ({
  customParameters,
  showErrors,
  parameters,
  onParametersChange,
  children,
}: ConfigurationStepProps) => {
  const customParametersDisplayCount =
    customParameters.length > 0 ? `(${customParameters.length})` : '';
  return (
    <DialogContent>
      {children}
      <DialogHorizontalRuler />
      <ParametersTitle>
        Custom parameters {customParametersDisplayCount}
      </ParametersTitle>
      <Parameters
        allParameters={parameters}
        selectedCategory="customParameters"
        showErrors={showErrors}
        onChange={onParametersChange}
        whitelistedParameters={customParameterWhitelist}
        title="Add custom parameter"
      />
    </DialogContent>
  );
};

export default ConfigurationStep;
