import { connect } from 'react-redux';
import Intercom from './Intercom';

const mapStateToProps = (state) => {
  if (!state.user.isLogged || !state.user.user) {
    return {
      userId: '',
      userName: '',
      email: '',
    };
  }

  return {
    userId: state.user.user.id,
    userName: `${state.user.user.firstName} ${state.user.user.lastName}`,
    email: state.user.user.email,
    created_at: new Date(state.user.user.createdAt).getTime(),
  };
};

const IntercomContainer = connect(mapStateToProps, null)(Intercom);

export default IntercomContainer;
