import { combineReducers } from 'redux';

import sidebar from './sidebar';
import search from './search';
import addUsers from './addUsers';
import login from './login';
import institution from './institution';
import loading from './loading';
import toasts from './toasts';

const rootReducer = combineReducers({
  sidebar,
  search,
  addUsers,
  institution,
  user: login,
  loading,
  toasts,
});

export default rootReducer;
