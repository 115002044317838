export default {
  increment: 'increment',
  decrement: 'decrement',
  SIDEBAR_OPEN: 'SIDEBAR_OPEN',
  SIDEBAR_CLOSE: 'SIDEBAR_CLOSE',
  SEARCH: 'SEARCH',
  ADD_USERS: 'ADD_USERS',
  REMOVE_USERS: 'REMOVE_USERS',
  FETCH_INSTITUTION: 'FETCH_INSTITUTION',
  CHECK_INSTITUTION: 'CHECK_INSTITUTION',
  USER_LOGIN: 'USER_LOGIN',
  UPDATE_USER: 'UPDATE_USER',
  LOADING: 'LOADING',
  TOASTS_SHOW_TOAST: 'TOASTS_SHOW_TOAST',
  TOASTS_DISMISS_TOAST: 'TOASTS_DISMISS_TOAST',
};
