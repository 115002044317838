import { createStyles } from '@material-ui/core/styles';

export const styles = createStyles({
  root: {
    margin: '31px auto 50px',
    minWidth: 500,
    maxWidth: 934,
    padding: 23,
  },
  header: {
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    margin: '-23px -23px 24px',
    padding: '24px 24px 4px',
    zIndex: 10,
    borderRadius: 4,
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  heading: {
    marginTop: 32,
    marginBottom: 14,
  },
  loader: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100vh - 207px)',
    width: '100%',
  },
});
