/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import curry from 'lodash/curryRight';
import ModalBox from '../../shared/ModalBox/ModalBox';
import createUser from './AddUserImage.png';
import createSpaceImg from '../CreateSpace/createSpaceImage.png';
import RightPanel from './RightPanel';
import { getUniqData } from '../../../utils/duplicate';
import AddUserConfirmation from './AddUserConfirmation';
import AddUserSuccess from './AddUserSuccess';
import chunkProcess from '../../../utils/chunkProcess';

const confirmationSectionConfig = {
  getConfirmationMessage: (data) => <AddUserConfirmation {...data} />,
  getSuccessMessage: (data) => <AddUserSuccess {...data} />,
};

const MODAL_PROPS = (
  props: any = {
    usersSearchData: [],
    spacesSearchData: [],
    search: null,
  },
  onSelect: (..._args) => void = () => {},
) => {
  const curriedSearch = props.search ? curry(props.search) : (_) => {};
  return {
    users: {
      title: 'user',
      modalSubtitle: 'Add users to spaces',
      rightPanelConfig: {
        getRightPanelTitle: (entityName) => `Creating ${entityName}`,
      },
      create: props.createUsers,
      uniqueness: {
        columns: ['externalId'],
      },
      csvLeftPanelAction: {
        tabTitle: 'import a CSV of users',
        actionButton: {
          title: 'Add users',
        },
      },
      columns: ['externalId'],
      table: {
        image: createUser,
        onSelect: (selected) => onSelect(selected, 'user'),
      },
      search: {
        data: props.usersSearchData,
        extractSelected: (item) => ({
          name: item.title,
          externalId: item.subtitle,
        }),
        search: curriedSearch(['users']),
        isSearching: props.isSearching,
        clearSearchOnSelect: true,
      },
    },
    spaces: {
      title: 'space',
      modalSubtitle: 'Add users to spaces',
      rightPanelConfig: {
        getRightPanelTitle: (entityName) => `Creating ${entityName}`,
      },
      csvLeftPanelAction: {
        tabTitle: 'import a CSV of spaces',
        actionButton: {
          title: 'Add spaces',
        },
      },
      columns: ['externalId'],
      table: {
        image: createSpaceImg,
        onSelect: (selected) => onSelect(selected, 'space'),
      },
      uniqueness: {
        columns: ['externalId'],
      },
      search: {
        search: curriedSearch(['spaces']),
        isSearching: props.isSearching,
        data: props.spacesSearchData,
        extractSelected: (item) => ({
          name: item.title,
          externalId: item.subtitle,
        }),
        clearSearchOnSelect: true,
      },
    },
  };
};

const CHUNK_SIZE = 100;

interface AddUserModalProps extends RouteComponentProps {
  search?: (term: unknown, searchFor?: unknown) => void;
  createRoles?: (roles: unknown[]) => void;
  usersSearchData?: unknown[];
  spacesSearchData?: unknown[];
  isSearching?: boolean;
  addingUsers?: unknown[];
  sso?: boolean;
}

class AddUserModal extends Component<AddUserModalProps> {
  static defaultProps: Partial<AddUserModalProps>;

  state = {
    step: 'users',
    modalProps: MODAL_PROPS().users,
    loading: false,
    confirmModalProps: null,
    leftToProcess: null,
    role: 'student',
    selected: {
      user: [],
      space: [],
    },
    errors: undefined,
    totalToProcess: undefined,
  };

  static getDerivedStateFromProps = (nextProps, prevState) => ({
    ...prevState,
    modalProps: MODAL_PROPS(nextProps)[prevState.step],
  });

  componentWillMount = () => {
    this.setState({
      modalProps: MODAL_PROPS(this.props)[this.state.step],
    });
  };

  transformAddUsersData = (data) => {
    const users = getUniqData(data.user);
    const spaces = getUniqData(data.space);
    return users.reduce(
      (acc, user) => [
        ...acc,
        ...spaces.map((space) => ({
          externalUserId: user.externalId,
          externalSpaceId: space.externalId,
          role: this.state.role,
        })),
      ],
      [],
    );
  };

  onSave = async (data) => {
    const users = this.transformAddUsersData(data);

    if (!users.length) {
      return true;
    }

    await chunkProcess({
      data: users,
      chunkSize: CHUNK_SIZE,
      onRequest: this.props.createRoles,
      onProgress: (newData) => this.setState(newData),
      onError: (error) =>
        this.setState({
          errors: [...(this.state.errors || []), error],
        }),
    });

    return true;
  };

  nextStep = () => {
    if (this.state.step === 'users') {
      this.setState({
        step: 'spaces',
        modalProps: MODAL_PROPS(this.props, () => undefined).spaces,
      });
    }
  };

  prevStep = () => {
    this.setState({
      step: 'users',
      modalProps: MODAL_PROPS(this.props, () => undefined).users,
    });
  };

  onRoleChange = (role) => {
    this.setState({
      role,
    });
  };

  render() {
    const { addingUsers } = this.props;
    const {
      step,
      role,
      errors,
      selected,
      modalProps,
      leftToProcess,
      totalToProcess,
      confirmModalProps,
    } = this.state;
    return (
      <div>
        <ModalBox
          {...modalProps}
          selectedRole={role}
          renderRightPanel={(props) => (
            <RightPanel
              {...props}
              onRoleChange={this.onRoleChange}
              selectedRole={role}
              step={step}
            />
          )}
          selected={selected}
          addingUsers={addingUsers}
          confirmModalProps={confirmModalProps}
          onNext={this.nextStep}
          onSave={this.onSave}
          onPrev={this.prevStep}
          leftToProcess={leftToProcess}
          totalToProcess={totalToProcess}
          errorsList={errors}
          step={step}
          showInstitutionDetails
          confirmationSectionConfig={confirmationSectionConfig}
        />
      </div>
    );
  }
}

AddUserModal.defaultProps = {
  search: () => {},
  createRoles: () => {},
};

export default withStyles({}, { withTheme: true })(withRouter(AddUserModal));
