import React, { useState, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Button, theme as aulaTheme } from '@ublend-npm/aulaui-next';
import { Typography, WithStyles } from '@material-ui/core';
import { styles } from './RemoveDialog.styles';

const CustomColorCheckbox = withStyles<any, any, any>({
  root: {
    '&$checked': {
      color: aulaTheme.color.designSystem.purple70,
    },
  },
  checked: {},
})((props) => <Checkbox {...(props as any)} />);

type RemoveDialogProps = WithStyles<typeof styles> &
  Readonly<{
    open: boolean;
    description: string | null;
    onClose: () => void;
    onConfirm: () => void;
  }>;

const RemoveDialog = ({
  open,
  description,
  onClose,
  onConfirm,
  classes,
}: RemoveDialogProps) => {
  const [confirmed, setConfirmed] = useState(false);

  const toggleConfirmed = useCallback(() => {
    setConfirmed((existingConfirmed) => !existingConfirmed);
  }, []);

  const handleClose = () => {
    setConfirmed(false);
    onClose();
  };

  const handleRemove = () => {
    handleClose();
    onConfirm();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      data-testid="remove-integration-dialog"
    >
      <DialogTitle className={classes.dialogTitle}>
        Permanently remove this integration?
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div>
          <b>{description}</b> will be permanently removed and it will no longer
          be available in Aula.
        </div>
        <FormControlLabel
          className={classes.formControlLabel}
          control={
            <CustomColorCheckbox
              data-testid="remove-integration-checkbox"
              checked={confirmed}
              onChange={toggleConfirmed}
              name="confirmed"
            />
          }
          label={
            <Typography className={classes.label}>
              I understand that removing is permanent.
            </Typography>
          }
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          className={classes.cancelButton}
          type="secondary"
          size="medium"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          size="medium"
          disabled={!confirmed}
          onClick={handleRemove}
          className={confirmed ? classes.removeButton : undefined}
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(RemoveDialog);
