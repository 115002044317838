import thunk, { ThunkDispatch } from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose, Store } from 'redux';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';
import { isLocal } from '../../utils/isLocal';

export const history = createBrowserHistory();

let enhancer = null;
const commonMiddleware = [thunk];
if (isLocal) {
  const logger = createLogger({ level: 'info', collapsed: true });
  enhancer = compose(applyMiddleware(...commonMiddleware, logger));
} else {
  enhancer = compose(applyMiddleware(...commonMiddleware));
}

type AdminDashboardState = any;

export type AdminDashboardStore = Store<AdminDashboardState> & {
  dispatch: ThunkDispatch<AdminDashboardState, any, any>;
};

let store: AdminDashboardStore | null = null;

export const getStore = () => store;

export const configureStore = (
  initialState = {},
): Promise<AdminDashboardStore> =>
  new Promise((resolve) => {
    store = createStore(rootReducer, initialState, enhancer);
    resolve(store);

    if (isLocal && module.hot) {
      module.hot.accept('../reducers', () =>
        // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
        store.replaceReducer(require('../reducers')),
      );
    }
  });
