import React from 'react';
import { IconButton, TextField } from '@ublend-npm/aulaui-next';
import InputAdornment from '@material-ui/core/InputAdornment';
import CopyLinkIcon from '../shared/Icons/CopyLink';

type IntegrationIdentificationProps = Readonly<{
  iss: string;
  clientId: string;
  deploymentId: string;
  showToast: ({ message }: { message: string }) => void;
}>;

const IntegrationIdentification = ({
  iss,
  clientId,
  deploymentId,
  showToast,
}: IntegrationIdentificationProps) => {
  const handleCopy =
    ({ label, value }) =>
    async () => {
      if (navigator && navigator.clipboard) {
        await navigator.clipboard.writeText(value);
        showToast({ message: `${label} added to the clipboard` });
      }
    };

  return (
    <>
      <TextField
        label="Issuer"
        value={iss}
        disabled
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              label="Copy issuer to clipboard"
              disableRipple
              onClick={handleCopy({ label: 'Issuer', value: iss })}
              icon={CopyLinkIcon}
              size="xSmall"
            />
          </InputAdornment>
        }
      />
      <TextField
        label="Client ID"
        value={clientId}
        disabled
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              label="Copy client ID to clipboard"
              disableRipple
              onClick={handleCopy({ label: 'Client ID', value: clientId })}
              icon={CopyLinkIcon}
              size="xSmall"
            />
          </InputAdornment>
        }
      />
      <TextField
        label="Deployment ID"
        value={deploymentId}
        disabled
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              label="Copy deployment ID to clipboard"
              disableRipple
              onClick={handleCopy({
                label: 'Deployment ID',
                value: deploymentId,
              })}
              icon={CopyLinkIcon}
              size="xSmall"
            />
          </InputAdornment>
        }
      />
    </>
  );
};

export default IntegrationIdentification;
