import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Avatar } from '@ublend-npm/aulaui-next';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';

import IconLogout from '../Icons/Logout';
import { logout } from '../../../utils/logout';

const styles = {
  avatarWrapper: {
    width: 68,
    height: 40,
    backgroundColor: 'rgba(0, 0, 0, .32)',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
  },
  caret: {
    width: 24,
    height: 24,
  },
  avatar: {
    width: 32,
    height: 32,
    marginLeft: 4,
  },
  popover: {
    zIndex: 999999,
    marginTop: 8,
    marginLeft: -4,
    borderRadius: 4,
  },
};

class AvatarDropdown extends PureComponent<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      anchorOriginVertical: 'bottom',
      anchorOriginHorizontal: 'left',
      transformOriginVertical: 'top',
      transformOriginHorizontal: 'left',
      anchorReference: 'anchorEl',
    };

    this.handleToggle = this.handleToggle.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleToggle = () => {
    this.setState({ open: !this.state.open });
  };

  handleOpen = (e) => {
    if (e.keyCode === 13) {
      this.setState({ open: !this.state.open });
    }
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleLogout() {
    const { user, institution } = this.props;
    const { email } = user;

    return logout({ email, institution, returnAfterLogin: false });
  }

  render() {
    const { classes, user } = this.props;
    const {
      open,
      anchorOriginVertical,
      anchorOriginHorizontal,
      transformOriginVertical,
      transformOriginHorizontal,
      anchorReference,
    } = this.state;

    return (
      <div>
        <div
          className={classes.avatarWrapper}
          aria-owns={open ? 'menu-list-collapse' : null}
          aria-haspopup="true"
          onClick={this.handleToggle}
          onKeyPress={this.handleOpen}
          ref={(node) => {
            (this as any).anchorEl = node;
          }}
          role="button"
          tabIndex={0}
        >
          <ArrowDropDown className={classes.caret} />
          <Avatar
            className={classes.avatar}
            name={`${user.firstName} ${user.lastName}`}
            src={user.avatar}
          />
        </div>
        <Popover
          open={open}
          anchorEl={(this as any).anchorEl}
          anchorReference={anchorReference}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: anchorOriginVertical,
            horizontal: anchorOriginHorizontal,
          }}
          transformOrigin={{
            vertical: transformOriginVertical,
            horizontal: transformOriginHorizontal,
          }}
          className={classes.popover}
          elevation={5}
        >
          <Paper>
            <ListItem button onClick={this.handleLogout}>
              <ListItemIcon>
                <IconLogout />
              </ListItemIcon>
              <ListItemText primary="Log out" />
            </ListItem>
          </Paper>
        </Popover>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AvatarDropdown);
