import CONST from '../../constants/actions';

export const addUsers = (users) => async (dispatch) => {
  dispatch({
    type: CONST.ADD_USERS,
    data: users,
    status: 'success',
  });
};

export const removeUsers = () => async (dispatch) => {
  dispatch({
    type: CONST.REMOVE_USERS,
    status: 'success',
  });
};

export default {};
