import React, { useState, MouseEvent } from 'react';
import { DateTimePickerDialog, IconButton } from '@ublend-npm/aulaui-next';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '../TextField/TextField';

type ClearableDateInputProps = Readonly<{
  label: string;
  value: string | null;
  minDate?: string;
  maxDate?: string;
  error?: string;
  handleValueChange: (date: string) => void;
}>;

const ClearableDateInput = ({
  label,
  value,
  minDate,
  maxDate,
  error,
  handleValueChange,
}: ClearableDateInputProps) => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const hasDate = !!value;
  const formattedDate = hasDate ? new Date(value).toDateString() : '';
  const handleClearIconClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handleValueChange(null);
  };

  return (
    <>
      <TextField
        label={label}
        onClick={() => setIsPickerOpen(true)}
        value={formattedDate}
        style={{ padding: '8px 0px', width: '160px' }}
        inputProps={{
          style: { padding: '0px' },
          'data-testid': `${label.replace(' ', '-').toLowerCase()}-input`,
        }}
        InputLabelProps={{
          shrink: hasDate,
        }}
        readOnly
        error={error}
        margin="none"
        variant="standard"
        endAdornment={
          hasDate && (
            <InputAdornment position="end">
              <IconButton
                label={`Clear ${label}`}
                size="small"
                icon={HighlightOffIcon}
                onClick={handleClearIconClick}
              />
            </InputAdornment>
          )
        }
      />
      <DateTimePickerDialog
        id={label}
        date={value}
        open={isPickerOpen}
        DateTimePickerProps={{
          dateOnly: true,
          minDate: minDate || undefined,
          maxDate: maxDate || undefined,
        }}
        log
        onClose={() => setIsPickerOpen(false)}
        primaryAction={{
          label: 'Confirm',
          onAction: handleValueChange,
        }}
        secondaryAction={{
          label: 'Cancel',
          onAction: () => setIsPickerOpen(false),
        }}
      />
    </>
  );
};

export default ClearableDateInput;
