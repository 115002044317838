import React from 'react';
import pluralize from 'pluralize';

interface AddRolesSuccessProps {
  title: string;
  parentClasses: { bold?: string };
  uniqDataLength: number;
}

const AddRolesSuccess = ({
  parentClasses,
  uniqDataLength,
  title,
}: AddRolesSuccessProps) => (
  <span>
    You just added{' '}
    <span className={parentClasses.bold}>
      {pluralize(title, uniqDataLength, true)}
    </span>
  </span>
);

export default AddRolesSuccess;
